import React, { ReactElement, ReactNode } from 'react';

import { Box, Container } from '@constellation/core';
import { To } from '@interstellar/react-app-routing';

import { BasicTableProps } from '../basicTable/BasicTable';

type BoxProps = React.ComponentProps<typeof Box>;
type ContainerProps = React.ComponentProps<typeof Box>;

export const contentSectionContainer = (
  props: Omit<ContainerProps, 'children'>,
) =>
  function ContentSectionContainer({
    children,
  }: {
    children: React.ReactNode;
  }) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Container {...props} padding={props.padding ?? 'none'}>
        {children}
      </Container>
    );
  };

export const contentSectionBox = (props: Omit<BoxProps, 'children'>) =>
  function ContentSectionBox({ children }: { children: React.ReactNode }) {
    return (
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        bgColor={props.bgColor ?? 'none'}
        padding={props.padding ?? 'none'}
      >
        {children}
      </Box>
    );
  };

export interface TextWithLinks {
  external?: boolean;
  href: string;
  displayText: string;
}

export interface ContentSectionItem {
  boldText?: string;
  text?: string | ReactElement;
  keyValueList?: string[];
  list?: (string | ReactElement)[];
  listItemContainer?: React.FC<{ children: ReactNode }>;
  subheading?:
    | string
    | {
        displayText: string;
        headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
        id?: string;
        size?: 's2' | 's3' | 's4' | 's5' | 's6' | 's7';
        marginBottom?:
          | 'default'
          | 'none'
          | '01'
          | '02'
          | '03'
          | '04'
          | '05'
          | '06'
          | '07'
          | '08'
          | '09'
          | '10';
      };
  basicTable?: BasicTableProps;
  container?: React.FC<{ children: ReactNode }>;
  link?: {
    external?: boolean;
    href: string;
    displayText: string;
  };
  linkButton?: {
    href: To;
    external?: boolean;
    displayText: string;
    variation?: 'secondary';
  }
  textWithLinks?: (string | TextWithLinks)[];
  component?: ReactElement;
}

export interface ContentSection {
  sectionHeading?: string;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  sectionID: string;
  sectionBody?: ContentSectionItem[];
  isAccordion?: boolean;
}
export interface InfoPanelContent {
  contentSections: ContentSection[];
}
