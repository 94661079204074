import React from 'react';

import { Accordion } from '@constellation/core';

import { CustomAccordionItem } from './CustomAccordion.config';
import AccordionLink from '../accordionLink/accordionLink';

function CustomAccordion({
  label,
  dataTestId,
  variationType,
  items,
}: CustomAccordionItem) {
  return (
    <Accordion label={label} data-testid={dataTestId} variation={variationType}>
      {items.map((item) => (
        <AccordionLink
          href={item.link}
          title={item.title}
          text={item.text}
          testId={`${dataTestId}_${item.title}`}
          key={item.title}
        />
      ))}
    </Accordion>
  );
}

export default CustomAccordion;
