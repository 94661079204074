const isTealiumAvailable = () =>
  typeof window !== 'undefined' &&
  typeof window.setAnalyticsVariables === 'function';

export default isTealiumAvailable;

declare global {
  interface Window {
    setAnalyticsVariables;
  }
}