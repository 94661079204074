export const calculatorsPage = {
  heading: 'calculators-heading',
  sectionBody: 'calculators-sectionBody',
  sectionHeading: 'calculators-Heading',
  table1Data:{
    heading1:'table1-data-heading1',
    heading2:'table1-data-heading2',
    rowData:'table1-data-row-data'
  },
  table2Data:{
    heading1:'table2-data-heading1',
    heading2:'table2-data-heading2',
    rowData:'table2-data-row-data'
  },
  table3Data:{
    heading1:'table3-data-heading1',
    heading2:'table3-data-heading2',
    rowData:'table3-data-row-data'
  },
  headingTwo:'calculators-heading-two',
  paragraph:'calculators-paragraph'
};
