import React from 'react';

import {
  List,
  ListItem,
  Strong,
  TableRow,
} from '@constellation/core';
import { StyledTable } from 'client/routes/privacy/PrivacyPage.styled';
import { StyledTableCol } from 'client/components/basicTable/BasicTable.styled';

interface ListItems {
  id: string;
  text: string;
}

interface CustomTableComponentProps {
  caption: string;
  rows: ListItems[][][];
  headers: string[];
}

export default function CustomTableComponent({
  caption,
  rows,
  headers,
}: Readonly<CustomTableComponentProps>) {
  return (
    <StyledTable
      caption={[<Strong key={caption}>{caption}</Strong>]}
      width="fluid"
      headings={headers}
    >
      {rows.map((row) => (
        <TableRow key={`row_${caption}_${row[0][0]?.id || 'empty'}`}>
          {row.map((list, index) => (
            <StyledTableCol
              key={`${caption}_${list[0]?.id || 'empty'}_row_col`}
              heading={headers[index]}
            >
              {list.length > 0 ? (
                <List key={`${caption}_${list[0].id}_List`}>
                  {list.map((entry) => (
                    <ListItem key={`${entry.id}_listItem`}>
                      {entry.text}
                    </ListItem>
                  ))}
                </List>
              ) : (
                ''
              )}
            </StyledTableCol>
          ))}
        </TableRow>
      ))}
    </StyledTable>
  );
}
