import { MetaContent } from '../content/MetaContent';
import { MetaContentValues } from '../content/MetaContentValues';
import * as routes from '../routes/manifest';

const routeToMetaConfig: Record<string, MetaContent> = {
  [routes.Home]: MetaContentValues.home,
  [routes.Articles]: MetaContentValues.Articles,
  [routes.ArticleImprovingQualityBenefitsLandlordsAndTenants]:
    MetaContentValues.ArticleImprovingQualityBenefitsLandlordsAndTenantsPage,
  [routes.OurCriteria]: MetaContentValues.ourCriteria,
  [routes.Calculators]: MetaContentValues.calculators,
  [routes.SustainabilityHub]: MetaContentValues.sustainabilityHub,
  [routes.PlacingBusiness]: MetaContentValues.placingBusiness,
  [routes.Resources]: MetaContentValues.resources,
  [routes.ContactUs]: MetaContentValues.contactUs,
  [routes.TermsAndConditions]: MetaContentValues.TermsAndConditions,
  [routes.Accessibility]: MetaContentValues.Accessibility,
  [routes.Privacy]: MetaContentValues.Privacy,
  [routes.Cookies]: MetaContentValues.Cookies,
  [routes.ExistingCustomers]: MetaContentValues.ExistingCustomers,
  [routes.NewBusinessEnquiries]: MetaContentValues.NewBusinessEnquiries,
  [routes.Complaints]: MetaContentValues.Complaints,
  [routes.IntermediaryServiceSupport]:
    MetaContentValues.IntermediaryServiceSupport,
  [routes.BDMSearch]: MetaContentValues.BDMSearch,
  [routes.SubmittedMortgageApplications]:
    MetaContentValues.SubmittedMortgageApplications,
  [routes.EpcRegulations]: MetaContentValues.EpcRegulations,
  [routes.LowerRatesForEnergyEfficientHomes]:
    MetaContentValues.LowerRatesForEnergyEfficientHomes,
  [routes.GrantsAndSchemes]: MetaContentValues.GrantsAndSchemes,
  [routes.LandlordRetrofitTool]: MetaContentValues.LandlordRetrofitTool,
  [routes.EducationalHub]: MetaContentValues.EducationalHub,
  [routes.FreeConveyancingService]: MetaContentValues.FreeConveyancingService,
  [routes.EpcStepByStepGuide]: MetaContentValues.EpcStepByStepGuide,
  [routes.ProductTransfers]: MetaContentValues.ProductTransfers,
  [routes.FurtherAdvances]: MetaContentValues.FurtherAdvances,
  [routes.TransferOrPurchaseOfEquity]:
    MetaContentValues.TransferOrPurchaseOfEquity,
  [routes.SubmittingBtlOrLtbMortgageApplication]:
    MetaContentValues.SubmittingBtlOrLtbMortgageApplication,
  [routes.Porting]: MetaContentValues.Porting,
  [routes.Fees]: MetaContentValues.Fees,
  [routes.DocumentUpload]: MetaContentValues.DocumentUpload,
  [routes.RateChangeCalculator]: MetaContentValues.RateChangeCalculator,
  [routes.Security]: MetaContentValues.Security,
  [routes.Register]: MetaContentValues.Register,
  [routes.Sitemap]: MetaContentValues.Sitemap,
};

export function getMetaTagContent(path: string): MetaContent {
  return routeToMetaConfig[path] ?? MetaContentValues.home;
}
