import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import testIds from 'client/testIds';

import { AccessibilityPageContent } from './Accessibility.config';

function AccessibilityPage() {
  const { metaContent, pageTitle, infoPanel } =
    useContent<AccessibilityPageContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup>
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={testIds.accessibility}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default AccessibilityPage;
