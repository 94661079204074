// keep 1 redirect rule per line - don't format this file with prettier
// keep lines in alphabetical order & groups of 5 to make them easier to read
// no paths we are redirecting should end in a trailing slash
import * as routes from 'client/routes/manifest';

export const InternalRedirectDefinitions = {
  '/amend-company-details': routes.Register,
  '/calculators/baseratecalculator': routes.RateChangeCalculator,
  '/contactus': routes.ContactUs,
  '/contactus/bdm': routes.BDMSearch,
  '/criteria': routes.OurCriteria,
  '/document-upload-guidance': routes.DocumentUpload,
  '/energy-performance-certificate': routes.EpcRegulations,
  '/fees': routes.Fees,
  '/literature/bmsolutionsliterature': routes.Resources,
  '/placing-business/submitting-application':
    routes.SubmittingBtlOrLtbMortgageApplication,
  '/placing-business/sustainability-hub': routes.SustainabilityHub,
  '/placing-business/sustainability-hub/greener-housing-market':
    routes.EpcRegulations,
  '/placing-business/sustainability-hub/lower-rates-energy-efficient-homes':
    routes.LowerRatesForEnergyEfficientHomes,
  '/portfolio-landlords': `${routes.OurCriteria}#lending`,
  '/portfolio-landlords/application-process': `${routes.OurCriteria}#lending`,
  '/portfoliolandlords': `${routes.OurCriteria}#lending`,
  '/registration-guidance-videos': routes.Register,
  '/stress-rates': `${routes.OurCriteria}#lending`,
  '/top-slicing': `${routes.OurCriteria}#top-slicing`,
  '/valuationfaqs': `${routes.OurCriteria}#property`,
};
