import React, { ReactNode } from 'react';

import { Box, ListItem, Paragraph, Text, Heading } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { StyledList } from './SectionBody.styled';
import BasicTable from '../basicTable/BasicTable';
import {
  ContentSection,
  ContentSectionItem,
} from '../infoPanel/InfoPanel.config';
import { LinkButton } from '../linkButton';

function DefaultContainer({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

interface SectionBodyProps {
  section: ContentSection;
  pageId: string;
  sectionIndex: number;
}

function SectionBody({ section, pageId, sectionIndex }: SectionBodyProps) {
  return (
    <Box
      bgColor="neutral2"
      padding="none"
      data-testid={`${pageId}-${sectionIndex}`}
    >
      {section.sectionBody.map((sectionItem: ContentSectionItem, index) => {
        const {
          boldText,
          text,
          subheading,
          keyValueList,
          list,
          basicTable,
          container,
          link,
          linkButton,
          listItemContainer,
          textWithLinks,
          component,
        } = sectionItem;

        const Container = container ?? DefaultContainer;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Container key={`${pageId}-section-${sectionIndex}-item-${index}`}>
            {boldText && (
              <Paragraph
                weight="bold"
                data-testid={`${pageId}-${sectionIndex}-bold-text-item-${index}`}
              >
                {boldText}
              </Paragraph>
            )}
            {text && (
              <Paragraph
                data-testid={`${pageId}-${sectionIndex}-text-item-${index}`}
              >
                {text}
              </Paragraph>
            )}
            {subheading &&
              // eslint-disable-next-line react/jsx-props-no-spreading
              (typeof subheading === 'string' ? (
                <Heading
                  size="s4"
                  marginBottom="02"
                  data-testid={`${pageId}-${sectionIndex}-heading-item-${index}`}
                  as="h2"
                >
                  {' '}
                  {subheading}
                </Heading>
              ) : (
                <Heading
                  size={subheading.size}
                  marginBottom={subheading.marginBottom}
                  data-testid={`${pageId}-${sectionIndex}-heading-item-${index}`}
                  as={subheading.headingLevel}
                  id={subheading.id}
                >
                  {subheading.displayText}
                </Heading>
              ))}
            {keyValueList && (
              <Paragraph
                data-testid={`${pageId}-${sectionIndex}-keyvaluelist-paragraph-item-${index}`}
              >
                {keyValueList.map((item, indexCount) => (
                  <Text
                    data-testid={`${pageId}-${sectionIndex}-keyvaluelist-item-${index}-${indexCount}`}
                    key={item}
                  >
                    {item}
                  </Text>
                ))}
              </Paragraph>
            )}
            {list && (
              <StyledList
                marginBottom="02"
                data-testid={`${pageId}-${sectionIndex}-styled-list-item-${index}`}
              >
                {list.map((item, indexCount) => {
                  const ListContainer = listItemContainer ?? DefaultContainer;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListContainer key={`${pageId}-${sectionIndex}-list-item-${index}-${indexCount}`}>
                      <ListItem
                        data-testid={`${pageId}-${sectionIndex}-list-item-${index}-${indexCount}`}
                      >
                        {item}
                      </ListItem>
                    </ListContainer>
                  );
                })}
              </StyledList>
            )}
            {basicTable && (
              <BasicTable
                testIdPrefix={basicTable.testIdPrefix}
                ariaLabel={basicTable.ariaLabel}
                columns={basicTable.columns}
                rows={basicTable.rows}
                width={basicTable.width}
                responsive={basicTable.responsive}
              />
            )}
            {link && (
              <StyledLink
                external={link.external ?? false}
                to={link.href}
                data-testid={`${pageId}-${sectionIndex}-link-item-${index}`}
              >
                {link.displayText}
              </StyledLink>
            )}
            {linkButton && (
              <LinkButton
                external={linkButton.external ?? false}
                to={linkButton.href}
                variation={linkButton.variation}
                testId={`${pageId}-${sectionIndex}-linkbutton-${index}`}
              >
                {linkButton.displayText}
              </LinkButton>
            )}
            {textWithLinks && (
              <Paragraph
                data-testid={`${pageId}-${sectionIndex}-textwithlinks-paragraph-item-${index}`}
              >
                {textWithLinks.map((item, indexCount) => {
                  if (typeof item === 'string') {
                    return item;
                  }

                  return (
                    <StyledLink
                      key={item.displayText}
                      external={item.external ?? false}
                      to={item.href}
                      data-testid={`${pageId}-${sectionIndex}-textwithlinks-link-item-${index}-${indexCount}`}
                    >
                      {item.displayText}
                    </StyledLink>
                  );
                })}
              </Paragraph>
            )}
            {/* render custom component if provided */}
            {component || null}
          </Container>
        );
      })}
    </Box>
  );
}

export default SectionBody;
