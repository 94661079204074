import React from 'react';

import { Text } from '@constellation/core';
import { Link as InterstellarLink } from '@interstellar/react-app-routing';

import { StyledLinkButton } from './LinkButton.styled';

export type LinkButtonProps = React.ComponentProps<typeof InterstellarLink> & {
  external?: boolean;
  testId?: string;
  variation?: 'secondary'
};

export function LinkButton({
  to,
  external,
  children,
  variation,
  testId
}: LinkButtonProps): React.JSX.Element {
  const target = external ? '_blank' : undefined;
  const rel = external ? 'noopener noreferrer' : undefined;

  return (
    <StyledLinkButton to={to} target={target} rel={rel} data-testid={testId} variation={variation}>
      <Text color="inherit">{children}</Text>
    </StyledLinkButton>
  );
}

export default LinkButton;
