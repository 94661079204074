import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';

const footer: AppContent['footer'] = {
  links: [
    {
      label: 'Terms & Conditions',
      Url: routes.TermsAndConditions,
    },
    {
      label: 'Accessibility',
      Url: routes.Accessibility,
    },
    {
      label: 'Privacy',
      Url: routes.Privacy,
    },
    {
      label: 'Security',
      Url: routes.Security,
    },
    {
      label: 'Cookies',
      Url: routes.Cookies,
    },
    {
      label: 'Sitemap',
      Url: routes.Sitemap,
    },
  ],
  legalText:
    'Birmingham Midshires is a division of Bank of Scotland plc. Registered in Scotland No. SC327000. Registered Office: The Mound, Edinburgh EH1 1YZ. Bank of Scotland plc is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 169628.',
};

export default footer;
