import React from 'react';

import { Box } from '@constellation/core';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const submittingBtlOrLtbMortgageApplication: AppContent['submittingBtlOrLtbMortgageApplication'] =
  {
    pageTitle: 'Submitting a new BTL or LTB mortgage application',
    metaContent: MetaContentValues.SubmittingBtlOrLtbMortgageApplication,
    backButtonLabel: 'Back',
    backButtonHref: routes.PlacingBusiness,
    infoPanel: {
      contentSections: [
        {
          sectionID: 'intro-content',
          sectionBody: [
            {
              textWithLinks: [
                'You need to ',
                {
                  displayText: 'sign in to Intermediaries online',
                  href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
                  external: true,
                },
                ' to submit an application.',
              ],
            },
            {
              text: "You'll need a 10-character user ID to sign in. If you don't have a user ID, please read the registration guidance section on this page.",
            },
            {
              textWithLinks: [
                'If you need any support keying an application, ',
                {
                  displayText: 'refer to our guide',
                  href: '/public/bm-online-hints-tips.pdf',
                  external: true,
                },
                ' or contact your ',
                {
                  displayText: 'Business Development Manager',
                  href: routes.BDMSearch,
                },
                '.',
              ],
            },
          ],
        },
        {
          sectionID: 'max-broker-fees',
          isAccordion: true,
          sectionHeading: 'Maximum broker fees',
          headingLevel: 'h2',
          sectionBody: [
            {
              text: 'Lloyds Banking Group have the following caps on the amount clients can be charged by brokers for their mortgage application services. Our up-to-date mortgage fees are:',
            },
            {
              container: ({ children }) => (
                <Box bgColor="none" padding="none">
                  {children}
                </Box>
              ),
              basicTable: {
                ariaLabel: 'Table for maximum broker fees',
                testIdPrefix: 'max-broker-fees-table',
                width: 'auto',
                columns: [
                  { displayText: 'Example', key: 'column-example' },
                  { displayText: 'Fee', key: 'column-fee' },
                ],
                rows: [
                  {
                    'column-example': 'Loan £100,000',
                    'column-fee': 'Max fee of £1,500',
                  },
                  {
                    'column-example': 'Loan £200,000',
                    'column-fee': 'Max fee of £2,000',
                  },
                  {
                    'column-example': 'Loan £400,000',
                    'column-fee': 'Max fee of £4,000',
                  },
                ],
              },
            },
            {
              boldText:
                'A cap of 1% of the loan amount or £1,500, whichever is the greater.',
            },
          ],
        },
        {
          sectionID: 'applicant-details',
          isAccordion: true,
          sectionHeading: 'Keying / amending applicant details',
          headingLevel: 'h2',
          sectionBody: [
            {
              text: 'If you need to change the order of applicants or add/remove an applicant, please follow these instructions. This will make sure the right data is allocated to each applicant.',
            },
            { boldText: 'To change the applicant order:' },
            {
              list: [
                'Go to the applicant summary table at the bottom of the personal details screen.',
                'Use the ↓ and ↑ arrows on the left-hand side to amend the order.',
                "Select the 'Submit' button when you're done.",
              ],
            },
            { boldText: 'To remove an applicant:' },
            {
              list: [
                'Go to the applicant summary table at the bottom of the personal details screen.',
                "Select the 'Delete' button on the right-hand side on the applicant you want to remove.",
                "Select the 'Submit' button when you're done.",
              ],
            },
            { boldText: 'To add an applicant:' },
            {
              list: [
                "Select the 'Add another applicant' button on the personal details screen.",
                'Complete the new applicant details.',
                "Select the 'Save applicant' button.",
                "Select the 'Submit' button when you're done.",
              ],
            },
            {
              text: 'You must not change any of the information in the name, date of birth, or other personal details sections from one applicant to another.',
            },
            {
              textWithLinks: [
                'If you have any questions, please speak to your ',
                {
                  displayText: 'Business Development Manager',
                  href: routes.BDMSearch,
                },
                ' before making any changes to an application.',
              ],
            },
          ],
        },
      ],
    },
  };

export default submittingBtlOrLtbMortgageApplication;
