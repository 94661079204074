import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { InfoPanelContent } from 'client/components/infoPanel/InfoPanel.config';

import { anchorLink, anchors } from './anchors';
import * as routes from '../../../routes/manifest';

const property: {
  title: string;
  id: string;
  infoPanel: InfoPanelContent;
} = {
  title: 'Property',
  id: anchors.property.id,
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Builder cash incentives',
        sectionID: anchors.property.builderCashIncentives,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                Examples of builder cash incentives include deposit
                contributions, cash-backs, contribution to legal fees/stamp
                duty, and mortgage subsidies.
              </>
            ),
          },

          {
            text: "Builder cash incentives are usually acceptable if their value isn't more than 5% of the lower of the purchase price or valuation. Cash incentives more than this might mean a lower maximum loan amount is available.",
          },
          {
            text: (
              <>
                All builder cash incentives must be declared at the point of
                sale. All lending decisions are based on either the valuation or
                purchase price, whichever is lower.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.newBuild),
              displayText: 'New build',
            },
          },
        ],
      },
      {
        sectionHeading: 'Buy to let property address',
        sectionID: anchors.property.buyToLetPropertyAddress,
        isAccordion: true,
        sectionBody: [
          {
            text: "The buy to let property address must not be the same as the customers' correspondence address.",
          },
        ],
      },
      {
        sectionHeading: 'Cladding',
        sectionID: anchors.property.cladding,
        isAccordion: true,
        sectionBody: [
          {
            text: <Strong>Properties in England and Wales</Strong>,
          },

          {
            text: "Properties in buildings five storeys or higher no longer need an External Wall System (EWS1) form in order to progress an application. We'll also consider applications on properties where cladding remediation work is needed but not yet completed.",
          },
          {
            text: (
              <>
                EWS1 forms might still be needed for buildings four storeys or
                lower. The surveyor will advise when an External Wall System
                (EWS1) form is required to confirm the status of the external
                wall system and any applicable attachments e.g. balconies on
                multi occupied, multi storey buildings of any height.
              </>
            ),
          },
          {
            text: <Strong>Properties in Scotland and Northern Ireland</Strong>,
          },

          {
            text: (
              <>
                Will still need an EWS1 form regardless of height. Customers
                will have to source the EWS1 form themselves.
              </>
            ),
          },
          {
            text: (
              <>
                The building owner and/or agent is responsible for confirming an
                external wall system or attachments, such as a balcony, on
                buildings containing flats has been assessed by a suitable
                expert.
              </>
            ),
          },
          {
            textWithLinks: [
              'The EWS1 form must be prepared by a qualified independent professional. They must be a member of one of the professional bodies ',
              {
                displayText: 'listed by RICS.',
                external: true,
                href: 'https://www.rics.org/news-insights',
              },
            ],
          },
          {
            text: "We can't confirm if a property is acceptable until a valuation has been instructed.",
          },
          {
            text: "For purchase cases, a special condition will be added to a mortgage offer to instruct the conveyancer to advise the customer an offer has been made based on the information in an EWS1. It'll also make sure the customer knows neither us nor the surveyor are liable for information in the EWS1 form.",
          },
          {
            text: <Strong>New build high rise blocks</Strong>,
          },
          {
            text: (
              <>
                In England and Wales, The Building (Amendment) Regulations 2018
                or local equivalent can be used for new builds over six storeys
                or 18 metres high. A conveyancer will need to confirm this has
                been used by the building owner or agent.
              </>
            ),
          },
          {
            text: (
              <>
                This applies to new builds of four storeys or 11 metres high in
                Scotland.
              </>
            ),
          },
          {
            text: "If this can't be confirmed, please follow the EWS1 process.",
          },
        ],
      },
      {
        sectionHeading: 'Condition',
        sectionID: anchors.property.condition,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                The property must be habitable, readily lettable or saleable,
                and structurally sound.
              </>
            ),
          },
          {
            text: (
              <>
                Buildings insurance also needs to be able to be arranged on it.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Construction',
        sectionID: anchors.property.construction,
        isAccordion: true,
        sectionBody: [
          {
            text: "The property must be in the UK under UK legal jurisdiction. Property acceptability is based on a satisfactory valuation report from the bank's appointed valuer.",
          },
          {
            text: (
              <>
                Non-standard construction will be assessed on individual merit.
              </>
            ),
          },
          {
            text: "Certain types of pre-cast reinforced concrete (PRC) construction are classed as defective. We might not accept it unless it's been repaired.",
          },
          {
            text: "We'll be guided by the suitability of all mortgage properties by the valuer.",
          },
          {
            list: [
              "Adjoining property purchases are acceptable, subject to valuer's comments.",
              "Houseboats and mobile homes/park homes aren't acceptable.",
              'Properties with annexes are acceptable as long as the annexe forms part of the main title. There should also be no separate tenancy agreement in place for the annexe.',
              "Studio flats are acceptable subject to valuer's comments. There is no minimum size requirement.",
              "Right to Buy/Shared ownership applications aren't acceptable.",
              'If a flat is part of a conversion, where the total number of flats in the scheme is above eight, the valuer must confirm the whole conversion project has been or will be completed to a high standard.',
              "Flats above/next to a business owned by the same person aren't acceptable.",
              "Flats above/next to a business not owned by the same person are subject to valuer's comments.",
            ],
          },
          {
            textWithLinks: [
              'For details of our what we need where a property has been converted into flats or is part of a purpose-built block, see ',
              {
                displayText: 'Tenure of flats.',
                external: false,
                href: anchorLink(anchors.property.tenure),
              },
            ],
          },
        ],
      },
      {
        sectionHeading: 'Distressed sale and leaseback',
        sectionID: anchors.property.distressedSaleAndLeaseback,
        isAccordion: true,
        sectionBody: [
          {
            text: "Applications involving a distressed sale, or a sale and leaseback aren't acceptable.",
          },
        ],
      },
      {
        sectionHeading: 'Energy performance certificate',
        sectionID: anchors.property.energyPerformanceCertificate,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                An energy performance certificate (EPC) gives domestic
                properties an energy-efficiency rating from A to G. The EPC is
                valid for 10 years. If the property is sold in that time, the
                EPC can be used by the new owner.
              </>
            ),
          },
          {
            list: [
              'Properties in England, Northern Ireland, Scotland, and Wales must have a valid EPC rating of E or above.',
              'Purchase, remortgage, and Let to Buy applications on properties with F or G EPC ratings are only allowed where a registered exemption is in place.',
              'We do not accept further advance applications where the EPC rating is F, G or an exemption is in place, except when the purpose of loan is for BTL repairs.',
              'Product transfers are exempt.',
              'For new build properties, use the Predicted Energy Assessment (PEA) or Standard Assessment Procedure (SAP) rating if no EPC is available.',
              "If a property is exempt, evidence isn't needed unless we request it.",
              'Evidence of EPC, PEA, SAP, or exemption evidence should be kept on file.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Exposure limits',
        sectionID: anchors.property.exposureLimits,
        isAccordion: true,
        sectionBody: [
          {
            text: "When a site is assessed as suitable for lending, we'll no longer apply an initial cap on our lending exposure. We'll continue to monitor applications (exposure) as the development progresses and might restrict further lending if concerns arise.",
          },
          {
            text: (
              <>
                There might be a small number of sites where we decide not to
                lend on because of specific market or site conditions.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Free valuations',
        sectionID: anchors.property.freeValuations,
        isAccordion: true,
        sectionBody: [
          {
            text: "If a free valuation is selected as part of a product incentive, the appeal process on the property valuation isn't available.",
          },
          {
            text: (
              <>
                If you want to appeal the rental valuation on a BTL remortgage
                application, please contact us.
              </>
            ),
          },
        ],
      },
      {
        sectionHeading: 'Houses in multiple occupation',
        sectionID: anchors.property.housesInMultipleOccupation,
        isAccordion: true,
        sectionBody: [
          {
            text: "Properties subject to Local Authority Homes in Multiple Occupation (HMO) licensing might be acceptable, subject to the valuer's assessment of suitability based on our requirements:",
          },
          {
            list: [
              "The maximum number of unrelated tenants is five. They must all be named on the agreement. It's the landlords' responsibility to make sure all legislation is complied with, including (but not restricted to) legislation relating to Tenancy Deposit schemes and HMOs.",
              'The property must be suitable for standard residential occupation.',
              "Institutional type properties, for example hostel accommodation/bedsits/shared accommodation comparable to halls of residence, aren't acceptable.",
              'For larger properties, where there is a potential of more than five lettable rooms, the valuer must be satisfied the property and local market reflects single family letting arrangements.',
              'The property can be let on a single assured shorthold tenancy in England and Wales, a private residential tenancy in Scotland, and a private tenancy in Northern Ireland. The tenancy in Northern Ireland must comply with the provisions of the Private Tenancies (Northern Ireland) Order 2006.',
            ],
          },
          {
            component: (
              <Paragraph marginTop="05">
                <Strong>Additional information:</Strong>
              </Paragraph>
            ),
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lettingCriteria),
              displayText: 'Letting criteria',
            },
          },
        ],
      },
      {
        sectionHeading: 'Inherited properties',
        sectionID: anchors.property.inheritedProperties,
        isAccordion: true,
        sectionBody: [
          {
            text: "We'll accept a remortgage on an inherited property where the applicant is a beneficiary, but hasn't owned the property for six months.",
          },
          {
            text: "Probate must have been granted before an application and written confirmation of the inheritance of the property and grant of probate is needed from the customer's conveyancer.",
          },
        ],
      },
      {
        sectionHeading: 'Japanese knotweed',
        sectionID: anchors.property.japaneseKnotweed,
        isAccordion: true,
        sectionBody: [
          {
            text: "We'll lend if the valuer confirms the property is suitable mortgage security and provides a present condition valuation figure.",
          },
          {
            text: "To assess the property, the valuer will need a report outlining the issues and a quote from a treatment company. The company must either be a member of the Property Care Association or offer a warranty-backed treatment plan. If treatment is needed, it's often over 3-4 years.",
          },
        ],
      },
      {
        sectionHeading: 'New build',
        sectionID: anchors.property.newBuild,
        isAccordion: true,
        sectionBody: [
          {
            text: 'An initial occupancy/new build property is classed as any property being occupied and/or sold for the first time on the open market in its current state. It also includes newly converted and refurbished properties. These will fall into one of the following categories:',
          },
          {
            list: [
              'Newly built property.',
              'Refurbished property. These are usually as initial occupancy where the vendor is a builder/developer and the property has been vacated, so it can be refurbished.',
              'Newly converted property. An example of this is a barn or warehouse converted into residential flats or houses.',
              "A property, either new or converted, that's been tenanted and is now offered for sale for the first time on the open market by the builder/developer.",
            ],
          },
          {
            component: (
              <Paragraph marginTop="05">
                <Strong>
                  The property must have a Building Standards Indemnity Scheme
                  from a warranty provider accepted by Lloyds Banking Group:
                </Strong>
              </Paragraph>
            ),
          },
          {
            list: [
              'ABC+.',
              'Advantage HCI.',
              'ARK Insurance.',
              'Building LifePlans Ltd (BLP).',
              'Buildzone.',
              'Checkmate.',
              'Global Home Warranties.',
              'HomeProof (previously Aedis).',
              'International Construction Warranties (ICW).',
              'NHBC.',
              'One Guarantee.',
              'Premier Guarantee - includes the LABC New Homes Warranty and LABC Hallmark.',
              'Protek.',
              'Q Assure Build.',
            ],
          },
          {
            component: (
              <Paragraph marginTop="05">
                <Strong>
                  For the property to qualify as acceptable for monitoring by a
                  consultant, it must be either:
                </Strong>
              </Paragraph>
            ),
          },
          {
            list: [
              'A single detached property - either self-built or otherwise - where construction is monitored by a consultant employed directly by the customer.',
              'A property built by a self-build group, where construction is monitored by a consultant employed by that self-build group.',
              'A property forming part of a small, solely residential development of not more than 15 units in total, where construction is monitored by a consultant employed by the builder or developer. If flats/maisonettes are involved, each individual flat/maisonette counts as one property. Developments containing more than 15 units need an acceptable form of building standards indemnity.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Revised property details',
        sectionID: anchors.property.revisedPropertyDetails,
        isAccordion: true,
        sectionBody: [
          {
            text: "If the purchase property address changes once a mortgage application has been made, and the customer's original mortgage product has been withdrawn, a new deal from the current range must be selected.",
          },
          {
            text: "If the property being purchased isn't changing but an amendment is needed to the address keyed, such as the property number or street name, this must be changed by us before completion. Please contact us to make this change.",
          },
        ],
      },
      {
        sectionHeading: 'Scottish valuations',
        sectionID: anchors.property.scottishValuations,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                We can no longer accept re-type/transcription reports from a
                Generic Mortgage Valuation Report (GMVR) with a valid Home
                Report.
              </>
            ),
          },
          {
            textWithLinks: [
              'For further clarification, ',
              {
                displayText: 'please contact your BDM.',
                href: routes.BDMSearch,
              },
            ],
          },
        ],
      },
      {
        sectionHeading: 'Sub-sales and back-to-back transactions',
        sectionID: anchors.property.subSalesAndBackToBackTransactions,
        isAccordion: true,
        sectionBody: [
          {
            text: "Sub-sales and back-to-back transactions aren't acceptable.",
          },
          {
            text: "The current vendor must have owned the property for at least six months before we can accept an application to purchase the property. The exception to this is if the property is inherited and the customer hasn't owned it for six months.",
          },
          {
            text: (
              <>
                We use the date of registration at the Land Registry to work out
                the length of ownership.
              </>
            ),
          },
          {
            text: (
              <>
                A back-to-back transaction is a type of sub-sale where the
                intervening seller buys from the original seller and sells on to
                the borrower on the same day or within a few days. We also class
                cases where the seller acquires the freehold - or superior
                leasehold - title to the property, and immediately sells on to
                the borrower by grant of lease or sub-lease as sub-sales.
              </>
            ),
          },
          {
            text: (
              <Strong>
                {`We'll accept the property being sold on within six months of acquisition where sales are by:`}
              </Strong>
            ),
          },
          {
            list: [
              'a personal representative of the registered proprietor',
              'an institutional mortgagee exercising its power of sale',
              'a receiver, trustee-in-bankruptcy or liquidator',
              'a developer or builder selling a property acquired under a part-exchange scheme',
              'a registered housing provider or housing association exercising a power of sale.',
            ],
          },
          {
            text: (
              <>
                The conveyancer will be responsible for making sure the
                application meets the acceptable criteria.
              </>
            ),
          },
          {
            text: "Applications involving assignable contracts or irrevocable powers of attorney in favour of intervening sellers aren't acceptable. You should tell us about any other transaction structure with a similar effect.",
          },
        ],
      },
      {
        sectionHeading: 'Tenure',
        sectionID: anchors.property.tenure,
        isAccordion: true,
        sectionBody: [
          {
            text: "Freehold and leasehold houses are acceptable. Leasehold properties must have a minimum of 70 years left on the lease at application."
          },
          {
            text: "If a property's been converted into no more than four units or is within a purpose-built block, any leases should already be in place and not created at the same time as the mortgage application.",
          },
          {
            text: "We'll consider lending in these situations:",
          },
          {
            text: (
              <Strong>
                Tenure is leasehold and the applicant does not own the freehold
                or share of freehold:
              </Strong>
            ),
          },
          {
            list: [
              'The conveyancer will make sure the lease meets our standard requirements.',
            ],
          },
          {
            text: (
              <Strong>
                Tenure is leasehold and the applicant owns/will own the freehold of the whole building on completion:
              </Strong>
            ),
          },
          {
            list: [
              'Our charge must be registered on the freehold of the whole building and the leasehold interest of the unit. The conveyancer will make sure the lease meets our standard requirements.',
              "The mortgage must be in the same name as the freehold and leasehold titles. It's not acceptable to hold the freehold in a Ltd company with the customer as sole director, majority shareholder, or with any family member.",
              "It's only acceptable for the customer to own one unit in the whole building, including any new application.",
            ],
          },
          {
            text: (
              <Strong>
                Tenure is leasehold and the applicant owns / will own on
                completion a share of the freehold of the whole building:
              </Strong>
            ),
          },
          {
            list: [
              'Our charge must be registered on the leasehold interest only. The conveyancer will make sure the lease meets our standard requirements.',
              "It's only acceptable for the customer to own one unit in the whole building, including any new application.",
            ],
          },
          {
            text: <Strong>Tenure is freehold:</Strong>,
          },
          {
            list: [
              'The security will be registered on the freehold of the whole building and processed as a freehold house.',
              'The applicant must own the freehold in their own name.',
              "The mortgage and freehold title must be in the same name. It's not acceptable to hold the freehold in a Ltd Company with the customer as sole director, majority shareholder, or with any family member.",
              "All units other than the freehold unit on which we're lending must have a minimum lease of 70 years.",
              "It's only acceptable for the customer to own one unit in the whole building, including any new applications.",
            ],
          },
          {
            text: <Strong>For developments of more than four units:</Strong>,
          },
          {
            list: [
              'Tenure must be leasehold.',
              'A management company must be in place.',
              'Our customer can hold a share in the management company.',
              'The conveyancer will make sure arrangements in connection with the management company are acceptable.',
              'Our mortgage must be registered on the leasehold title.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Unacceptable construction types',
        sectionID: anchors.property.unacceptableConstruction,
        isAccordion: true,
        sectionBody: [
          {
            list: [
              'Timber or metal framed buildings where the cavity has been retrospectively filled with an insulation material.',
              'Concrete walls built in Cornwall or Devon before 1950. Or 1960 for postcodes PL12, 13, 14, 15, 17, 18, 22, and 23, where a valuer has recommended a Mundic report, and a concrete test has classified it as class B or C.',
              'Unrepaired, designated defective properties under the Housing Act 1985, Housing (Scotland) Act 1987, Housing (Northern Ireland) Order 1986.',
              'Large panel system type lats or maisonette blocks without an acceptable structural appraisal.',
              'Load bearing panels made of asbestos or gypsum plaster.',
              'Structurally unsound or uninsurable properties.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Use of property',
        sectionID: anchors.property.useOfProperty,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <Strong>
                {`The following property types aren't acceptable as security:`}
              </Strong>
            ),
          },
          {
            list: [
              'A dwelling on a single title must not be split into separate units of accommodation, such as a house converted into two flats, so each flat would need its own title',
              'property purchased from and let to the same family member.',
              'Bedsits.',
              'Commercial property.',
              'Ex-local authority flats, unless the majority of the building is owner-occupied.',
              'Farms or property with land subject to commercial agricultural use.',
              'Live/work units',
              'Mobile homes/houseboats',
              'Property assessed as unsuitable security by the valuer.',
              'Property with restricted occupancy.',
              'Property designated defective under the Housing Defects Act 1984.',
              "Properties where buildings insurance can't be arranged",
              'Right to Buy/Shared ownership applications.',
              'Timeshares.',
              'Properties with an Energy Performance Certificate (EPC) rating of F or G with no registered exemption in place.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Valuation',
        sectionID: anchors.property.valuation,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                The minimum acceptable property value or purchase price is{' '}
                <Strong>£50,000</Strong> - whichever is the lower.
              </>
            ),
          },
          {
            text: "A mortgage valuation is valid for 12 months. If a mortgage doesn't complete within 12 months of the valuation being carried out, a new valuation will be needed. We'll advise you if a new valuation fee is also needed.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.scottishValuations),
              displayText: 'Scottish valuations',
            },
          },
        ],
      },
    ],
  },
};

export default property;
