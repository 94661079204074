import { Box, Link } from '@constellation/core';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
  &&& {
    padding: ${({ theme }) => theme.spacing_05};
    padding-bottom: 0;
    background: #fff;
    border-radius: 1rem;
    border: 2px solid ${({ theme }) => theme.color_border_default_2};
  }
`;

export const StyledLink = styled(Link)`
  &&& {
    word-wrap: break-word;
  }
`;
