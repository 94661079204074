import React, { ReactNode, useContext } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import BrandContext from 'client/components/brandProvider/BrandContext';
import AppFooter from 'client/components/footer/AppFooter';
import { HeaderComponent } from 'client/components/header';
import content from 'client/content';
import styled from 'styled-components';

import { Disclaimer } from '../disclaimer';
import { NavigationMenu } from '../navigationMenu';

interface LayoutProps {
  /** Optional children of the component. */
  logo?: string;
  children?: ReactNode;
}

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

function AppLayout({ children, logo }: LayoutProps): React.JSX.Element {
  const { brand } = useContext(BrandContext);

  return (
    <ContentProvider value={content({ brand }).appLayout}>
      <StyledPage>
        <Disclaimer />
        <HeaderComponent logo={logo} />
        <NavigationMenu />
        {children}
        <AppFooter />
      </StyledPage>
    </ContentProvider>
  );
}

export default AppLayout;
