import React from 'react';

import { useContent } from '@interstellar/react-app-content';
import BM_Solutions_Head from 'assets/common/BM_Solutions_Head.png';
import ArticleView from 'client/components/articleView/articleView/ArticleView';


import { ArticleImprovingQualityBenefitsLandlordsAndTenantsContent } from './ArticleImprovingQualityBenefitsLandlordsAndTenants.config';

function ArticleImprovingQualityBenefitsLandlordsAndTenantsPage(): React.JSX.Element {
  const articleContent =
    useContent<ArticleImprovingQualityBenefitsLandlordsAndTenantsContent>();

  return (
    <ArticleView content={articleContent} imgSrc={BM_Solutions_Head} />
  );
}

export default ArticleImprovingQualityBenefitsLandlordsAndTenantsPage;
