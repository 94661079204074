import React from 'react';

import {
  Container,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { EducationalHubContent } from './EducationalHub.config';

function EducationalHubPage(): React.JSX.Element {
  const {
    metaContent,
    pageTitle,
    iFramePanel,
    backButtonLabel,
    backButtonHref,
    infoPanel,
  } = useContent<EducationalHubContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={backButtonHref}
          marginRight="04"
        >
          {backButtonLabel}
        </StyledLink>
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <Container padding="none">
              <Paragraph>
                <Strong>{iFramePanel.heading}</Strong>
              </Paragraph>
              <Grid>
                <GridItem md={8} lg={8}>
                  <iframe
                    data-testid="iframe-video-player"
                    width="100%"
                    height="350"
                    src={iFramePanel.url}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                </GridItem>
              </Grid>
            </Container>
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={{
                sectionHeading: 'educational-hub-heading',
                sectionBody: 'educational-hub-body',
              }}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default EducationalHubPage;
