import React, { useState } from 'react';

import {
  Main,
  Heading,
  Button,
  Paragraph,
  TextField,
  Selector,
  SelectorItem,
  Grid,
  GridItem,
  Box,
  CurrencyField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { ErrorSummaryBuilder } from 'client/components/errorSummaryBuilder';
import MetaTags from 'client/components/metaTags/MetaTags';
import NumberField from 'client/components/numberField';
import { useScreen } from 'client/context/ScreenContext';
import testIds from 'client/testIds';
import { Field, Form, Formik } from 'formik';

import { formatNumber } from './formatters/formatNumber';
import { stripNonNumericalCharacters } from './formatters/stripNonNumericalCharacters';
import { RateChangeCalculatorContent } from './RateChangeCalculator.config';
import { calculateMonthlyPaymentChange } from './validation/calculateMonthlyPaymentChange';
import isValidInterestRate from './validation/isValidInterestRate';
import isValidInterestRateChange from './validation/isValidInterestRateChange';
import isValidMortgageBalance from './validation/isValidMortgageBalance';
import isValidTermLengthMonths from './validation/isValidTermLengthMonths';
import isValidTermLengthYears from './validation/isValidTermLengthYears';

interface FormValues {
  mortgageBalanceField: string;
  termLengthYearsField: number;
  termLengthMonthsField: number;
  interestRateField: number;
  repaymentType: string;
  interestRateChange: number;
}

export default function RateChangeCalculatorPage() {
  const { isMobile } = useScreen();
  const widthValue = isMobile ? 'fluid' : 'default';
  const RateChangeCalculator = useContent<RateChangeCalculatorContent>();
  const [showSummaryError, setShowSummaryError] = useState<boolean>(false);
  const [interestRates, setInterestRates] = useState<any>({
    interestRate: '',
    interestRateChange: '',
  });
  const hasEmptyFields = (
    values: { [s: string]: unknown } | ArrayLike<unknown> | FormValues,
  ) => Object.values(values).some((value) => value === undefined);
  const [monthlyPaymentChange, setMonthlyPaymentChange] =
    useState<number>(undefined);
  const validateForm = (values: FormValues) => {
    const errors: Partial<{
      mortgageBalanceField: string;
      termLengthYearsField: string;
      termLengthMonthsField: string;
      interestRateField: string;
      interestRateChange: string;
    }> = {};

    if (!isValidMortgageBalance(values.mortgageBalanceField)) {
      errors.mortgageBalanceField = RateChangeCalculator.errors.mortgageBalance;
    }
    if (!isValidTermLengthYears(values.termLengthYearsField)) {
      errors.termLengthYearsField = RateChangeCalculator.errors.termLengthYears;
    }
    if (
      !isValidTermLengthMonths(
        values.termLengthYearsField,
        values.termLengthMonthsField,
      )
    ) {
      errors.termLengthMonthsField =
        RateChangeCalculator.errors.termLengthMonths;
    }
    if (!isValidInterestRate(values.interestRateField)) {
      errors.interestRateField = RateChangeCalculator.errors.interestRate;
    }
    if (
      !isValidInterestRateChange(
        values.interestRateField,
        values.interestRateChange,
      )
    ) {
      errors.interestRateChange =
        RateChangeCalculator.errors.interestRateChange;
    }
    return errors;
  };

  const touchedAndError = (touched, errors, fieldName) =>
    touched[fieldName] && errors[fieldName];

  return (
    <Main>
      <MetaTags metaContent={RateChangeCalculator.metaContent} />

      <Heading
        as="h1"
        size="s7"
        marginTop="05"
        data-testid={testIds.rateChangeCalculator.heading}
      >
        {RateChangeCalculator.pageHeading}
      </Heading>

      <Grid>
        <Formik
          initialValues={{
            mortgageBalanceField: undefined,
            termLengthYearsField: undefined,
            termLengthMonthsField: undefined,
            interestRateField: undefined,
            repaymentType: 'repayment',
            interestRateChange: 0,
          }}
          validate={validateForm}
          onSubmit={(values: FormValues) => {
            setInterestRates({
              interestRate: values.interestRateField,
              interestRateChange: values.interestRateChange,
            });
            setMonthlyPaymentChange(calculateMonthlyPaymentChange(values));
          }}
        >
          {({
            values,
            touched,
            isValid,
            handleSubmit,
            setFieldValue,
            setValues,
            errors,
          }) => (
            <>
              <GridItem xs={12} sm={12} md={8} lg={8} xl={8}>
                <Form noValidate>
                  {showSummaryError && !isValid && (
                    <ErrorSummaryBuilder
                      errors={errors}
                      errorTitle={RateChangeCalculator.errors.title}
                      testId={
                        testIds.rateChangeCalculator.errorSummaryContainer
                      }
                    />
                  )}
                  <Grid>
                    <GridItem xs={12} sm={12} md={12} lg={6} xl={6}>
                      <Field
                        name="mortgageBalanceField"
                        as={TextField}
                        maxLength={11}
                        placeholder="£"
                        label={RateChangeCalculator.mortgageBalanceFieldLabel}
                        value={
                          values.mortgageBalanceField &&
                          values.mortgageBalanceField.toLocaleString()
                        }
                        inputWidth={widthValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value.replace(/[^0-9]/g, '');
                          setFieldValue(
                            'mortgageBalanceField',
                            formatNumber(stripNonNumericalCharacters(value)) ||
                              undefined,
                          );
                        }}
                        error={touchedAndError(
                          touched,
                          errors,
                          'mortgageBalanceField',
                        )}
                        data-testid={
                          testIds.rateChangeCalculator.mortgageBalanceField
                        }
                      />

                      <Heading
                        as="h2"
                        size="s3"
                        data-testid="interim-statement-section-heading"
                      >
                        {RateChangeCalculator.termLengthHeading}
                      </Heading>

                      <Grid>
                        <GridItem xs={6} sm={6} md={3} lg={4}>
                          <Field
                            name="termLengthYearsField"
                            maxLength={2}
                            as={CurrencyField}
                            showCurrencySymbol={false}
                            label={
                              RateChangeCalculator.termLengthYearsFieldLabel
                            }
                            value={values.termLengthYearsField}
                            inputWidth={widthValue}
                            error={
                              touched.termLengthYearsField &&
                              errors.termLengthYearsField
                            }
                            data-testid={
                              testIds.rateChangeCalculator.termLengthYearsField
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue(
                                'termLengthYearsField',
                                e.target.value.replace(/[^0-9]/g, '') ||
                                  undefined,
                              );
                            }}
                          />
                        </GridItem>

                        <GridItem xs={6} sm={6} md={3} lg={4}>
                          <Field
                            name="termLengthMonthsField"
                            as={CurrencyField}
                            showCurrencySymbol={false}
                            label={
                              RateChangeCalculator.termLengthMonthsFieldLabel
                            }
                            value={values.termLengthMonthsField}
                            inputWidth={widthValue}
                            maxLength={2}
                            error={
                              touched.termLengthMonthsField &&
                              errors.termLengthMonthsField
                            }
                            data-testid={
                              testIds.rateChangeCalculator.termLengthMonthsField
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue(
                                'termLengthMonthsField',
                                e.target.value.replace(/[^0-9]/g, '') ||
                                  undefined,
                              );
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Field
                        name="interestRateField"
                        as={CurrencyField}
                        showCurrencySymbol={false}
                        label={RateChangeCalculator.interestRateFieldLabel}
                        value={values.interestRateField}
                        error={
                          touched.interestRateField && errors.interestRateField
                        }
                        data-testid={testIds.rateChangeCalculator.interestField}
                        maxLength={5}
                        inputWidth={widthValue}
                      />

                      <Field
                        as={Selector}
                        variation="Spaced-Auto"
                        label="Repayment type"
                        name="Repayment type"
                        value={values.repaymentType}
                        supportiveText="Please choose one of these options"
                      >
                        <Field
                          as={SelectorItem}
                          defaultChecked
                          id="repayment-type-repayment"
                          name="repaymentType"
                          value="repayment"
                          label={RateChangeCalculator.repaymentType.repayment}
                          data-testid={
                            testIds.rateChangeCalculator.repaymentTypeRepayment
                          }
                        >
                          {RateChangeCalculator.repaymentType.repayment}
                        </Field>

                        <Field
                          as={SelectorItem}
                          id="repayment-type-interest-only"
                          name="repaymentType"
                          value="interest-only"
                          label={
                            RateChangeCalculator.repaymentType.interestOnly
                          }
                          data-testid={
                            testIds.rateChangeCalculator
                              .repaymentTypeInterestOnly
                          }
                        >
                          {RateChangeCalculator.repaymentType.interestOnly}
                        </Field>
                      </Field>

                      <Field
                        as={NumberField}
                        id="interestRateChange"
                        inputWidth={widthValue}
                        name={RateChangeCalculator.interestRateChangeFieldLabel}
                        label={
                          RateChangeCalculator.interestRateChangeFieldLabel
                        }
                        step={0.25}
                        min={-15}
                        max={15}
                        value={values.interestRateChange}
                        error={
                          touched.interestRateChange &&
                          errors.interestRateChange
                        }
                        onChange={(interestRateChange) => {
                          setValues({ ...values, interestRateChange });
                        }}
                        maxLength={5}
                        data-testid={
                          testIds.rateChangeCalculator.interestFieldChange
                        }
                      />

                      <Button
                        type="submit"
                        data-testid={testIds.rateChangeCalculator.submitBtn}
                        onClick={() => {
                          if (!isValid || hasEmptyFields(values)) {
                            setShowSummaryError(true);
                            window.scrollTo({ top: 140, behavior: 'smooth' });
                          } else {
                            handleSubmit();
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </GridItem>
                  </Grid>
                </Form>
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                {monthlyPaymentChange !== undefined &&
                  !Number.isNaN(values.interestRateField) &&
                  !Number.isNaN(values.interestRateChange) &&
                  interestRates.interestRate === values.interestRateField &&
                  interestRates.interestRateChange ===
                    values.interestRateChange && (
                    <>
                      <Box
                        marginTop={isMobile ? '04' : 'none'}
                        data-testid={
                          testIds.rateChangeCalculator.monthlyPaymentContainer
                        }
                      >
                        <Paragraph
                          data-testid={
                            testIds.rateChangeCalculator.monthlyPaymentParagraph
                          }
                        >
                          {RateChangeCalculator.repaymentText.firstLine}{' '}
                          {Number(values.interestRateField).toFixed(2)}%{' '}
                          {RateChangeCalculator.repaymentText.secondLine}{' '}
                          {(
                            Number(values.interestRateChange) +
                            Number(values.interestRateField)
                          )?.toFixed(2)}
                          % {RateChangeCalculator.repaymentText.thirdLine}
                        </Paragraph>

                        <Heading
                          size="s4"
                          data-testid={
                            testIds.rateChangeCalculator.monthlyPaymentChange
                          }
                        >
                          {monthlyPaymentChange > 0 &&
                            (Math.sign(Number(values.interestRateChange)) === -1
                              ? '-'
                              : '+')}
                          £{monthlyPaymentChange.toLocaleString()}{' '}
                          {RateChangeCalculator.repaymentText.fourthLine}
                        </Heading>
                      </Box>
                      <Paragraph marginTop="none">
                        {
                          RateChangeCalculator.repaymentText
                            .illustrativePurposes
                        }
                      </Paragraph>
                    </>
                  )}
              </GridItem>
            </>
          )}
        </Formik>
      </Grid>
    </Main>
  );
}
