import React, { ReactElement } from 'react';

import {
  Container,
  BackgroundProvider,
  IconPadlock,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { AppLayoutContent } from 'client/components/appLayout/AppLayout.config';
import { NavLink } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  StyledHeader,
  StyledInnerContainer,
  StyledSignInContainer,
  StyledBirminghamSolutionsLogo,
  StyledSignInIconContainer,
  StyledSignInText,
} from './HeaderComponent.styled';
import * as routes from '../../routes/manifest';

export default function HeaderComponent({
  logo = 'public/bm-solutions.svg',
}): ReactElement {
  const { header } = useContent<AppLayoutContent>();

  const theme: any = useTheme();

  return (
    <StyledHeader>
      <BackgroundProvider value={{ cssValue: theme.header_color_background }}>
        <Container>
          <StyledInnerContainer>
            <NavLink to={routes.Home} title="logo">
              <StyledBirminghamSolutionsLogo
                src={logo}
                alt={header.accessibleLogoDescription.text}
              />
            </NavLink>
            <StyledSignInContainer>
              <Link href={header.signIn.href} external title={header.signIn.text}>
                <StyledSignInText>
                  <StyledSignInIconContainer>
                    <IconPadlock
                      color="default1"
                      iconSize="lg"
                      size="s1"
                      data-testid="header-sign-in-icon"
                    />
                  </StyledSignInIconContainer>
                  {header.signIn.text}
                </StyledSignInText>
              </Link>
            </StyledSignInContainer>
          </StyledInnerContainer>
        </Container>
      </BackgroundProvider>
    </StyledHeader>
  );
}
