import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const articles: AppContent['articles'] = {
  metaContent: MetaContentValues.Articles,
  pageTitle: 'News and updates',
  backButtonLabel: 'Back',
  backButtonHref: routes.Articles,
  navigationTiles: [
    {
      linkText: `Improving the quality of rental homes benefits landlords and tenants`,
      boxText: `One in five households (19%) live in the private rented sector (PRS), a figure that has stayed...`,
      url: routes.ArticleImprovingQualityBenefitsLandlordsAndTenants,
      linkTestId: 'articles-navigation-rental-homes',
    },
  ],
};

export default articles;
