import { TableCol, Table, Text, Paragraph } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledTableCol: StyledComponent<typeof TableCol, any> = styled<
  typeof TableCol
>(TableCol)`
  background-color: ${({ theme }) => theme.color_background_neutral_2};
`;


export const StyledTable: StyledComponent<typeof Table, any> = styled<
  typeof Table
>(Table)`
  table-layout: fixed;
`;

export const ItalicText: StyledComponent<typeof Text, any> = styled<
  typeof Paragraph
>(Text)`
  &&& {
    font-style: italic;
  }
`;
export const StyledSpan = styled.span`
  text-decoration-line: underline;
`;
