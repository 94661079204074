import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import {
  contentSectionBox,
  InfoPanelContent,
} from 'client/components/infoPanel/InfoPanel.config';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';

import { anchors, anchorLink } from './anchors';

const lending: {
  title: string;
  id: string;
  infoPanel: InfoPanelContent;
} = {
  title: 'Lending',
  id: anchors.lending.id,
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Assignable contracts',
        sectionID: anchors.lending.assignableContracts,
        isAccordion: true,
        sectionBody: [
          {
            text: 'Assignable contracts allow the original buyer to sell the property before legal completion, by assigning the contract to buy the property to a new buyer after contracts have been exchanged with the developer.',
          },
          {
            text: "We can't accept applications involving assignable contracts or irrevocable powers of attorney in favour of intervening sellers. Any similar transaction structure must be reported to us.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.newBuild),
              displayText: 'New build',
            },
          },
        ],
      },
      {
        sectionHeading: 'Bankruptcies',
        sectionID: anchors.lending.bankruptcies,
        isAccordion: true,
        sectionBody: [
          {
            text: "You must answer 'Yes' to the credit history question asking if any customer has had a bankruptcy / IVA / debt management arrangement or debt relief order which was:",
          },
          {
            list: [
              'Registered within the last six years (whether now completed or not).',
            ],
          },
          {
            text: 'Or',
          },
          {
            list: ['Is still outstanding (whenever this was registered).'],
          },
          {
            text: "If a bankruptcy / IVA / debt management arrangement or debt relief order was registered over six years ago and is no longer outstanding, the question can be answered as 'No'.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.countyCourtJudgementsCcJs),
              displayText: 'County Court Judgements (CCJs)',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.mortgageArrears),
              displayText: 'Mortgage arrears',
            },
          },
        ],
      },
      {
        sectionHeading: 'Business/consumer buy to let',
        sectionID: anchors.lending.businessConsumerBuyToLet,
        isAccordion: true,
        sectionBody: [
          {
            text: 'We offer business buy to lets (BBTL) and consumer buy to lets (CBTL).',
          },

          {
            text: 'A BBTL is a mortgage used to buy or remortgage a property to let to a non-family member or where the majority of the borrowing is for BTL purposes.',
          },
          {
            text: "A CBTL is where the property wasn't originally purchased for letting, and the customer doesn't let out any other properties. The customer or a family member might have previously lived in the property, for example.",
          },
          {
            text: 'And/or',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'The majority of the borrowing is for non-business purposes.',
            ],
          },
          {
            text: "BBTL mortgages are not regulated and a CBTL mortgage is regulated by the HM Treasury. You'll need Consumer FCA permissions before submitting any CBTL applications.",
          },
          {
            text: 'Customer answers will establish whether the loan is classified as a BBTL or CBTL.',
          },
          {
            text: 'Loan purpose is vital in deciding whether lending will be unregulated BBTL or regulated CBTL. When applying for a remortgage with additional borrowing or a further advance, the loan purposes listed will be available online:',
          },
          { boldText: 'BBTL loan purpose' },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'Buy BTL property freehold.',
              'Buy land adjacent to BTL property.',
              'Buy extension of lease to BTL.',
              'Buy final share being mortgaged.',
              'BTL repairs.',
              'BTL loan/BTL deposit.',
              'Repay existing BTL loan.',
            ],
          },
          {
            boldText: 'Consumer Goods/Funds',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            list: [
              'Non BTL debt consolidation.',
              'Non BTL home improvements.',
              'Non BTL (residential/second home) loan.',
              'Repay subsequent charge - clearing bank.',
              'Repay subsequent charge - non-clearing bank.',
            ],
          },
          {
            text: 'This list might not be available for every application. Availability of each loan purpose will depend on the circumstances of an individual application.',
          },
        ],
      },
      {
        sectionHeading: 'Concessionary (discounted) purchase',
        sectionID: anchors.lending.concessionaryDiscountedPurchase,
        isAccordion: true,
        sectionBody: [
          {
            text: "We don't accept concessionary purchase applications where the property is being purchased below full market value.",
          },
          {
            text: (
              <>
                The only exception to this is the purchase of a new build
                property if the value has increased between agreement to
                purchase and building completion. Builder incentives are
                acceptable, subject to our existing criteria.
              </>
            ),
          },
          {
            text: "It's acceptable to purchase a BTL property from a family member, as long as the deposit is sourced from the applicant's own resources and the property is purchased at full market value. We can't accept gifted deposits, even if they're from a different family member to the customer.",
          },
          {
            text: "BTL properties can't be let to a family member.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.builderCashIncentives),
              displayText: 'Builder cash incentives',
            },
          },
        ],
      },
      {
        sectionHeading: 'County Court Judgements (CCJs)',
        sectionID: anchors.lending.countyCourtJudgementsCcJs,
        isAccordion: true,
        sectionBody: [
          {
            text: "Please answer 'Yes' to the credit history question if any customer has had a County Court Judgement (CCJ) or default registered against them, or their business if they're self-employed, within the last six years.",
          },
          {
            text: (
              <>
                CCJs are generally considered in credit scoring. Background
                details will be need for the application.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.mortgageArrears),
              displayText: 'Mortgage arrears',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.bankruptcies),
              displayText: 'Bankruptcies',
            },
          },
        ],
      },
      {
        sectionHeading: 'Credit score decline appeals',
        sectionID: anchors.lending.creditScoreDeclineAppeals,
        isAccordion: true,
        sectionBody: [
          {
            text: "All applications will be credit scored. Customers have the right to appeal if an application is declined because it doesn't pass our credit scoring.",
          },
          {
            text: 'Here are the scenarios when an appeal might be successful:',
          },
          {
            boldText: 'Porting application with no additional borrowing',
          },
          {
            boldText: 'BFPO Address',
          },
          {
            text: 'The client is in the British Armed Forces and living at a BFPO address.',
          },
          {
            boldText: 'Victim of fraud',
          },
          {
            text: "The fraud's been reported to the police and Credit Industry Fraud Avoidance system (CIFAS) and has its own a crime reference number.",
          },
          {
            boldText: 'Adverse credit data registered in error',
          },
          { boldText: 'An error by Lloyds Banking Group can be proven' },
          {
            text: "If the adverse information has been registered on a non-Lloyds Banking Group product, an appeal can't be submitted. The customer should arrange for their credit file to be corrected before a new application is submitted.",
          },
          {
            text: "For any scenario outside of these, it's unlikely we'll change our decision.",
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: '/public/credit_scoring.pdf',
              displayText: 'Read our credit scoring guide',
              external: true,
            },
          },
        ],
      },
      {
        sectionHeading: 'Dependants',
        sectionID: anchors.lending.dependants,
        isAccordion: true,
        sectionBody: [
          {
            text: "A dependant is anyone who relies on your customer financially. They won't be included on the mortgage or contribute to the monthly payments.",
          },
          {
            text: (
              <>Child and adult dependants need to be captured separately.</>
            ),
          },
          {
            text: <Strong>Child dependants</Strong>,
          },
          {
            text: "These are any children under 18 who the customers are the primary carer for. You should also include any children they're expecting or in the process of adopting. Foster children or any children who have an income they can fully support themselves with don't need to be included.",
          },
          {
            text: "Child maintenance must be keyed as an outgoing commitment, but the child doesn't need to be included as a dependant.",
          },
          {
            text: <Strong>Adult dependant</Strong>,
          },
          {
            text: (
              <>
                An adult dependant is someone not included in the mortgage but
                is financially reliant on the customer. This could be a
                spouse/partner, elderly relative, or any grown-up children in
                full-time education or unemployed.
              </>
            ),
          },
          {
            text: "If the adult dependant doesn't live at the property all of the time, still key this in the adult dependant section.",
          },
          {
            text: "If the adult dependant doesn't ever live at the property, they must not be keyed as a dependant, but the related costs must be keyed as a total figure in 'Other commitments'.",
          },
        ],
      },
      {
        sectionHeading: 'Deposits',
        sectionID: anchors.lending.deposits,
        isAccordion: true,
        sectionBody: [
          {
            text: 'The source of all customer deposits must be captured, and proof must be retained for all buy to let applications, other than remortgages.',
          },
          {
            text: "The deposit should come from the customers' own resources. A builder's deposit of up to 5% of the purchase price is allowable on new build property applications if the LTV is 75% or less. The valuer must be aware of any incentive and make a note of it in the valuation report.",
          },
          {
            text: "For certain applications, you'll need to verify the deposit. Evidence will also be needed if the application needs an underwriter review, and/or a message is displayed at DIP submission and the additional requirements section of the Submit application results screens at full application.",
          },
          {
            text: (
              <Strong>{`We can't accept the following as deposit sources:`}</Strong>
            ),
          },
          {
            list: [
              "Any deposit coming from a business current account, business loan, or directors' loan account.",
              ' Vendor gifted deposits.',
              ' Personal loans.',
              ' Second charges.',
              ' The Forces Help to Buy scheme (Long Service Advance of Pay) for military personnel.',
            ],
          },

          {
            text: (
              <Strong>
                We can accept the following as deposit sources, as long as they
                can be verified
              </Strong>
            ),
          },
          {
            text: <Strong id="lending-deposits-equity">Equity</Strong>,
          },
          {
            list: [
              'The credit file should confirm a mortgage already exists.',
              'If no mortgage can be confirmed on the credit file, request a copy of the most recent mortgage statement. This must be dated within the last 12 months.',
              "We'll also request the sales details for the property to verify its value.",
            ],
          },
          {
            text: "If the customer is remortgaging or taking a further advance on an existing property with us, and the funds will be used as a deposit, we'll need to view the funds in an account or an offer guaranteeing the lending.",
          },
          {
            text: <Strong>Gift</Strong>,
          },
          {
            text: "Gifted deposits are only acceptable from family. They must be non-repayable. The property can't be let out to, or purchased from, a family member. Evidence of the source of deposit must be kept on record in all cases.",
          },
          {
            text: "Purchases between family members are acceptable, as long as the property's sold at market value and the buyer is a UK resident. Deposits for these types of sale can't be gifted.",
          },
          {
            text: (
              <Strong>
                {`You'll need to provide a signed letter written by the donor
                within three months of the mortgage application`}
              </Strong>
            ),
          },
          {
            text: <Strong>It must include:</Strong>,
          },
          {
            list: [
              'BM Solutions/Birmingham Midshires address.',
              'The address of the property being bought.',
              "All customers' names.",
              "The donor's full name and address, and relationship to the customer.",
            ],
          },
          {
            text: <Strong>It must confirm:</Strong>,
          },
          {
            list: [
              'The deposit amount.',
              "That the donor understands the deposit is non-repayable and they'll hold no interest in the property after mortgage completion.",
            ],
          },
          {
            text: "You must also provide the latest UK bank statement or passbook showing evidence of the deposit funds in the donor's or customers' UK bank account. Alternatively, a letter from the donor's or customers' UK bank confirming the funds are available can be provided.",
          },
          {
            text: (
              <>
                Additional statements or other documents can be requested if
                needed.{' '}
              </>
            ),
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: '/public/gifted_deposit_letter_template.pdf',
              displayText: 'View our gifted deposit letter template',
              external: true,
            },
          },
          {
            text: (
              <>
                A family member is someone who is related to at least one of the
                customers:
              </>
            ),
          },
          {
            list: [
              'by birth/blood relative',
              'by marriage or civil partnership, including step-children, adopted children and in-laws',
              "as common law' partners or co-habitants.",
            ],
          },
          {
            container: contentSectionBox({
              marginBottom: '05',
              marginTop: '05',
            }),
            basicTable: {
              width: 'auto',
              responsive: true,
              ariaLabel: 'A table of acceptable and unacceptable deposits',
              testIdPrefix: 'Deposits-table-test-id',

              columns: [
                { displayText: 'Acceptable', key: 'Acceptable' },
                { displayText: 'Not Acceptable', key: 'Not-Acceptable' },
              ],
              rows: [
                {
                  Acceptable: 'Parents, step parent, parents in laws',
                  'Not-Acceptable': 'Family Friend',
                },
                {
                  Acceptable:
                    'Brothers and sisters, half brothers and sisters, step brothers and sisters, brother and sister in laws',
                  'Not-Acceptable': 'Employer',
                },
                {
                  Acceptable: 'Grandparents, step grandparents',
                  'Not-Acceptable': 'Developer/ Landlord',
                },
                {
                  Acceptable: (
                    <>
                      Auntie/Uncle (related by blood) <br />
                      Niece/ Nephew
                    </>
                  ),
                  'Not-Acceptable': 'Auntie/Uncle (related via marriage)',
                },
                {
                  Acceptable:
                    'Partners living with applicant either common law or co-habitee',
                  'Not-Acceptable': 'Cousins',
                },
                {
                  Acceptable:
                    "Applicants' children, step children, son and daughter in laws and adopted children",
                  'Not-Acceptable': 'Foster/Guardian children',
                },
              ],
            },
          },
          {
            boldText: 'Inheritance',
          },
          {
            text: 'We may request up-to-date UK bank statements or a passbook showing evidence of the inheritance, if needed.',
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink('lending-deposits-uk-savings'),
              displayText: 'View Savings for more details',
            },
          },
          {
            text: <Strong>Previous property sale</Strong>,
          },
          {
            textWithLinks: [
              'Please use the guidance in the ',
              {
                displayText: 'Equity',
                external: false,
                href: anchorLink('lending-deposits-equity'),
              },
              ' or ',
              {
                displayText: 'Savings',
                external: false,
                href: anchorLink('lending-deposits-uk-savings'),
              },
              ' sections.',
            ],
          },
          {
            text: <Strong id="lending-deposits-uk-savings">UK savings</Strong>,
          },
          {
            text: "If requested, you'll need to provide current account, ISAs, or savings account statements as a minimum. You must show at least three months' statements or latest annual statement with the customers' names, sort code, account number and provider name clearly visible.",
          },
          {
            text: "The latest statement must show a closing balance covering the deposit required. If there are any recent large or unusual deposits into the account, you'll need to confirm where they came from. This might mean obtaining further documents ",
          },
          {
            text: "If funds are from a business account, you must obtain confirmation from the company accountant that the withdrawal won't have a negative impact on the business.",
          },
          {
            text: <Strong>Shares</Strong>,
          },
          {
            text: <>Please obtain:</>,
          },
          {
            list: [
              "a certified copy of Share Certificate showing customers' name and address",
              'evidence of current share value calculation showing the value of shares.',
            ],
          },
          {
            text: <Strong>Premium bonds</Strong>,
          },
          {
            text: <Strong>{`You'll need to provide:`}</Strong>,
          },
          {
            list: [
              'Premium bond certificate showing the name of the customer and the value of premium bonds held.',
              "Additional documents to verify the customer's name and address is the same as customer named on the certificate.",
            ],
          },
          {
            text: <Strong>Passbooks</Strong>,
          },
          {
            list: [
              "should show customers' names and account numbers",
              'should be in the same name as at least one of the customers named on the new mortgage',
              "should show at least the latest three months' transactions and the closing balance must cover the deposit required.",
            ],
          },
          {
            text: "If there are any recent large or unusual deposits into the account, you'll need to confirm where they came from. This might mean obtaining further documents.",
          },
          {
            text: <Strong>Additional information: </Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.builderCashIncentives),
              displayText: 'Builder cash incentives',
            },
          },
        ],
      },
      {
        sectionHeading: 'First-time buyer/first-time landlord',
        sectionID: anchors.lending.firstTimeBuyerFirstTimeLandlord,
        isAccordion: true,
        sectionBody: [
          {
            text: <Strong>Sole applications</Strong>,
          },
          {
            text: (
              <>
                The applicant must own a property in the UK in their own name.
                This will need to be validated before an offer can be made.
              </>
            ),
          },
          {
            text: <Strong>More than one applicant</Strong>,
          },
          {
            text: (
              <>
                At least one applicant must own a property in the UK in their
                own name. This will need to be validated before an offer can be
                made. All other named applicants can be first time buyers.
              </>
            ),
          },
          {
            text: "In most cases, we'll validate property ownership with a credit search on the applicant. If we can't do this or the property is unencumbered, we might ask for more evidence.",
          },
          {
            text: <Strong>Acceptable documents:</Strong>,
          },
          {
            text: <Strong>England and Wales</Strong>,
          },
          {
            list: [
              "Land Registry certificate dated within the last three months, which must show at least one of the applicants' names.",
              "A copy of a recent mortgage statement dated within the last three months, which must show at least one of the applicants' names.",
              " A copy of a mortgage statement over three months old plus last three months bank statements confirming payment. Both must show at least one of the applicants' names.",
            ],
          },
          {
            text: <Strong>Scotland and Northern Ireland</Strong>,
          },
          {
            list: [
              "Land Registry certificate dated within the last three months. This must show at least one of the applicants' names. If no date is on the certificate, proof this was requested within the last three months is acceptable from the Land Registry or a solicitor.",
              "A copy of a recent mortgage statement dated within the last three months, which must show at least one of the applicants' names.",
              "A copy of a mortgage statement over three months old plus last three months bank statements confirming payment. Both must show at least one of the applicants' names.",
            ],
          },
        ],
      },
      {
        sectionHeading: 'Letting criteria',
        sectionID: anchors.lending.lettingCriteria,
        isAccordion: true,
        sectionBody: [
          {
            text: <Strong>The property can be let on:</Strong>,
          },
          {
            list: [
              'a single assured shorthold tenancy in England',
              'an occupation contract in Wales,',
              'a private residential tenancy in Scotland',
              'a private tenancy in Northern Ireland under the provisions of the Private Tenancies (Northern Ireland) Order 2006.',
            ],
          },
          {
            text: (
              <>
                The minimum tenancy term is six months. The maximum tenancy term
                is normally 36 months. A maximum tenancy of five years is
                acceptable under a Dept for Levelling Up, Housing and
                Communities (DLUHC) model agreement of September 2014 (or as
                amended) or the Scottish Equivalent.
              </>
            ),
          },
          {
            text: "A BTL remortgage application can't be started without a tenant in place. A tenancy agreement must also be in place. If a tenancy is about to start, the applicant must have a tenancy agreement dated within 14 days of the application confirming the expected rental income.",
          },
          {
            text: "The property can be let to a company on a corporate let. The maximum term for this is 12 months. Our consent isn't needed to renew the tenancy. But the occupiers need to be named on the tenancy and must be employees of the tenant company.",
          },
          {
            text: "The maximum number of unrelated tenants is five. They must all be named on the agreement. It's the landlords' responsibility to make sure all legislation is complied with, including (but not restricted to) legislation relating to Tenancy Deposit schemes and HMOs (Houses in Multiple Occupation).",
          },
          {
            text: (
              <>
                For larger properties, where there is a potential of more than
                five lettable rooms, the valuer must be satisfied the property
                and local market reflects single family letting arrangements.
              </>
            ),
          },
          {
            text: "Properties subject to Local Authority Homes in Multiple Occupation (HMO) licensing might be acceptable, subject to the valuer's assessment of suitability based on our requirements.",
          },
          {
            text: (
              <Strong>
                {`We can't accept tenants in the following categories:`}
              </Strong>
            ),
          },
          {
            list: [
              'Asylum seekers.',
              'Persons who have or might acquire diplomatic immunity.',
              'Social landlords, such as housing associations.',
              'Holiday lets.',
              'Rent act protected tenants.',
              'Agents of any of the above.',
              'Family members.',
            ],
          },
          {
            text: (
              <>
                The tenancy agreement must provide that assignment or
                sub-lettings to tenants in these categories are not allowed.
              </>
            ),
          },
          {
            list: [
              "Multiple tenancies, where each tenant signs a separate agreement, aren't acceptable.",
              'If the property is in Scotland, the landlord must register with the relevant local authority.',
              "Leaseback and sale properties aren't acceptable.",
              "Limited company purchases or Trust Deed applications aren't acceptable.",
              "The property can't have any occupancy restrictions.",
              "Applications won't be accepted where a second charge is being completed at the same time as the BTL application.",
            ],
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.housesInMultipleOccupation),
              displayText: 'Houses in multiple occupation',
            },
          },
        ],
      },
      {
        sectionHeading: 'Lending limits',
        sectionID: anchors.lending.lendingLimits,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                A <Strong>£5,000,000 lending limit</Strong> applies to all Buy
                To Let, Let To Buy, consent to let, second home loan, and House
                2 House borrowing across the Retail division of Lloyds Banking
                Group.
              </>
            ),
          },
          {
            text: 'The maximum loan available on individual properties is',
          },
          {
            list: [
              '65% LTV between £1,000,000 and £2,000,000',
              '75% LTV up to £1,000,000',
              '80% LTV available for lending up to £350,000, including all fees, on EPC rated properties A-C.',
            ],
          },
          {
            text: 'This includes New Build properties and there is no variation in LTV limits for flats.',
          },
          {
            text: 'A maximum of 10 properties applies to all let to buy, buy to let, second home loan, consent to let, and House 2 House lending across the Retail division of Lloyds Banking Group. This consists of Bank of Scotland, Birmingham Midshires, TMB, Halifax, Intelligent Finance, Lloyds Bank, C&G, and Scottish Widows.',
          },
          {
            text: 'Where more than 10 properties are held, no additional borrowing is allowed.',
          },
          {
            text: (
              <>
                The minimum loan amount is <Strong>£25,001.</Strong> For further
                advances, the minimum loan amount is <Strong>£5,000.</Strong>
              </>
            ),
          },
          {
            text: (
              <>
                Certain buy to let deals have a lower max LTV. Where a deal with
                a lower LTV is combined with a deal with a higher LTV, the lower
                LTV will apply to the whole loan.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.minMaxTerm),
              displayText: 'Min/max term',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.minMaxAge),
              displayText: 'Min/max age',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.portfolioLandlords),
              displayText: 'Portfolio landlords',
            },
          },
        ],
      },
      {
        sectionHeading: 'Min/max age',
        sectionID: anchors.lending.minMaxAge,
        isAccordion: true,
        sectionBody: [
          {
            text: <>The minimum customer age is 21.</>,
          },
          {
            text: (
              <>
                The eldest customer must submit the application before their
                75th birthday. They must be no older than age 80 at the end of
                the mortgage term.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lendingLimits),
              displayText: 'Lending limits',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.minMaxTerm),
              displayText: 'Min/max term',
            },
          },
        ],
      },
      {
        sectionHeading: 'Min/max term',
        sectionID: anchors.lending.minMaxTerm,
        isAccordion: true,
        sectionBody: [
          {
            text: "The maximum term is 40 years. The term must not exceed the eldest applicant's 80th birthday.",
          },
          {
            text: (
              <>
                The minimum term must not be less than the selected product
                term.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.minMaxAge),
              displayText: 'Min/max age',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lendingLimits),
              displayText: 'Lending limits',
            },
          },
        ],
      },
      {
        sectionHeading: 'Mortgage arrears',
        sectionID: anchors.lending.mortgageArrears,
        isAccordion: true,
        sectionBody: [
          {
            text: "Please answer 'Yes' to the credit history question if any customer has been in arrears in the last six years with any mortgage borrowing or ever had a property repossessed. Applications where previous mortgages have been in arrears will be considered on an individual basis.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.countyCourtJudgementsCcJs),
              displayText: 'County Court Judgements (CCJs)',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.bankruptcies),
              displayText: 'Bankruptcies',
            },
          },
        ],
      },
      {
        sectionHeading: 'Mortgage-free/unencumbered properties',
        sectionID: anchors.lending.mortgageFreeUnencumberedProperties,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                Loans on mortgage-free properties are treated like remortgages.
                The applicant is eligible for the remortgage deal and
                incentives, such as free property assessment and legal service.
              </>
            ),
          },
          {
            text: (
              <Strong>
                Please key mortgage-free/unencumbered property applications as
                follows:
              </Strong>
            ),
          },
          {
            list: [
              "Select 'Remortgage' or 'Remortgage - own conveyancer' as appropriate in the dropdown list for application type.",
              "Answer 'Yes' for all applicants to 'Is the property to be mortgaged, mortgage free?'.",
            ],
          },
          {
            text: "The property must have been registered in at least one of the applicants' names for a minimum of six months.",
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.remortgageAndCapitalRaising),
              displayText: 'Remortgage and capital raising',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.application.freeConveyancing),
              displayText: 'Free conveyancing',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.property.inheritedProperties),
              displayText: 'Inherited properties',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.existingCustomer.portability),
              displayText: 'Portability',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.businessConsumerBuyToLet),
              displayText: 'Business/consumer BTL',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lettingCriteria),
              displayText: 'Letting criteria',
            },
          },
        ],
      },
      {
        sectionHeading: 'Notional stress rates',
        sectionID: anchors.lending.notionalStressRates,
        isAccordion: true,
        sectionBody: [
          {
            text: 'Lending amounts are either assessed using actual or anticipated rental income from the property only using stress rates and rental cover ratios or top slicing where we will consider personal income alongside rental income.',
          },

          {
            boldText: 'Additional information:',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            link: {
              href: anchorLink(anchors.lending.creditScoreDeclineAppeals),
              displayText: 'Credit score decline appeals',
            },
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            link: {
              href: anchorLink(anchors.income.rentalIncome),
              displayText: 'Rental income',
            },
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            link: {
              href: anchorLink(anchors.income.taxOnRentalIncome),
              displayText: 'Tax on rental income',
            },
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            link: {
              href: anchorLink(anchors.topSlicing.minimumTopSlicingCriteria),
              displayText: 'Top slicing',
            },
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            link: {
              href: routes.Calculators,
              displayText: 'Calculators',
            },
          },
        ],
      },
      {
        sectionHeading: 'Portfolio landlords',
        sectionID: anchors.lending.portfolioLandlords,
        isAccordion: true,
        sectionBody: [
          {
            text: "A portfolio landlord application is where the applicants' have four or more mortgaged BTL properties, including the new transaction. This is based on the total number held by everyone named on the application.",
          },

          {
            text: <Strong>{`Here's an example of how it works`}</Strong>,
          },
          {
            text: (
              <>
                Two applicants are buying a property together. Applicant 1
                already has two mortgaged BTL properties, and applicant 2 has
                one mortgaged BTL property.
              </>
            ),
          },
          {
            text: "In total, they'll have four properties between them, including the one they're buying together, so the application is classified as a portfolio landlord.",
          },
          {
            text: (
              <>
                Mortgaged BTL properties include those held in a personal name,
                limited company name, or any other legal entity.
              </>
            ),
          },
          {
            subheading: {
              displayText: 'Our process',
              headingLevel: 'h4',
            },
          },
          
          {
            list: [
              'Submit all applications online at BM Solutions.',
              'A question will be asked to find out the number of mortgaged BTLs on the application.',
              '3 or less, including the new application, will continue as current process.',
              '4-10, including the new application, will need proof of income and supporting documents to be uploaded.',
              "If mortgaged properties within the portfolio are held in a limited company, we'll need the latest mortgage statement or mortgage offer, if completed in the last 12 months.",
              'Full information about the portfolio must be keyed.',
            ],
          },
          {
            text: <Strong>Information needed includes:</Strong>,
          },
          {
            list: [
              'Addresses.',
              'Year built and year of purchase.',
              'Original purchase price.',
              'Gross monthly rent.',
              'Estimated property valuation.',
              'Whether property is leasehold, managed, and mortgaged.',
            ],
          },
          {
            boldText:
              'Your application will not progress until all of the above has been fully completed - including completion of the portfolio details screen in the full application.',
          },
          {
            text: <Strong>Portfolio landlords criteria</Strong>,
          },
          {
            list: [
              'No more than 10 BTL mortgaged properties, including the new application.',
              'Minimum income of £30,000 each application from employment, self-employment, property portfolio profit, or pension/investment income.',
              'Maximum aggregate portfolio* of 75% LTV.',
              'Minimum aggregate portfolio rental cover ratio of 145% stressed at 5.5%.',
              'Property must meet standard criteria.',
              <>
                Please check the maximum amount the customer can borrow{' '}
                <StyledLink
                  external
                  to="https://www2.bmsolutionsonline.co.uk/tools/rental-income-calculator"
                >
                  here
                </StyledLink>
              </>,
            ],
          },
          {
            text: (
              <>
                * Based on the entire portfolio, including acquiring property
                and any unencumbered let property.
              </>
            ),
          },
          {
            text: <Strong>Unencumbered let properties</Strong>,
          },
          {
            text: "These aren't included in the total mortgaged BTL properties. But the rent and property value must be declared in the property schedule and portfolio details sections of the application.",
          },
          {
            subheading: {
              displayText: 'Finance costs',
              headingLevel: 'h4',
              size: 's2',
            },
          },
          {
            text: 'Since 06 April 2020, tax relief on finance costs for landlords of residential properties is restricted to basic rate of income tax, regardless of which tax band the landlord falls into. Accordingly, profit from land and property as it appears on a tax calculation no longer has finance costs deducted.',
          },
          {
            text: "You'll need to input the latest year's finance costs on the application.",
          },
          {
            text: <Strong>Portfolio landlord help and support</Strong>,
          },
          {
            text: "If you need any help with an application, get in touch with our specialist team. You'll get your own dedicated case handler, who will contact you once you submit your application.",
          },
          {
            text: (
              <>
                <Strong>Call: </Strong>
                <StyledLink to="tel:03456020914">0345 6020914</StyledLink>
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lendingLimits),
              displayText: 'Lending limits',
            },
          },
        ],
      },
      {
        sectionHeading: 'Remortgage and capital raising',
        sectionID: anchors.lending.remortgageAndCapitalRaising,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                For remortgage applications, including unencumbered properties,
                a customer must have owned the property for a minimum of six
                months.
              </>
            ),
          },

          {
            text: (
              <Strong>
                {`The following reasons for capital raising aren't acceptable:`}
              </Strong>
            ),
          },
          {
            list: [
              'Gambling.',
              'Capital injection into a business.',
              'Business start-up.',
              'Timeshare purchase.',
            ],
          },
          {
            text: "We'll allow capital raising for a deposit for a BTL purchase in personal or limited company name.",
          },
          {
            text: (
              <>
                Underwriters might ask for evidence of how funds will be used if
                they have any concerns.
              </>
            ),
          },
          {
            text: <Strong>Additional information:</Strong>,
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(
                anchors.lending.mortgageFreeUnencumberedProperties,
              ),
              displayText: 'Mortgage free properties (unencumbered properties)',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.application.freeConveyancing),
              displayText: 'Free conveyancing',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.existingCustomer.portability),
              displayText: 'Portability',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.businessConsumerBuyToLet),
              displayText: 'Business/consumer BTL',
            },
          },
          {
            container: ({ children }) => <Paragraph>{children}</Paragraph>,
            link: {
              href: anchorLink(anchors.lending.lettingCriteria),
              displayText: 'Letting criteria',
            },
          },
        ],
      },
      {
        sectionHeading: 'Repayment methods',
        sectionID: anchors.lending.repaymentMethods,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>
                Repayment, interest only, or a combination of both are
                acceptable. You must tell us interest only elements will be
                repaid.
              </>
            ),
          },
        ],
      },
    ],
  },
};

export default lending;
