import React from 'react';

import { Paragraph, Text } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const documentUpload: AppContent['documentUpload'] = {
  metaContent: MetaContentValues.DocumentUpload,
  pageTitle: 'Document upload',
  backButtonLabel: 'Back',
  backButtonHref: routes.PlacingBusiness,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        sectionBody: [
          {
            text: "When submitting a buy to let, let to buy, or further advance application, select the 'Upload documents' tab in the Summary section of the application.",
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: 'Applications must be fully submitted - including portfolio landlord information if required - and all fees paid before the document upload service becomes visible.',
          },
          { subheading: 'How to upload documents' },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  1.{' '}
                </Text>
                Select the &apos;Upload documents&apos; button to take you to
                the Upload document page.
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  2.{' '}
                </Text>
                Select the blue text link within the grey box to browse for your
                files.
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  3.{' '}
                </Text>
                Find the file you want to upload. Select &apos;Open&apos;.
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  4.{' '}
                </Text>
                Use the appropriate document type from the drop-down box for
                your document.
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  5.{' '}
                </Text>
                Select &apos;Upload&apos;. Repeat these steps for multiple
                documents if needed.{' '}
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  6.{' '}
                </Text>
                Once you&apos;ve uploaded all your documents, select
                &apos;Submit documents&apos;. You can submit batches of 10 at a
                time.
              </Paragraph>
            ),
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            component: (
              <Paragraph>
                <Text weight="bold" marginRight="02">
                  7.{' '}
                </Text>
                To save and resume later, select cancel when &apos;Are you sure
                you want to submit the document(s)?&apos; appears on screen.
              </Paragraph>
            ),
          },
          { subheading: "Once you've uploaded your documents" },
          {
            text: "A member of our Broker Support Team will contact you once your documents have been processed. You don't need to do anything.",
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: "If you need to upload more documents to an existing application, go to the submitted application on the home page. Then select the 'Upload documents' tab and follow the instructions in the previous section.",
          },
          { subheading: 'Helpful hints' },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            listItemContainer: contentSectionBox({ marginBottom: '01' }),
            list: [
              'If an error appears on screen before you try to upload documents, select the refresh button on your webpage until it clears.',
              'Attach  documents by customer and document type to one file. Payslips for customer 1 to one file, payslips for customer 2 to a second file, for example.',
              "Files  shouldn't be password protected or have names that include special characters.",
              'Up to 60 files can be submitted for each case.',
            ],
          },
          {
            text: "If you have issues uploading documents, open an individual document and select 'Print', followed by 'Print to PDF'. You can then press 'Print' again at the bottom of the screen and open 'File explorer'. Save your document under a new name and follow the instructions again to upload it.",
          },
          {
            textWithLinks: [
              'If you have a technical issue, please call us on',
              {
                external: true,
                displayText: ' 0345 124 1388',
                href: 'tel:03451241388',
              },
              '.',
            ],
          },
        ],
      },
    ],
  },
};

export default documentUpload;
