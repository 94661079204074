import React from 'react';

import { Paragraph } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const links = {
  englandAndWales: {
    href: 'https://www.gov.uk/improve-energy-efficiency',
    label: 'Find Ways to Save Energy in Your Home for England and Wales',
  },
  homeEnergyScotland: {
    href: 'https://www.homeenergyscotland.org',
    label: 'Scotland',
  },
  affordableWarmthNorthernIreland: {
    href: 'https://www.nihe.gov.uk/housing-help/affordable-warmth/affordable-warmth-scheme',
    label: 'Northern Ireland',
  },
  scotlandWarmerHomes: {
    href: 'https://www.mygov.scot/energy-saving-funding/warmer-homes-scotland-scheme',
    label: 'Warmer Homes Scotland',
  },
  scotlandPrivateLandlords: {
    href: 'https://www.homeenergyscotland.org/support-private-sector-landlords',
    label: 'Private Rented Sector Landlord Loan schemes',
  },

  northernIrelandAffordableWarmth: {
    href: 'https://www.nihe.gov.uk/housing-help/affordable-warmth/affordable-warmth-scheme',
    label: "Housing Executive's Affordable Warmth Scheme",
  },
  welshNest: {
    href: 'https://www.gov.wales/get-help-energy-efficiency-your-home-nest',
    label: "Welsh Government's Nest Scheme",
  },
  energyCompanyScheme: {
    href: 'https://www.gov.uk/energy-company-obligation',
    label: 'Energy Company Obligation Scheme',
  },
  greatBritishInsulationScheme: {
    href: 'https://www.gov.uk/apply-great-british-insulation-scheme',
    label: 'Great British Insulation Scheme',
  },
  boilerUpgradeScheme: {
    href: 'https://www.gov.uk/apply-boiler-upgrade-scheme',
    label: 'Boiler Upgrade Scheme (BUS)',
  },
  smartExportScheme: {
    href: 'https://which.co.uk/reviews/solar-panels/article/smart-export-guarantee-explained-at2wh8b519s2',
    label: 'Smart Export Guarantee (SEG)',
  },
};

const grantsAndSchemes: AppContent['grantsAndSchemes'] = {
  metaContent: MetaContentValues.GrantsAndSchemes,
  pageTitle: 'Grants and schemes',
  backButtonLabel: 'Back',
  backButtonHref: routes.SustainabilityHub,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'grants-and-schemes',
        isAccordion: false,
        headingLevel: 'h2',
        sectionBody: [
          {
            text: "Here are some of the current schemes available that could help your customers or their tenants. Many energy companies also offer their own schemes. These can change or expire, so always check what's available before doing anything.",
          },
          {
            list: [
              <Paragraph>
                The {` Government's `}{' '}
                <StyledLink external to={links.englandAndWales.href}>
                  {links.englandAndWales.label}
                </StyledLink>{' '}
                offers recommendations for energy-efficient home improvements.{' '}
                <StyledLink external to={links.homeEnergyScotland.href}>
                  {links.homeEnergyScotland.label}
                </StyledLink>{' '}
                and{' '}
                <StyledLink
                  external
                  to={links.affordableWarmthNorthernIreland.href}
                >
                  {links.affordableWarmthNorthernIreland.label}
                </StyledLink>{' '}
                also have similar schemes.
              </Paragraph>,
              <Paragraph>
                Scotland also has{' '}
                <StyledLink external to={links.scotlandWarmerHomes.href}>
                  {links.scotlandWarmerHomes.label}
                </StyledLink>{' '}
                and the{' '}
                <StyledLink external to={links.scotlandPrivateLandlords.href}>
                  {links.scotlandPrivateLandlords.label}
                </StyledLink>
                .
              </Paragraph>,

              <Paragraph>
                In Northern Ireland, check out the{' '}
                <StyledLink
                  external
                  to={links.northernIrelandAffordableWarmth.href}
                >
                  {links.northernIrelandAffordableWarmth.label}
                </StyledLink>{' '}
                for low-income households.
              </Paragraph>,
              <Paragraph>
                The{' '}
                <StyledLink external to={links.welshNest.href}>
                  {links.welshNest.label}
                </StyledLink>{' '}
                provides free, impartial energy advice.
              </Paragraph>,
              <Paragraph>
                The{' '}
                <StyledLink external to={links.energyCompanyScheme.href}>
                  {links.energyCompanyScheme.label}
                </StyledLink>{' '}
                could help your tenants with certain energy-saving home
                improvements.
              </Paragraph>,
              <Paragraph>
                The{' '}
                <StyledLink
                  external
                  to={links.greatBritishInsulationScheme.href}
                >
                  {links.greatBritishInsulationScheme.label}
                </StyledLink>{' '}
                provides free or cheaper insulation for properties in England,
                Scotland, and Wales.
              </Paragraph>,
              <Paragraph>
                With the{' '}
                <StyledLink external to={links.boilerUpgradeScheme.href}>
                  {links.boilerUpgradeScheme.label}
                </StyledLink>
                , you can apply for a grant to help with installing air or
                ground source heat pumps, or a biomass boiler in your property.
              </Paragraph>,
              <Paragraph>
                The{' '}
                <StyledLink external to={links.smartExportScheme.href}>
                  {links.smartExportScheme.label}
                </StyledLink>{' '}
                is a government-backed scheme that means you can get paid for
                renewable electricity {` you've `} generated and not used.
              </Paragraph>,
            ],
          },
        ],
      },
    ],
  },
};

export default grantsAndSchemes;
