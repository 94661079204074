// We've given these anchors to an external team who manages the Our Criteria Search functionality changing them will break their links.
// We should not rename these unless we really have to - we can only add new anchors
export const anchors = {
  jumpToASection: {
    id: 'jump-to-a-section',
  },
  application: {
    id: 'application',
    applicants: 'application-applicants',
    decisionInPrinciple: 'application-decision-in-principle',
    directDebitDetails: 'application-direct-debit-details',
    documentationNeeded: 'application-documentation-needed',
    findersFee: 'application-finders-fees',
    firstMonthlyPayment: 'application-first-monthly-payment',
    freeConveyancing: 'application-free-conveyancing',
    proofOfResidentialPurchaseLetToBuy:
      'application-proof-of-residential-purchase-let-to-buy',
  },
  customer: {
    id: 'customer',
    idAddressVerification: 'customer-id-address-verification',
    expatriates: 'customer-expatriates',
    foreignNationals: 'customer-foreign-nationals',
    howToCertifyADocument: 'customer-how-to-certify-a-document',
    refugees: 'customer-refugees',
  },
  income: {
    id: 'income',
    affordabilityAndSustainability: 'income-affordability-and-sustainability',
    applicantsNotPaidInSterling: 'income-applicants-not-paid-in-sterling',
    contractors: 'income-contractors',
    incomeTypes: 'income-income-types',
    lengthOfEmployment: 'income-length-of-employment',
    rentalIncome: 'income-rental-income',
    secondJob: 'income-second-job',
    selfEmployed: 'income-self-employed-applicants',
    selfEmployedContractors: 'income-self-employed-contractors',
    taxOnRentalIncome: 'income-tax-on-rental-income',
  },
  lending: {
    id: 'lending',
    assignableContracts: 'lending-assignable-contracts',
    bankruptcies: 'lending-bankruptcies',
    businessConsumerBuyToLet: 'lending-business-consumer-buy-to-let',
    concessionaryDiscountedPurchase:
      'lending-concessionary-discounted-purchase',
    countyCourtJudgementsCcJs: 'lending-county-court-judgements-cc-js',
    creditScoreDeclineAppeals: 'lending-credit-score-decline-appeals',
    dependants: 'lending-dependants',
    deposits: 'lending-deposits',
    firstTimeBuyerFirstTimeLandlord:
      'lending-first-time-buyer-first-time-landlord',
    lettingCriteria: 'lending-letting-criteria',
    lendingLimits: 'lending-lending-limits',
    minMaxAge: 'lending-min-max-age',
    minMaxTerm: 'lending-min-max-term',
    mortgageArrears: 'lending-mortgage-arrears',
    mortgageFreeUnencumberedProperties:
      'lending-mortgage-free-unencumbered-properties',
    notionalStressRates: 'lending-notional-stress-rates',
    portfolioLandlords: 'lending-portfolio-landlords',
    remortgageAndCapitalRaising: 'lending-remortgage-and-capital-raising',
    repaymentMethods: 'lending-repayment-methods',
  },
  property: {
    id: 'property',
    builderCashIncentives: 'property-builder-cash-incentives',
    buyToLetPropertyAddress: 'property-buy-to-let-property-address',
    cladding: 'property-cladding',
    condition: 'property-condition',
    construction: 'property-construction',
    distressedSaleAndLeaseback: 'property-distressed-sale-and-leaseback',
    energyPerformanceCertificate: 'property-energy-performance-certificate',
    exposureLimits: 'property-exposure-limits',
    freeValuations: 'property-free-valuations',
    housesInMultipleOccupation: 'property-houses-in-multiple-occupations',
    inheritedProperties: 'property-inherited-properties',
    japaneseKnotweed: 'property-japanese-knotweed',
    newBuild: 'property-new-build',
    revisedPropertyDetails: 'property-revised-property-details',
    scottishValuations: 'property-scottish-valuations',
    subSalesAndBackToBackTransactions:
      'property-sub-sales-and-back-to-back-transactions',
    tenure: 'property-tenure',
    unacceptableConstruction: 'property-unacceptable-construction',
    useOfProperty: 'property-use-of-property',
    valuation: 'property-valuation',
  },
  existingCustomer: {
    id: 'existing-customer',
    arrears: 'existing-customer-arrears',
    earlyRepaymentCharges: 'existing-customer-early-repayment-charges',
    furtherAdvances: 'existing-customer-further-advances',
    overpayments: 'existing-customer-overpayments',
    paymentHolidays: 'existing-customer-payment-holidays',
    portability: 'existing-customer-portability',
    productTransfers: 'existing-customer-product-transfers',
    productTransferTimescales: 'existing-customer-product-transfer-timescales',
    regulatedMortgages: 'existing-customer-regulated-mortgages',
    transferPurchaseOfEquity: 'existing-customer-transfer-purchase-of-equity',
  },
  topSlicing: {
    id: 'top-slicing',
    minimumTopSlicingCriteria: 'top-slicing-minimum-top-slicing-criteria',
    process: 'top-slicing-process',
    incomeAndAffordability: 'top-slicing-income-and-affordability',
  },
};

export function anchorLink(id:string) {
  return `#${id}`;
}
