import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';

import { TermsAndConditionsContent } from './TermsAndConditions.config';

function TermsAndConditionsPage(): React.JSX.Element {
  const { metaContent, pageTitle, infoPanel } =
    useContent<TermsAndConditionsContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <Heading as="h1" size="s7" marginTop="05">
          {pageTitle}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={{
                sectionHeading: 'terms-and-conditions-heading',
                sectionBody: 'terms-and-conditions-body',
              }}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default TermsAndConditionsPage;
