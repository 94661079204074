import getSafeLBGAnalytics from '../utils/getSafeLBGAnalytics';
import isTealiumAvailable from '../utils/isTealiumAvailable';

interface TealiumButtonClickEvent {
  label: string;
}

/**
 * Logs a click event, usually used for buttons but this can be used in the onClick function of any clickable element.
 * @param label The text content of the button
 */
export function logTealiumButtonClickEvent({ label }: TealiumButtonClickEvent) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.send({
      JourneyEvent: 'Button Click',
      LinkValue: label,
    });
  }
}
