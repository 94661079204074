import React from 'react';

import {
  ContentGroup,
  Heading,
  Hr,
  Link,
  Main,
  Paragraph,
  Strong,
  Grid,
  GridItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';

import { FreeConveyancingServiceContent } from './FreeConveyancingService.config';

function FreeConveyancingServicePage(): React.JSX.Element {
  const {
    pageTitle,
    backButtonLabel,
    backButtonHref,
    tableData,
    paragraph,
    sectionsList,
    metaContent,
  } = useContent<FreeConveyancingServiceContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={backButtonHref}
          marginRight="none"
        >
          {backButtonLabel}
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={testIds.freeConveyancingService.heading}
        >
          {pageTitle}
        </Heading>
        <Hr />
        <Grid>
          <GridItem sm={12} md={8}>
            <Paragraph data-testid={testIds.freeConveyancingService.paragraph}>
              {paragraph}
            </Paragraph>

            {sectionsList.map((sectionItem, index) => (
              <Paragraph marginBottom="none" key={sectionItem.id}>
                <Link
                  weight="bold"
                  href={`#${sectionItem.id}`}
                  data-testid={`${testIds.freeConveyancingService.sectionName}-${index}`}
                >
                  {sectionItem.text}
                </Link>
              </Paragraph>
            ))}

            {tableData.map((val, indexOne) => (
              <React.Fragment key={val.sectionId}>
                <Hr marginTop="05" marginBottom="02" />
                <Heading
                  id={val.sectionId}
                  as="h2"
                  size="s4"
                  marginTop="04"
                  marginBottom="02"
                  data-testid={`${testIds.freeConveyancingService.sectionNameInTable}-${indexOne}`}
                >
                  {val.sectionName}
                </Heading>
                <Grid>
                  {val.items.map((item, indexTwo) => (
                    <GridItem
                      key={item.id}
                      md={6}
                      sm={12}
                      data-testid={`${testIds.freeConveyancingService.tableItem}-${indexTwo}`}
                    >
                      <Paragraph marginBottom="none" size="s2" marginTop="04">
                        <Strong>{item.name}</Strong>
                      </Paragraph>
                      <StyledLink to={`tel:${item.telLink}`}>
                        {item.telText}
                      </StyledLink>
                      <Paragraph marginBottom="none">
                        <StyledLink to={`mailto:${item.email}`}>
                          {item.email}
                        </StyledLink>
                      </Paragraph>
                    </GridItem>
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default FreeConveyancingServicePage;
