import React from 'react';

import Document from '@constellation/core/icons/Document';

import { AccordionLinkProps } from './accordionLink.config';
import { StyledLink } from '../styledLink/StyledLink';

function AccordionLink({ href, title, text, testId }: AccordionLinkProps) {
  return (
    <>
      <StyledLink
        external
        to={href}
        icon={<Document />}
        iconPosition="left"
        data-testid={testId}
      >
        {`${title} ${text}`}
      </StyledLink>
      <br />
    </>
  );
}

export default AccordionLink;
