import React from 'react';

import { Strong } from '@constellation/core';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const intermediaryServiceSupport: AppContent['intermediaryServiceSupport'] = {
  pageHeading: 'Intermediary service support',
  metaContent: MetaContentValues.IntermediaryServiceSupport,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'intermediary-service-support',
        sectionBody: [
          {
            text: 'Our Intermediary service support team are available 9am to 5pm Monday Friday with the exception of Wednesday when they are available 10am to 5pm.',
          },
          {
            boldText: 'For queries with:',
          },
          {
            list: [
              'Registering on BM Solutions Online',
              'Technical issues when keying a New application or Product Transfer',
              'Broker panel',
              'Mortgage procuration fees',
            ],
          },
          {
            textWithLinks: [
              "If your online account is locked or you require a password reset please select the 'Forgotten password' link from the Sign in screen on ",
              {
                displayText: 'BM Solutions Online',
                external: true,
                href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx/',
              },
              '.',
            ],
          },
          {
            text: (
              <>
                Call us on <Strong>0345 124 1388</Strong> and select one of the
                options below:
              </>
            ),
          },
          {
            text: (
              <>
                <Strong>Option 1</Strong> - If you are experiencing a technical
                problem while keying a Buy to Let or Let to Buy mortgage or
                Product Transfer application.
              </>
            ),
          },
          {
            text: (
              <>
                <Strong>Option 2</Strong> - If your business details have
                changed, you have forgotten your user ID, you require support in
                registering with us or wish to discuss our privacy notice.
              </>
            ),
          },
          {
            text: (
              <>
                <Strong>Option 3</Strong> - If you have a query regarding
                procuration fees.
              </>
            ),
          },
          {
            textWithLinks: [
              'For queries relating to using BM Solutions Online, or if you are waiting for a one time password following registration, please ',
              {
                displayText: 'contact your Business Development Manager',
                external: false,
                href: `${routes.BDMSearch}`,
              },
              '.',
            ],
          },
        ],
      },
    ],
  },
};

export default intermediaryServiceSupport;
