import React from 'react';

import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const bdmSearch: AppContent['bdmSearch'] = {
  metaContent: MetaContentValues.BDMSearch,
  pageTitle: 'Search by postcode',
  backButtonLabel: 'Back',
  backButtonHref: routes.ContactUs,
  searchLabel: 'Postcode',
  searchSupportiveText: 'Enter a UK postcode to find the BDM for that region.',
  searchButton: 'Search',
  bdmResultsLabels: {
    email: 'Email',
    telephone: 'Telephone',
    postcodes: 'Postcodes covered',
  },
  noResultsError: 'Please enter a full valid UK postcode.',
  noInputError: 'You must enter a postcode.',
  foundBdms: 'The following BDMs were found for the postcode',
  resultsTextId: 'results-box',
  contentBox: {
    heading: 'Search for a BDM',
    paragraph: 'Our Business Development Managers (BDMs) can help you with:',
    listItem1: 'new business enquiries and criteria questions',
    listItem2: 'product information',
    listItem3: 'registering with us',
    listItem4: 'registering with BM Solutions Online.',
  },
  styledBox: {
    heading: 'National mortgage desk',
    telLabel: 'Telephone',
    telNumber: '0345 850 5000',
    telHref: 'tel:03458505000',
    availableLabel: 'Available',
    openTimes: 'Monday to Friday 8am to 6pm.',
  },
  applicationUpdate: {
    heading: 'If you need an update on an application',
    text: (
      <>
        Please refer to our{' '}
        <StyledLink to={routes.SubmittedMortgageApplications}>
          submitted mortgage applications page
        </StyledLink>
        .
      </>
    ),
  },

  /* Please keep these in alphabetical order by BDM name with TBDM entries first then BDM */
  bdmResults: [
    {
      name: 'Becky Burrows',
      role: 'TBDM',
      email: 'Becky.Burrows@birminghammidshires.co.uk',
      telephone: '02920 783502',
      postcodes: ['CW', 'CH', 'M', 'LL', 'SK', 'ST', 'WA'],
    },
    {
      name: 'Becky Burrows',
      role: 'TBDM',
      email: 'Becky.Burrows@birminghammidshires.co.uk',
      telephone: '02920 783502',
      postcodes: [
        'BB',
        'BD',
        'BL',
        'CA',
        'HX',
        'FY',
        'L',
        'LA',
        'OL',
        'PR',
        'WN',
      ],
    },
    {
      name: 'Danielle Horton',
      role: 'TBDM',
      email: 'Danielle.Horton@birminghammidshires.co.uk',
      telephone: '02920 783506',
      postcodes: ['BA', 'BS', 'CF', 'LD', 'NP', 'SA'],
    },
    {
      name: 'Gurdeep Chahal',
      role: 'TBDM',
      email: 'Gurdeep.Chahal@birminghammidshires.co.uk',
      telephone: '02920 783642',
      postcodes: ['CB', 'DE', 'LE', 'NG', 'PE'],
    },
    {
      name: 'Gurdeep Chahal',
      role: 'TBDM',
      email: 'Gurdeep.Chahal@birminghammidshires.co.uk',
      telephone: '02920 783642',
      postcodes: ['DN', 'HD', 'HU', 'LN', 'LS', 'S', 'WF'],
    },
    {
      name: 'Ieva Cebatoriute',
      role: 'TBDM',
      email: 'Ieva.Cebatoriute@birminghammidshires.co.uk',
      telephone: '02920 783536',
      postcodes: ['DH', 'DL', 'HG', 'NE', 'SR', 'TS', 'YO'],
    },
    {
      name: 'Ieva Cebatoriute',
      role: 'TBDM',
      email: 'Ieva.Cebatoriute@birminghammidshires.co.uk',
      telephone: '02920 783536',
      postcodes: [
        'AB',
        'DD',
        'DG',
        'EH',
        'FK',
        'G',
        'HS',
        'IV',
        'KA',
        'KW',
        'KY',
        'ML',
        'PA',
        'PH',
        'TD',
        'ZE',
      ],
    },
    {
      name: 'Ieva Cebatoriute',
      role: 'TBDM',
      email: 'Ieva.Cebatoriute@birminghammidshires.co.uk',
      telephone: '02920 783536',
      postcodes: ['BT'],
    },
    {
      name: 'Jack Pemberton',
      role: 'TBDM',
      email: 'Jack.Pemberton@birminghammidshires.co.uk',
      telephone: '02920 783532',
      postcodes: ['CV', 'MK', 'NN', 'OX'],
    },
    {
      name: 'Jack Pemberton',
      role: 'TBDM',
      email: 'Jack.Pemberton@birminghammidshires.co.uk',
      telephone: '02920 783532',
      postcodes: ['B', 'DY', 'HR', 'SY', 'TF', 'WR', 'WS', 'WV'],
    },
    {
      name: 'Joanna Green',
      role: 'TBDM',
      email: 'Joanna.Green@birminghammidshires.co.uk',
      telephone: '02920 783533',
      postcodes: ['HA', 'HP', 'SL', 'LU', 'TW', 'UB'],
    },
    {
      name: 'Joanna Green',
      role: 'TBDM',
      email: 'Joanna.Green@birminghammidshires.co.uk',
      telephone: '02920 783533',
      postcodes: [
        'AL',
        'EN',
        'N',
        'NW',
        'SG',
        'WD',
        'W2',
        'W3',
        'W4',
        'W5',
        'W6',
        'W7',
        'W8',
        'W9',
        'W10',
        'W11',
        'W12',
        'W13',
        'W14',
      ],
    },
    {
      name: 'Leroy Atkinson',
      role: 'TBDM',
      email: 'Leroy.Atkinson@birminghammidshires.co.uk',
      telephone: '02920 783501',
      postcodes: ['CM', 'CO', 'IP', 'NR'],
    },
    {
      name: 'Leroy Atkinson',
      role: 'TBDM',
      email: 'Leroy.Atkinson@birminghammidshires.co.uk',
      telephone: '02920 783501',
      postcodes: ['BN', 'CR', 'CT', 'RH', 'SM', 'TN'],
    },
    {
      name: 'Michelle Preston',
      role: 'TBDM',
      email: 'Michelle.Preston@birminghammidshires.co.uk',
      telephone: '02920 783534',
      postcodes: ['E', 'IG', 'RM', 'SS'],
    },
    {
      name: 'Michelle Preston',
      role: 'TBDM',
      email: 'Michelle.Preston@birminghammidshires.co.uk',
      telephone: '02920 783534',
      postcodes: ['EC', 'WC', 'W1'],
    },
    {
      name: 'Michelle Preston',
      role: 'TBDM',
      email: 'Michelle.Preston@birminghammidshires.co.uk',
      telephone: '02920 783534',
      postcodes: ['BR', 'DA', 'ME', 'SE', 'SW'],
    },
    {
      name: 'Richard Harrington',
      role: 'TBDM',
      email: 'Richard.Harrington@birminghammidshires.co.uk',
      telephone: '02920 783521',
      postcodes: ['GU', 'PO', 'SO', 'KT'],
    },
    {
      name: 'Richard Harrington',
      role: 'TBDM',
      email: 'Richard.Harrington@birminghammidshires.co.uk',
      telephone: '02920 783521',
      postcodes: [
        'BH',
        'DT',
        'EX',
        'GL',
        'PL',
        'RG',
        'SN',
        'SP',
        'TA',
        'TQ',
        'TR',
      ],
    },
    {
      name: 'Alan Green',
      role: 'BDM',
      email: 'AlanGreen@birminghammidshires.co.uk',
      telephone: '07818 011772',
      postcodes: ['CB', 'DE', 'LE', 'NG', 'PE'],
    },
    {
      name: 'Andrew Middleton',
      role: 'BDM',
      email: 'Andrew.Middleton@BirminghamMidshires.co.uk',
      telephone: '07920 568854',
      postcodes: ['DN', 'HD', 'HU', 'LN', 'LS', 'S', 'WF'],
    },
    {
      name: 'Chelsea Thomson',
      role: 'BDM',
      email: 'Chelsea.Thomson@birminghammidshires.co.uk',
      telephone: '07970 964019',
      postcodes: [
        'AB',
        'DD',
        'DG',
        'EH',
        'FK',
        'G',
        'HS',
        'IV',
        'KA',
        'KW',
        'KY',
        'ML',
        'PA',
        'PH',
        'TD',
        'ZE',
      ],
    },
    {
      name: 'Claire Biddick',
      role: 'BDM',
      email: 'Claire.Biddick@birminghammidshires.co.uk',
      telephone: '07468 712415',
      postcodes: ['CM', 'CO', 'IP', 'NR'],
    },
    {
      name: 'Gareth Stockton',
      role: 'BDM',
      email: 'Gareth.Stockton@birminghammidshires.co.uk',
      telephone: '07920 495278',
      postcodes: ['CW', 'CH', 'M', 'LL', 'SK', 'ST', 'WA'],
    },
    {
      name: 'Gary Watson',
      role: 'BDM',
      email: 'Gary.Watson@birminghammidshires.co.uk',
      telephone: '07879 604683',
      postcodes: ['DH', 'DL', 'HG', 'NE', 'SR', 'TS', 'YO'],
    },
    {
      name: 'Gurdeep Bahra',
      role: 'BDM',
      email: 'Gurdeep.Bahra@birminghammidshires.co.uk',
      telephone: '07967 380272',
      postcodes: ['E', 'IG', 'RM', 'SS'],
    },
    {
      name: 'Ian Southern',
      role: 'BDM',
      email: 'IanSouthern@birminghammidshires.co.uk',
      telephone: '07841 952540',
      postcodes: ['GU', 'PO', 'SO', 'KT'],
    },
    {
      name: 'Isaac Kikabi',
      role: 'BDM',
      email: 'Isaac.Kikabi@birminghammidshires.co.uk',
      telephone: '07385 032470',
      postcodes: ['BR', 'DA', 'ME', 'SE', 'SW'],
    },
    {
      name: 'James Clemson',
      role: 'BDM',
      email: 'James.Clemson@birminghammidshires.co.uk',
      telephone: '07768 008516',
      postcodes: ['EC', 'WC', 'W1'],
    },
    {
      name: 'Jeni Law',
      role: 'BDM',
      email: 'Jeni.Law@birminghammidshires.co.uk',
      telephone: '07970 566576',
      postcodes: [
        'BB',
        'BD',
        'BL',
        'CA',
        'HX',
        'FY',
        'L',
        'LA',
        'OL',
        'PR',
        'WN',
      ],
    },
    {
      name: 'Jonathan Smith',
      role: 'BDM',
      email: 'Jonathan.Smith@birminghammidshires.co.uk',
      telephone: '07976 563258',
      postcodes: ['BA', 'BS', 'CF', 'LD', 'NP', 'SA'],
    },
    {
      name: 'Kellie Parish',
      role: 'BDM',
      email: 'Kellie.Parish@birminghammidshires.co.uk',
      telephone: '07795 800053',
      postcodes: ['CV', 'MK', 'NN', 'OX'],
    },
    {
      name: 'Mark Penfold',
      role: 'BDM',
      email: 'Mark_Penfold@birminghammidshires.co.uk',
      telephone: '07976 778836',
      postcodes: ['BN', 'CR', 'CT', 'RH', 'SM', 'TN'],
    },
    {
      name: 'Nusha Hamidi Ahmetagic',
      role: 'BDM',
      email: 'Nusha.Hamidi.Ahmetagic@birminghammidshires.co.uk',
      telephone: '07384 535058',
      postcodes: ['HA', 'HP', 'SL', 'LU', 'TW', 'UB'],
    },
    {
      name: 'Paul Watts',
      role: 'BDM',
      email: 'Paul.Watts@birminghammidshires.co.uk',
      telephone: '07767 227565',
      postcodes: [
        'AL',
        'EN',
        'N',
        'NW',
        'SG',
        'WD',
        'W2',
        'W3',
        'W4',
        'W5',
        'W6',
        'W7',
        'W8',
        'W9',
        'W10',
        'W11',
        'W12',
        'W13',
        'W14',
      ],
    },
    {
      name: 'Sacha Buckland',
      role: 'BDM',
      email: 'Sacha.Buckland@birminghammidshires.co.uk',
      telephone: '07770 701791',
      postcodes: [
        'BH',
        'DT',
        'EX',
        'GL',
        'PL',
        'RG',
        'SN',
        'SP',
        'TA',
        'TQ',
        'TR',
      ],
    },
    {
      name: 'Sam Richards',
      role: 'BDM',
      email: 'Samantha.Richards@birminghammidshires.co.uk',
      telephone: '07867 180025',
      postcodes: ['B', 'DY', 'HR', 'SY', 'TF', 'WR', 'WS', 'WV'],
    },
    {
      name: 'Tonya Dornan',
      role: 'BDM',
      email: 'TonyaDornan@birminghammidshires.co.uk',
      telephone: '07841 491796',
      postcodes: ['BT'],
    },
  ],
};

export default bdmSearch;
