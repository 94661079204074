import React from 'react';

import { Text } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const fees: AppContent['fees'] = {
  metaContent: MetaContentValues.Fees,
  pageTitle: 'Fees',
  backButtonLabel: 'Back',
  backButtonHref: routes.PlacingBusiness,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        isAccordion: false,
        sectionBody: [
          {
            container: contentSectionBox({ marginBottom: '05' }),
            subheading:
              'New business, additional borrowing, product transfer, and porting procuration fees',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            basicTable: {
              ariaLabel:
                'table of fees for New business, additional borrowing, product transfer, and porting procuration',
              testIdPrefix: 'new-business-borrowing-product-transfer-table',
              width: 'fluid',
              columns: [
                { displayText: '', key: 'col-blank' },
                { displayText: 'Direct', key: 'col-direct' },
                { displayText: 'By club / network', key: 'col-club' },
              ],
              rows: [
                {
                  'col-blank': 'Buy to let, let to buy',
                  'col-direct': '36 BPS',
                  'col-club': 'Up to 50 BPS',
                },
                {
                  'col-blank': '',
                  'col-direct':
                    'Fees will be paid for business submitted online direct to BM Solutions',
                  'col-club':
                    'Fees will be paid to the club/network. Contact your distributor representative for details of their procuration fee scales and terms',
                },
              ],
            },
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: 'Procuration fees are calculated as per the terms agreed with your club/network.',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            subheading: 'Valuation fees',
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            basicTable: {
              ariaLabel: 'table of valuation fees',
              testIdPrefix: 'valuation-fee-table',
              width: 'fluid',
              columns: [
                {
                  displayText:
                    'Purchase Price/Estimated value up to and including',
                  key: 'col-price',
                },
                {
                  displayText: 'Level 1 Buy to Let, Let to Buy',
                  key: 'col-level-1',
                },
                {
                  displayText: 'Level 2 Buy to Let, Let to Buy',
                  key: 'col-level-2',
                },
              ],
              rows: [
                {
                  'col-price': '£50k',
                  'col-level-1': '£100',
                  'col-level-2': 'N/A',
                },
                {
                  'col-price': '£100k',
                  'col-level-1': '£100',
                  'col-level-2': '£390',
                },
                {
                  'col-price': '£150k',
                  'col-level-1': '£100',
                  'col-level-2': '£420',
                },
                {
                  'col-price': '£200k',
                  'col-level-1': '£100',
                  'col-level-2': '£470',
                },
                {
                  'col-price': '£300k',
                  'col-level-1': '£100',
                  'col-level-2': '£590',
                },
                {
                  'col-price': '£400k',
                  'col-level-1': '£100',
                  'col-level-2': '£720',
                },
                {
                  'col-price': '£500k',
                  'col-level-1': '£100',
                  'col-level-2': '£865',
                },
                {
                  'col-price': '£600k',
                  'col-level-1': '£100',
                  'col-level-2': '£1005',
                },
                {
                  'col-price': '£700k',
                  'col-level-1': '£100',
                  'col-level-2': '£1150',
                },
                {
                  'col-price': '£800k',
                  'col-level-1': '£100',
                  'col-level-2': '£1290',
                },
                {
                  'col-price': '£900k',
                  'col-level-1': '£100',
                  'col-level-2': '£1435',
                },
                {
                  'col-price': '£1m',
                  'col-level-1': '£100',
                  'col-level-2': '£1575',
                },
                {
                  'col-price': '£1.2m',
                  'col-level-1': '£100',
                  'col-level-2': '£1775',
                },
                {
                  'col-price': '£1.4m',
                  'col-level-1': '£100',
                  'col-level-2': '£1980',
                },
                {
                  'col-price': '£1.6m',
                  'col-level-1': '£100',
                  'col-level-2': '£2180',
                },
                {
                  'col-price': '£1.8m',
                  'col-level-1': '£100',
                  'col-level-2': '£2385',
                },
                {
                  'col-price': '£2m',
                  'col-level-1': '£100',
                  'col-level-2': '£2585',
                },
              ],
            },
          },
          {
            subheading:
              'Product transfer and further advance revaluations fees',
          },
          {
            text: 'The fee for product transfer and further advance re-valuations is £100.',
          },
          {
            boldText:
              "*Product transfer revaluations can be carried out at the customers' request.",
          },
          {
            text: 'You can find the current valuation of the property in the Summary of accounts screen at the start of the product transfer application.',
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            textWithLinks: [
              "If the applicants would like to pay for a revaluation of the property, you'll need to complete the ",
              {
                displayText: 'Product transfer revaluation authority form',
                href: 'https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-pt-revaluation-authority-form.html',
                external: true,
              },
              '. The revaluation will need to be carried out before you submit a product transfer application.',
            ],
          },
          {
            subheading: 'Level 1 includes the following instruction types:',
          },
          {
            text: 'Remortgage, building survey where only the Level 1 fee is collected.',
          },
          {
            text: 'The type of valuation will be decided at application stage.',
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: 'The valuation fees shown here are for work carried out by e.surv, or by another valuation firm appointed by us.',
          },
          {
            subheading: 'Scottish valuations',
          },
          {
            text: 'In Scotland, a retype/transcription report from a Generic Mortgage Valuation Report (GMVR) attached to a valid home report is no longer acceptable for a buy to let.',
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            textWithLinks: [
              'For further clarification, please ',
              {
                displayText: 'contact your Business Development Manager',
                href: routes.BDMSearch,
                external: false,
              },
              '.',
            ],
          },
          { subheading: 'Other mortgage fees' },
          {
            component: (
              <Text>
                <Text weight="bold">Product fee: </Text> See specific product
                details.
              </Text>
            ),
          },
        ],
      },
    ],
  },
};

export default fees;
