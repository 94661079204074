import React from 'react';

import { Brand } from './Brand';

interface BrandContextType {
  brand: Brand;
}

const BrandContext = React.createContext<BrandContextType | null>(null);

export default BrandContext;
