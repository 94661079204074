import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import testIds from 'client/testIds';

import { CalculatorsPageContent } from './Calculators.config';
import {
  StyledCalulatorTable,
  StyledCalulatorTableCol,
  StyledCalulatorTableRow,
} from './CalculatorsPage.styled';

function CalculatorsPage() {
  const {
    pageTitle,
    infoPanel1,
    paragraph,
    headingTwo,
    infoPanel2,
    table1Data,
    table2Data,
    table3Data,
    metaContent,
  } = useContent<CalculatorsPageContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={testIds.calculatorsPage.heading}
        >
          {pageTitle}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              pageTestIds={{
                sectionBody: 'calculators-sectionBody',
                sectionHeading: 'calculators-Heading',
              }}
              infoPanel={infoPanel1}
            />
            <StyledCalulatorTable width="auto" responsive={false}>
              <StyledCalulatorTableRow
                data-testid={testIds.calculatorsPage.table1Data.heading1}
              >
                <StyledCalulatorTableCol>
                  <Strong>{table1Data.heading1}</Strong>
                </StyledCalulatorTableCol>
                <StyledCalulatorTableCol>
                  <Strong>{table1Data.heading2}</Strong>
                </StyledCalulatorTableCol>
              </StyledCalulatorTableRow>

              {table1Data.rowData.map((rowData, index) => (
                <StyledCalulatorTableRow
                  key={`${rowData.column1}+${rowData.column2}`}
                  data-testid={`${testIds.calculatorsPage.table1Data.rowData}-${index}`}
                >
                  <StyledCalulatorTableCol>
                    {rowData.column1}
                  </StyledCalulatorTableCol>
                  <StyledCalulatorTableCol>
                    {rowData.column2}
                  </StyledCalulatorTableCol>
                </StyledCalulatorTableRow>
              ))}
            </StyledCalulatorTable>

            <Paragraph data-testid={testIds.calculatorsPage.paragraph}>
              {paragraph}
            </Paragraph>

            <Heading
              data-testid={testIds.calculatorsPage.headingTwo}
              as="h3"
              size="s3"
            >
              {headingTwo}
            </Heading>

            <StyledCalulatorTable width="auto" responsive={false}>
              <StyledCalulatorTableRow
                data-testid={testIds.calculatorsPage.table2Data.heading1}
              >
                <StyledCalulatorTableCol>
                  <Strong>{table2Data.heading1}</Strong>
                </StyledCalulatorTableCol>
                <StyledCalulatorTableCol>
                  <Strong>{table2Data.heading2}</Strong>
                </StyledCalulatorTableCol>
              </StyledCalulatorTableRow>

              {table2Data.rowData.map((rowData, index) => (
                <StyledCalulatorTableRow
                  key={`${rowData.column1}+${rowData.column2}`}
                  data-testid={`${testIds.calculatorsPage.table2Data.rowData}-${index}`}
                >
                  <StyledCalulatorTableCol>
                    {rowData.column1}
                  </StyledCalulatorTableCol>
                  <StyledCalulatorTableCol>
                    {rowData.column2}
                  </StyledCalulatorTableCol>
                </StyledCalulatorTableRow>
              ))}
            </StyledCalulatorTable>

            <StyledCalulatorTable width="auto" responsive={false}>
              <StyledCalulatorTableRow
                data-testid={testIds.calculatorsPage.table3Data.heading1}
              >
                <StyledCalulatorTableCol>
                  <Strong>{table3Data.heading1}</Strong>
                </StyledCalulatorTableCol>
                <StyledCalulatorTableCol>
                  <Strong>{table3Data.heading2}</Strong>
                </StyledCalulatorTableCol>
              </StyledCalulatorTableRow>

              {table3Data.rowData.map((rowData, index) => (
                <StyledCalulatorTableRow
                  key={`${rowData.column1}+${rowData.column2}`}
                  data-testid={`${testIds.calculatorsPage.table3Data.rowData}-${index}`}
                >
                  <StyledCalulatorTableCol>
                    {rowData.column1}
                  </StyledCalulatorTableCol>
                  <StyledCalulatorTableCol>
                    {rowData.column2}
                  </StyledCalulatorTableCol>
                </StyledCalulatorTableRow>
              ))}
            </StyledCalulatorTable>

            <InfoPanel
              pageTestIds={{
                sectionBody: 'calculators-sectionBody-two',
                sectionHeading: 'calculators-Heading-two',
              }}
              infoPanel={infoPanel2}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default CalculatorsPage;
