import { Box } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledHeroImageBox: StyledComponent<typeof Box, any> = styled<
  typeof Box
>(Box)`
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.color_source_primary_1},
    ${({ theme }) => theme.color_background_panel_brand_2}
  );
  display: flex;
  height: 125px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 0rem !important;
`;
