import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const educationalHub: AppContent['educationalHub'] = {
  metaContent: MetaContentValues.EducationalHub,
  pageTitle: 'Educational Hub',
  backButtonLabel: 'Back',
  backButtonHref: routes.SustainabilityHub,
  iFramePanel: {
    heading: 'Watch our Sustainability in the private rental sector video',
    url: 'https://www.youtube.com/embed/xpCheL9AJfI?si=c5yi6EjTQv7iLq_4',
  },
  infoPanel: {
    contentSections: [
      {
        sectionID: 'introduction',
        isAccordion: false,
        sectionBody: [
          {
            text: 'Join Leigh Church, Head of BM Solutions, and Heather Cara, Senior Manager at BM Solutions, to hear more about why sustainability matters in the private rental sector.',
          },
        ],
      },
      {
        sectionID: 'broker-training-module',
        isAccordion: false,
        sectionBody: [
          {
            boldText: 'Broker training module',
          },
          {
            text: "Try our broker self-led training modules. They're designed to help improve your understanding and awareness of why sustainability is so important in the housing market and help you have more effective conversations with your customers.",
          },
        ],
      },
      {
        sectionID: 'module-1',
        isAccordion: false,
        sectionBody: [
          {
            boldText: 'Module 1',
          },
          {
            textWithLinks: [
              {
                displayText:
                  'Overview on EPCs, exemptions, and what it means to you',
                external: true,
                href: '/public/overview-on-EPCs-exemptions-and-what-it-means-for-you.pdf',
              },
            ],
          },
        ],
      },
    ],
  },
};

export default educationalHub;
