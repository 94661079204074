import { AppContent } from 'content/AppContent';

import disclaimer from './disclaimer';
import footer from './footer';
import navigationMenu from './navigationMenu';

export const appLayout: AppContent['appLayout'] = {
  disclaimer,
  navigationMenu,
  header: {
    accessibleLogoDescription: {
      text: 'Birmginham Solutions Logo',
    },
    signIn: {
      text: 'Sign in',
      href: 'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
    },
  },
  footer,
};
