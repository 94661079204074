import React from 'react';

import {
  Heading,
  Main,
  Paragraph,
  Accordion,
  Grid,
  GridItem,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import CustomAccordion from 'client/components/CustomAccordion/CustomAccordion';
import MetaTags from 'client/components/metaTags/MetaTags';

import { ResourcesPageContent } from './Resources.config';
import { resourcesPage as testId } from '../../testIds/resources';

export default function Resourcespage() {
  const {
    pageHeading,
    guidesAndFormsSection,
    regulatoryGuidanceSection,
    usefulGuidesAndFormsSection,
    placingBusinessSection,
    customerInformationSection,
    metaContent,
  } = useContent<ResourcesPageContent>();
  const { regulatoryGuidanceTitle, ...regulatoryItems } =
    regulatoryGuidanceSection;

  const regulatoryAccordionItems = Object.values(regulatoryItems);

  const { usefulGuidesAndFormsTitle, ...usefulGuidesAndFormsItems } =
    usefulGuidesAndFormsSection;

  const usefulGuidesAndFormsAccordionItems = Object.values(
    usefulGuidesAndFormsItems,
  );

  const { placingBusinessTitle, newBTLandLTBApplications, existingCustomers } =
    placingBusinessSection;

  const newBTLandLTBApplicationsItems = Object.values(
    newBTLandLTBApplications,
  ).filter(
    (item): item is { link: string; title: string; text: string } =>
      typeof item === 'object' && 'link' in item,
  );

  const existingCustomersItems = Object.values(existingCustomers).filter(
    (item): item is { link: string; title: string; text: string } =>
      typeof item === 'object' && 'link' in item,
  );

  const customerInformationItems = Object.values(
    customerInformationSection,
  ).filter(
    (item): item is { link: string; title: string; text: string } =>
      typeof item === 'object' && 'link' in item,
  );

  return (
    <Main>
      <MetaTags metaContent={metaContent} />
      <Heading as="h1" size="s7" data-testid={testId.pageHeading}>
        {pageHeading}
      </Heading>
      <Heading as="h2" size="s4" data-testid={testId.guidesAndForms}>
        {guidesAndFormsSection.guidesAndFormsHeader}
      </Heading>
      <Grid>
        <GridItem sm={12} md={8}>
          <Paragraph data-testid={testId.guidesAndFormsText}>
            {guidesAndFormsSection.guidesAndFormsText}
          </Paragraph>
          <CustomAccordion
            label={regulatoryGuidanceTitle}
            dataTestId={`${testId.regulatoryGuidanceId}`}
            variationType="inpage1"
            items={regulatoryAccordionItems}
          />

          <CustomAccordion
            label={usefulGuidesAndFormsTitle}
            dataTestId={`${testId.usefulGuidesAndFormsId}`}
            variationType="inpage1"
            items={usefulGuidesAndFormsAccordionItems}
          />

          <Accordion
            label={placingBusinessTitle}
            data-testid={testId.placingBusinessTitleId}
            variation="inpage1"
          >
            <Grid>
              <GridItem>
                <Container padding="none" marginBottom="03">
                  <CustomAccordion
                    label={newBTLandLTBApplications.title}
                    dataTestId={`${testId.placingBusinessTitleId}_newBTLandLTBApplications`}
                    variationType="standalone"
                    items={newBTLandLTBApplicationsItems}
                  />
                </Container>
              </GridItem>
              <GridItem>
                <Container padding="none">
                  <CustomAccordion
                    label={existingCustomers.title}
                    dataTestId={`${testId.placingBusinessTitleId}_existingCustomers`}
                    variationType="standalone"
                    items={existingCustomersItems}
                  />
                </Container>
              </GridItem>
            </Grid>
          </Accordion>
          <CustomAccordion
            label={customerInformationSection.title}
            dataTestId={`${testId.customerInformationId}`}
            variationType="inpage1"
            items={customerInformationItems}
          />
        </GridItem>
      </Grid>
    </Main>
  );
}
