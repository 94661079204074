import { Table, TableCol } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

type TableProps = React.ComponentProps<typeof Table>;

type StyledTableType = StyledComponent<typeof Table, any, TableProps, never>;

export const StyledTable: StyledTableType = styled(Table)(
  ({ theme }) => `
  &&&&& {
    thead th {
      padding: 1rem 0.5rem;
      span {
        font-size: ${theme.typography_text_font_size_s1};
      }
    }

    @media (min-width: ${theme.breakpoint_lg}) {
      thead th {
        padding: 1.5rem 1.5rem;
        span {
          font-size: ${theme.typography_text_font_size_s2};
        }
      }
    }
  }
`,
);

type TableColProps = React.ComponentProps<typeof TableCol>;

type StyledTableColType = StyledComponent<
  typeof TableCol,
  any,
  TableColProps,
  never
>;

export const StyledTableCol: StyledTableColType = styled(TableCol)(
  ({ theme }) => `
  &&&&& {
    margin-top: ${theme.spacing_none};
    span > h3 {
      font-size: ${theme.typography_text_font_size_s2};
      margin-bottom: ${theme.spacing_none};
    }
    padding: ${theme.spacing_02};

    span {
      font-size: ${theme.typography_text_font_size_s1};
      margin-bottom: ${theme.spacing_01};
    }

    @media (min-width: ${theme.breakpoint_lg}) {
      padding: 1rem 1.5rem;
      span {
        font-size: ${theme.typography_text_font_size_s2};
      }
    }
  }
`,
);
