import React from 'react';

import { Text } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

type TextProps = React.ComponentProps<typeof Text>;

interface StyledTextProps extends TextProps {
  verticalAlign?:
    | 'baseline'
    | 'sub'
    | 'super'
    | 'top'
    | 'bottom'
    | 'middle';
}

type StyledTextType = StyledComponent<
  typeof Text,
  any,
  React.ComponentProps<typeof Text>,
  never
>;

export const StyledTextComponent: StyledTextType = styled(
  Text,
)<StyledTextProps>`
  vertical-align: ${({ verticalAlign }) => verticalAlign};
`;

export function StyledText({
  children,
  ...rest
}: StyledTextProps): React.JSX.Element {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <StyledTextComponent {...rest}>{children}</StyledTextComponent>
  );
}
