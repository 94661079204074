import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const epcRegulations: AppContent['epcRegulations'] = {
  metaContent: MetaContentValues.EpcRegulations,
  pageTitle: 'Current and proposed EPC regulations',
  backButtonLabel: 'Back',
  backButtonHref: routes.SustainabilityHub,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        isAccordion: false,
        sectionBody: [
          {
            text: "At BM Solutions, we're supporting the move to a sustainable housing market.",
          },
          {
            text: 'We need to reduce carbon emissions from building and heating our homes to tackle climate change and reach government net zero targets.',
          },
          {
            subheading: 'England and Wales',
            container: contentSectionBox({ marginTop: '08' }),
          },
          {
            textWithLinks: [
              'In England and Wales, landlords can only let out properties with a minimum EPC rating of E, unless ',
              {
                displayText: "they've registered an exemption",
                external: true,
                href: 'https://www.gov.uk/government/publications/private-rented-sector-minimum-energy-efficiency-standard-exemptions/guidance-on-prs-exemptions-and-exemptions-register-evidence-requirements',
              },
              '.',
            ],
          },
          {
            boldText:
              "If your customer has a property that doesn't meet the energy efficiency standard, or they haven't registered an exemption, they could:",
          },
          {
            list: [
              'be prevented from letting out a property and lose their rental income and be fined up to £5,000',
              'struggle to remortgage their buy-to-let property as mortgage lenders now ask for evidence of property EPC rating.',
            ],
          },
          {
            subheading: 'Energy-efficiency cost-cap',
            container: contentSectionBox({ marginTop: '08' }),
          },
          {
            text: "Landlords are only required to spend up to £3,500 to boost their property's energy-efficiency. If it still doesn't meet the E-rating after that, they can register an exemption.",
          },
          {
            subheading: 'Scotland and Northern Ireland',
            container: contentSectionBox({ marginTop: '08' }),
          },
          {
            text: "In Scotland and Northern Ireland, privately rented properties need a valid EPC certificate, but there's no minimum rating.",
          },
          {
            textWithLinks: [
              'In Scotland, the ',
              {
                displayText:
                  'government proposes landlords should meet a minimum energy-efficiency standard by 2028',
                external: true,
                href: 'https://www.gov.scot/binaries/content/documents/govscot/publications/consultation-paper/2023/11/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/documents/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill/govscot%3Adocument/delivering-net-zero-scotlands-buildings-consultation-proposals-heat-buildings-bill.pdf?optoutmulti=0:0%7Cc1:1%7Cc3:0%7Cc5:0%7Cc4:0%7Cc2:0&optmessage=1',
              },
              ". They can do this  by making improvements from a prescribed list, if it's relevant to their property.",
            ],
          },
          {
            text: "We'll keep you up-to-date with any rule changes and review our criteria to make sure you can keep supporting your customers.",
          },
          {
            text: "We'll only lend on private rented properties with a minimum EPC E-rating. This applies to buy-to-let and let-to-buy mortgages. Product transfers and transfers of equity are exempt.",
          },
          {
            subheading: 'Want to learn more?',
            container: contentSectionBox({ marginTop: '08' }),
          },
          {
            textWithLinks: [
              {
                displayText:
                  'Read our step-by-step guide to supporting your customers with EPC changes',
                external: false,
                href: routes.EpcStepByStepGuide,
              },
              '.',
            ],
          },
          {
            textWithLinks: [
              'Website for your customers where they can find out more about ',
              {
                displayText: 'EPC regulation',
                external: true,
                href: 'https://www.bmmortgages.co.uk/sustainability',
              },
              '.',
            ],
          },
        ],
      },
    ],
  },
};

export default epcRegulations;
