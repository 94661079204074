import { Link as InterstellarLink } from '@interstellar/react-app-routing';
import styled from 'styled-components';

const pxToRem = (px: any) => parseFloat(px) / 16;

const PrimaryButtonStyles = ({ theme }) => `
    background-color: ${theme.color_action_background_primary_default};
    border-color: ${theme.color_action_border_primary_default}; 

    &,
    & svg {
      color: ${theme.color_action_foreground_primary_default};
    }

    &:disabled {
      background-color: ${theme.color_action_background_primary_disabled};

      &,
      & svg {
        color: ${theme.color_action_foreground_primary_disabled};
      }
    }

    &:active:not(:disabled) {
      background-color: ${theme.color_action_background_primary_pressed};
      border-color: ${theme.color_action_border_primary_pressed};
      text-decoration: underline;

      &,
      & svg {
        color: ${theme.color_action_foreground_primary_pressed};
      }
    }

    &:hover:not(:disabled) {
      background-color: ${theme.color_action_background_primary_hover};
      border-color: ${theme.color_action_border_primary_hover};
      text-decoration: underline;

      &,
      & svg {
        color: ${theme.color_action_foreground_primary_hover};
      }
    }

    &:focus:not(:disabled) {
      background-color: ${theme.color_action_background_primary_focus};
      border-color: ${theme.color_action_border_primary_focus};
      text-decoration: underline;

      &,
      & svg {
        color: ${theme.color_action_foreground_primary_focus};
      }
    }

    &:focus,
    &:focus:hover {
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.125rem, rgb(131, 60, 140) 0px 0px 0px 0.25rem;
    }
`;

const SecondaryButtonStyles = ({ theme }) => `
  background-color: ${theme.color_action_background_secondary_default};
  border-color: ${theme.color_action_border_secondary_default};

  &,
  & svg {
    color: ${theme.color_action_foreground_secondary_default};
  }

  &:disabled {
    background-color: ${theme.color_action_background_secondary_default};

    &,
    & svg {
      color: ${theme.color_action_foreground_secondary_disabled};
    }
  }

  &:active:not(:disabled) {
    background-color: ${theme.color_action_background_secondary_pressed};
    border-color: ${theme.color_action_border_secondary_pressed};
    text-decoration: underline;

    &,
    & svg {
      color: ${theme.color_action_foreground_secondary_pressed};
    }
  }

  &:hover:not(:disabled) {
    background-color: ${theme.color_action_background_secondary_hover};
    border-color: ${theme.color_action_border_secondary_hover};
    text-decoration: underline;

    &,
    & svg {
      color: ${theme.color_action_foreground_secondary_hover};
    }
  }

  &:focus:not(:disabled) {
    background-color: ${theme.color_action_background_secondary_focus};
    border-color: ${theme.color_action_border_secondary_focus};
    text-decoration: underline;

    &,
    & svg {
      color: ${theme.color_action_foreground_secondary_focus};
    }
  }

  &:focus,
  &:focus:hover {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.125rem, rgb(131, 60, 140) 0px 0px 0px 0.25rem;
  }
`;

export const StyledLinkButton = styled(InterstellarLink)<{
  variation: 'secondary';
}>(
  ({ theme, variation }) => `
    align-items: center;
    border: solid ${pxToRem(2)}rem;
    border-radius: ${theme.button_border_radius};
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    outline: ${pxToRem(2)}rem solid transparent;
    outline-offset: -${pxToRem(2)}rem;
    padding: ${theme.button_padding_vertical || 0} ${theme.spacing_05}; /* Internet Explorer fallback */
    padding: calc(${theme.button_padding_vertical || 0} - ${pxToRem(2)}rem) calc(${theme.spacing_05} - ${pxToRem(2)}rem);
    position: relative;
    text-decoration: none;
    text-underline-offset: ${theme.link_text_underline_offset};
    transition-duration: ${theme.transition_duration_default};
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: ${theme.transition_easing_standard_css};
    width: 100%;

    &:focus:not(:disabled) {
      background-color: ${theme.color_action_background_primary_focus};
    }

    &:focus {
      border-width: ${theme.button_border_focus_width};
      padding: calc(${theme.button_padding_vertical || 0} - ${theme.button_border_focus_width}) calc(${theme.spacing_07} - ${theme.button_border_focus_width});
    }

    &:disabled {
      background-color: ${theme.color_action_background_primary_disabled};
      border-color: ${theme.color_action_border_primary_disabled};
      cursor: not-allowed;
      text-decoration: none;
    }

    @media (min-width: ${theme.breakpoint_md}) {
      padding-left: calc(${theme.button_padding_horizontal || 0} - ${pxToRem(2)}rem);
      padding-right: calc(${theme.button_padding_horizontal || 0} - ${pxToRem(2)}rem);
      min-width: ${theme.button_min_width};
      width: auto;

      &:focus {
        padding-left: calc(${theme.button_padding_horizontal || 0} - ${theme.button_border_focus_width});
        padding-right: calc(${theme.button_padding_horizontal || 0} - ${theme.button_border_focus_width});
      }
    }

    .csl_button__link {
      display: inline-block;
      width: 100%;
    }

    ${variation === 'secondary' ? SecondaryButtonStyles({ theme }) : PrimaryButtonStyles({ theme })}
`,
);
