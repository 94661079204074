import React from 'react';

import { DisplayText } from '@constellation/core';
import { useScreen } from 'client/context/ScreenContext';

import { FlexContainer, FlexImg, FlexTextBox } from './ContentBox.styled';

type DisplayTextProps = React.ComponentProps<typeof DisplayText>;

interface ContentBoxProps
  extends Pick<DisplayTextProps, 'marginTop' | 'marginBottom'> {
  imgSrc: any;
  imgWidth: string;
  marginLeft: string;
  marginRight: string;
  children: any;
  leftAlignImage: boolean;
  imgTestId?: string;
  imgAlt: string;
}

function ContentBox({
  imgSrc,
  imgWidth,
  marginLeft,
  marginRight,
  children,
  marginTop,
  marginBottom,
  leftAlignImage,
  imgTestId,
  imgAlt,
}: ContentBoxProps): React.JSX.Element {
  const { isMobile } = useScreen();

  function determineFlexDirection() {
    if (isMobile) {
      return leftAlignImage ? 'column-reverse' : 'column';
    }

    return leftAlignImage ? 'row-reverse' : 'row';
  }

  function determineImageMarginRight() {
    if (isMobile) {
      return 'auto';
    }

    return marginRight;
  }

  function determineImageMarginLeft() {
    if (isMobile) {
      return 'auto';
    }

    return marginLeft;
  }

  function determineJustifyContent() {
    if (leftAlignImage) {
      return 'flex-start';
    }

    return 'flex-end';
  }

  function determineTextBoxWidth() {
    /* Desktop view right aligned content boxes take 75% as the text in page banners shouldn't take up the full space */
    if (!leftAlignImage && !isMobile) {
      return '75%';
    }

    return '100%';
  }

  function determineTextBoxMarginLeft() {
    if (leftAlignImage && !isMobile) {
      return '05';
    }

    return 'none';
  }

  return (
    <FlexContainer
      justifyContent={determineJustifyContent()}
      flexDirection={determineFlexDirection()}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <FlexTextBox
        marginTop={isMobile ? '04' : 'none'}
        marginLeft={determineTextBoxMarginLeft()}
        maxWidth={determineTextBoxWidth()}
      >
        {children}
      </FlexTextBox>
      <FlexImg
        src={imgSrc}
        maxWidth={imgWidth}
        marginLeft={determineImageMarginLeft()}
        marginRight={determineImageMarginRight()}
        data-testid={imgTestId}
        alt={imgAlt}
      />
    </FlexContainer>
  );
}

export default ContentBox;
