import React from 'react';

import {
  ContentGroup,
  Heading,
  Main,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import contentBoxImage from 'assets/common/BM_illustration_chart_white.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';

import { ArticlesContent } from './Articles.config';

function ArticlesPage(): React.JSX.Element {
  const { metaContent, pageTitle, navigationTiles } =
    useContent<ArticlesContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <ContentBox
          imgSrc={contentBoxImage}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          marginLeft="auto"
          marginRight="12px"
          imgAlt="Icon of whiteboard with images on"
        >
          <Heading as="h1" size="s7" data-testid="articles-heading" inverse>
            {pageTitle}
          </Heading>
          <Paragraph
            marginBottom="08"
            marginTop="05"
            data-testid="articles-subheading"
            inverse
          >
            <Strong
              inverse
            >{`Here's where you can find the latest news and updates from BM Solutions.`}</Strong>
          </Paragraph>
        </ContentBox>
      </GradientBox>
      <ContentGroup marginTop="05">
        <NavigationTiles
          navigationTiles={navigationTiles}
          pageTestId="articles-page-navigation-tiles"
          layout="list"
        />
      </ContentGroup>
    </Main>
  );
}

export default ArticlesPage;
