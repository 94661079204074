export const homePage = {
  heroComponent: 'home-hero-component',

  homeGridImg: 'home-grid-image',
  homeGridHeading: 'home-grid-heading',
  homeGridSubheading: 'home-grid-subheading',
  homeGridButton: 'home-grid-button',

  whatWeOfferHeading: 'what-we-offer-heading',
  whatWeOfferFirstParagraph: 'what-we-offer-first-paragraph',
  whatWeOfferSecondParagraph: 'what-we-offer-second-paragraph',
  whatWeOfferButton: 'what-we-offer-button',

  newToBMImg: 'new-to-bm-img',
  newToBMHeading: 'new-to-bm-heading',
  newToBMFirstParagraph: 'new-to-bm-first-paragraph',
  newToBMSecondParagraph: 'new-to-bm-second-paragraph',
  newToBMSignin: 'new-to-bm-signin',

  sustainabilityImage: 'sustainability-image',
  sustainabilityHeading: 'sustainability-heading',
  sustainabilityFirstParagraph: 'sustainability-first-paragraph',
  sustainabilitySecondParagraph: 'sustainability-second-paragraph',
  sustainabilityButton: 'sustainability-button',
};
