import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  ContentGroup,
  Heading,
  List,
  Link,
  Main,
  Paragraph,
  Button,
  Container,
  Strong,
  IconPhone,
  IconClock,
  TextField,
  ListItem,
  Hr,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import contentBoxImage from 'assets/common/BM_illustration_signpost_white.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { BdmSearchContent } from './BdmSearch.config';
import { StyledBox } from './BdmSearchPage.styled';
import BdmResultBox from './components/BdmResultBox';
import getPostcodePrefixLettersWithNumberWhenWPostcode from './validation/getPostcodePrefixLettersWithNumberWhenWPostcode';
import isValidPostcodeWithMatchedPrefix from './validation/isValidPostcodeWithMatchedPrefix';

function BdmSearchPage(): React.JSX.Element {
  const {
    metaContent,
    pageTitle,
    backButtonLabel,
    backButtonHref,
    searchLabel,
    searchSupportiveText,
    contentBox,
    styledBox,
    applicationUpdate,
    bdmResults,
    noResultsError,
    noInputError,
    resultsTextId,
    foundBdms,
    searchButton,
  } = useContent<BdmSearchContent>();
  const postcodeInputRef = useRef(null);
  const [postcode, setPostcode] = useState('');
  const [isError, setIsError] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const matchingResults = useMemo(() => {
    if (postcode === '' && !isClicked) return;
    if (!postcode || (isClicked && postcode === '')) {
      setIsError(true);
      return [];
    }

    const inputValue = postcode.toUpperCase();
    const prefix = isValidPostcodeWithMatchedPrefix(inputValue);
    if (prefix === '' || inputValue === '') {
      setIsError(true);
      return [];
    }

    const trimmedPrefix =
      getPostcodePrefixLettersWithNumberWhenWPostcode(prefix);
    const results = bdmResults.filter((bdm) =>
      bdm.postcodes.some((pc) => trimmedPrefix === pc),
    );

    if (results.length === 0) {
      setIsError(true);
      return [];
    }

    setIsError(false);
    return results;
  }, [isClicked, postcode, bdmResults]);

  useEffect(() => {
    const element = document.getElementById(resultsTextId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [matchingResults, resultsTextId]);

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <Container padding="none" marginTop="05" marginBottom="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={backButtonHref}
        >
          {backButtonLabel}
        </StyledLink>
      </Container>
      <GradientBox>
        <ContentBox
          imgSrc={contentBoxImage}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          marginLeft="auto"
          marginRight="12px"
          imgAlt="Icon of a signpost"
        >
          <Heading as="h1" size="s7" inverse>
            {contentBox.heading}
          </Heading>
          <Paragraph inverse>{contentBox.paragraph}</Paragraph>
          <Paragraph marginBottom="08" marginTop="05" inverse>
            <List inverse>
              <ListItem>{contentBox.listItem1}</ListItem>
              <ListItem>{contentBox.listItem2}</ListItem>
              <ListItem>{contentBox.listItem3}</ListItem>
              <ListItem>{contentBox.listItem4}</ListItem>
            </List>
          </Paragraph>
        </ContentBox>
      </GradientBox>
      <ContentGroup marginTop="05">
        <Container marginTop="05" padding="none">
          <Strong>{applicationUpdate.heading}</Strong>
          <Paragraph marginTop="05">{applicationUpdate.text}</Paragraph>
        </Container>
        <Hr />
        <Heading as="h1" size="s6" marginTop="05">
          {pageTitle}
        </Heading>
        <form
          noValidate
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            setPostcode(postcodeInputRef?.current?.value);
            setIsClicked(true);
          }}
        >
          <Container padding="none" marginTop="05" marginBottom="05">
            <TextField
              label={searchLabel}
              name="postcode-input"
              inputRef={postcodeInputRef}
              supportiveText={searchSupportiveText}
            />
          </Container>
          <Container padding="none" marginBottom="05">
            <Button type="submit">{searchButton}</Button>
          </Container>
        </form>
        <Container padding="none" marginBottom="05">
          <Paragraph color="brand">
            {isError &&
              (postcodeInputRef?.current?.value.length > 0
                ? noResultsError
                : noInputError)}
          </Paragraph>
        </Container>
        {!isError && matchingResults && postcodeInputRef?.current?.value && (
          <Container padding="none" marginBottom="05">
            <Paragraph id={resultsTextId} color="normal">
              {foundBdms}{' '}
              {postcodeInputRef?.current?.value?.trim().toUpperCase().slice(0, -3)}{' '}
              {postcodeInputRef?.current?.value?.trim().toUpperCase().slice(-3)}.
            </Paragraph>
          </Container>
        )}
        <ContentGroup data-testid="bdm-results-section">
          {matchingResults &&
            matchingResults.map((result, resultIndex) => (
              <BdmResultBox testID={`bdm-result-box-${resultIndex + 1}`} key={result.name} result={result} />
            ))
          }
          {isClicked && !isError && (
            <StyledBox data-testid="national-mortgage-desk-box" padding="none" marginBottom="05">
              <Strong size="s4">{styledBox.heading}</Strong>
              <List>
                <ListItem icon={<IconPhone iconSize="md" color="brand" trim />}>
                  <Paragraph size="s2" marginTop="05" marginBottom="none">
                    {styledBox.telLabel}
                  </Paragraph>
                  <Link color="brand" href={styledBox.telHref}>
                    {styledBox.telNumber}
                  </Link>
                </ListItem>
                <ListItem icon={<IconClock iconSize="md" color="brand" trim />}>
                  <Paragraph size="s2" marginTop="05" marginBottom="none">
                    {styledBox.availableLabel}
                  </Paragraph>
                  <Strong>{styledBox.openTimes}</Strong>
                </ListItem>
              </List>
            </StyledBox>
          )}
        </ContentGroup>
      </ContentGroup>
    </Main>
  );
}

export default BdmSearchPage;
