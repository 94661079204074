import React from 'react';

import { Heading, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import main_image from 'assets/common/BM_illustration_signpost_white.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';
import testIds from 'client/testIds';

import { ContactUsPageContent } from './ContactUs.config';

export default function ContactUsPage() {
  const { pageTitle, pageText, navigationTiles, metaContent } =
    useContent<ContactUsPageContent>();
  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <ContentBox
          imgSrc={main_image}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          marginLeft="auto"
          marginRight="12px"
          imgAlt="Icon of a signpost"
        >
          <Heading
            as="h1"
            size="s7"
            inverse
            data-testid={testIds.contactUsPage.heading}
            marginBottom="03"
          >
            {pageTitle}
          </Heading>
          <Paragraph
            marginBottom="10"
            marginTop="none"
            data-testid={testIds.contactUsPage.text}
            inverse
          >
            {pageText}
          </Paragraph>
        </ContentBox>
      </GradientBox>

      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId={testIds.contactUsPage.sectionTitle}
      />
    </Main>
  );
}
