import React, { ReactNode } from 'react';

import { TableRow, Text } from '@constellation/core';

import { StyledTable, StyledTableCol } from './BasicTable.styled';

// each row an an object where you specify the column key & the data for that column
export type BasicTableRow = Record<string, string | ReactNode>;

export interface BasicTableRowComponentProps {
  row: BasicTableRow;
  columnKeyToHeading: Record<string, string>;
  testIdPrefix: string;
}

function BasicTableRowComponent({
  row,
  testIdPrefix,
  columnKeyToHeading,
}: BasicTableRowComponentProps) {
  const columnKeys = Object.keys(row);

  return (
    <TableRow>
      {columnKeys.map((columnKey) => (
        <StyledTableCol
          key={`${testIdPrefix}-tablecol-${columnKey}-${row[columnKey]}`}
          heading={columnKeyToHeading[columnKey]}
        >
          <Text>{row[columnKey]}</Text>
        </StyledTableCol>
      ))}
    </TableRow>
  );
}

export interface BasicTableColumn {
  key: string;
  displayText: string;
}

export interface BasicTableProps {
  ariaLabel: string;
  testIdPrefix: string;
  columns: BasicTableColumn[];
  rows: BasicTableRow[];
  width: 'auto' | 'fluid';
  responsive?: boolean;
}

function BasicTable({
  ariaLabel,
  testIdPrefix,
  columns,
  rows,
  width,
  responsive,
}: BasicTableProps): React.JSX.Element {
  const headings = columns.map(({ displayText }) => displayText);
  const columnKeyToHeading: Record<string, string> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const { displayText, key } of columns) {
    columnKeyToHeading[key] = displayText;
  }

  return (
    <StyledTable
      aria-label={`${ariaLabel}`}
      headings={headings}
      width={width}
      responsive={responsive ?? false}
    >
      {rows.map((row, index) => (
        <BasicTableRowComponent
          // eslint-disable-next-line react/no-array-index-key
          key={`${testIdPrefix}-table-row-component-${index}`}
          row={row}
          columnKeyToHeading={columnKeyToHeading}
          testIdPrefix={testIdPrefix}
        />
      ))}
    </StyledTable>
  );
}

export default BasicTable;
