import { Box } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledBox: StyledComponent<typeof Box, any> = styled<typeof Box>(
  Box,
)`
  &&& {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
