import React from 'react';

import { Box } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const productTransfers: AppContent['productTransfers'] = {
  metaContent: MetaContentValues.ProductTransfers,
  pageTitle: 'Product transfers',
  backButtonLabel: 'Back',
  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: (
              <>
                You need to{' '}
                <StyledLink
                  external
                  to="https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx"
                >
                  sign in to Intermediaries online
                </StyledLink>{' '}
                to submit an application.
              </>
            ),
          },
          {
            text: (
              <>
                You'll need a 10-character user ID to sign in. If you don't have
                a user ID, please read our{' '}
                <StyledLink to={routes.Register}>registration guide</StyledLink>
                .
              </>
            ),
          },
          {
            text: 'Product transfers can be submitted at any time in the month. The earliest a product transfer can start is the 1st of the following month.',
          },
          {
            text: "If your customer is within the last three months of an existing product, you can choose to start the new product early. If you select this option, we'll waive any early repayment charge (ERC). You can also choose to forward date the new product to start after the current product has ended.",
          },
          {
            text: 'If the account has multiple sub-accounts with different ERC periods, you can only complete a product transfer application on the sub-account with the ERC expiring the soonest.',
          },
          {
            text: 'When your customer is within the last 4 months of an existing product, you can choose to select a new product and forward date the new product to start after the current product has ended.',
          },
          {
            subheading: 'Before applying',
          },
          {
            text: 'The Product finder tab will show you which products are available for the customer.',
          },
          {
            text: "Customers can make overpayments from their bank account or over the phone before a product transfer is submitted. They'll need to wait for the overpayment to clear before submitting the product transfer, which will then start the following month.",
          },
          {
            text: 'After selecting any sub-accounts to transfer, you have the option to make changes to the repayment type and/or term if needed.',
          },
          {
            text: "If the customers' selected product includes a fee, you'll have the option to add this to the mortgage or pay it upfront. You'll be prompted to enter the customer's card details for the payment on submission if they select pay upfront.",
          },
          {
            text: 'Once a product has been chosen and a new start date selected, a quote and mortgage illustration can be generated and shared with your customers.',
          },
          { text: 'Submitting a product transfer' },
          {
            container: ({ children }) => (
              <Box bgColor="none" padding="none" marginBottom="05">
                {children}
              </Box>
            ),
            listItemContainer: ({ children }) => (
              <Box bgColor="none" padding="none" marginBottom="02">
                {children}
              </Box>
            ),
            list: [
              "You can't submit a product transfer and a further advance application at the same time.",
              'You can submit a product transfer online up to the day before it expires to start on 01 of the following month.',
              "Applications can't be keyed on Sundays.",
              "A product transfer application can't be started until a further advance application has completed and funds have been released.",
              "A further advance application can't be started until a product transfer application has been submitted and the new product has started.",
              <>
                If you get a message about needing to update a customer's
                account while keying a product transfer, you'll need to{' '}
                <StyledLink
                  external
                  to="https://www.bankofscotland.co.uk/mortgages/existing-customers/account-update-submit-pt-fad.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1"
                >
                  complete this form
                </StyledLink>
                .
              </>,
              <>
                Once submitted, you can view any available documents in the
                product transfers tab of the application home screen. If any
                details are incorrect, you'll need to cancel the application and
                ask the customer to call our Servicing team on{' '}
                <StyledLink external to="tel:03453002627">
                  0345 300 2627
                </StyledLink>{' '}
                to update them.
              </>,
            ],
          },
          {
            subheading: 'After submission of a product transfer',
          },
          {
            text: "The status of the application on the Mortgage applications home screen will show as 'Submitted', 'Ready to complete', or 'Completed'.",
          },
          {
            text: "If it shows as 'In Progress', it's not been fully submitted and will need to be cancelled and rekeyed. Any applications not fully submitted will be cancelled automatically after two days.",
          },
          {
            text: "Offer and illustration documents are available to view before full submission. You'll receive an email once the product transfer completes. This is usually the day after submission if you've selected the new product to start on the 1st of the following month, or one month before if you've selected the product to start when the current product ends.",
          },
          {
            text: "If completion of the product transfer is due within five days of the customers' next payment date, the Direct Debit will already have been requested. The existing payment will have been taken and the new payment will start the following month. If this means the customers underpays, they'll need to make an extra payment for the difference. If it means the customer overpays, the amount will be taken from their mortgage balance, or they can request a refund.",
          },
          {
            subheading: 'Cancelling a product transfer application',
          },
          {
            text: "A product application transfer can only be cancelled within 28 days of the date of the customer's completion letter. To cancel, please complete our cancellation form.",
          },
          {
            text: "We'll email you to confirm when the cancellation has been done. Any new product transfer application can't be keyed until you receive this confirmation.",
          },
          {
            text: "If a product transfer application is submitted and subsequently cancelled, you'll need to wait until the 1st of the following month before applying for a further advance.",
          },
          {
            text: 'If you want to change the new product selected on a product transfer offer, you must first cancel the existing application and submit a new one.',
          },
          {
            subheading: 'Completion of product transfers',
          },
          {
            text: 'When a product transfer has been future dated, completion will be early in the month before it starts. If the product transfer starts the following month, completion will take place shortly after the offer has been produced.',
          },
          {
            subheading: 'Cancelling a completed product transfer',
          },
          {
            text: "On your My applications page, find the required product transfer tile and select the 'Cancel' button. You can then key a new application. If the 'Cancel' button doesn't show, the product transfer has already completed and a 'Completed' status will show on the My applications page.",
          },
          {
            text: 'To cancel a completed product transfer application, please use our cancellation form.',
          },
          {
            linkButton: {
              displayText: 'Go to cancellation form',
              external: true,
              href: 'https://www.bankofscotland.co.uk/mortgages/bm-solutions/pt-cancellation-form.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
            },
          },
        ],
      },
    ],
  },
};

export default productTransfers;
