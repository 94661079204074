import React from 'react';

import { Heading } from '@constellation/core';

import { ContentSection, InfoPanelContent } from './InfoPanel.config';
import { SectionBody } from '../sectionBody';
import { TaggedAccordion } from '../taggedAccordion/TaggedAccordion';

function headingLevelStringToNumber(level: string) {
  if (!level) {
    return undefined;
  }

  const mapping = {
    h1: 1,
    h2: 2,
    h3: 3,
    h4: 4,
    h5: 5,
    h6: 6,
    span: undefined,
  };

  return mapping[level];
}

interface InfoPanelAccordionProps {
  section: ContentSection;
  sectionIndex: number;
  pageTestIds: any;
}

function InfoPanelAccordionSection({
  section,
  sectionIndex,
  pageTestIds,
}: InfoPanelAccordionProps) {
  return (
    <TaggedAccordion
      headingLevel={headingLevelStringToNumber(section.headingLevel)}
      id={section.sectionID}
      label={section.sectionHeading}
      variation="inpage1"
      data-testid={`${pageTestIds.sectionHeading}-${sectionIndex}`}
    >
      <SectionBody
        section={section}
        pageId={pageTestIds.sectionBody}
        sectionIndex={sectionIndex}
      />
    </TaggedAccordion>
  );
}

interface InfoPanelDefaultSectionProps {
  section: ContentSection;
  sectionIndex: number;
  pageTestIds: any;
}

function InfoPanelSection({
  section,
  sectionIndex,
  pageTestIds,
}: InfoPanelDefaultSectionProps) {
  return (
    <React.Fragment key={`${pageTestIds.sectionHeading}`}>
      {section.sectionHeading && (
        <Heading
          as={section.headingLevel ?? 'h2'}
          id={section.sectionID}
          data-testid={`${pageTestIds.sectionHeading}-${sectionIndex}`}
        >
          {section.sectionHeading}
        </Heading>
      )}
      <SectionBody
        section={section}
        pageId={pageTestIds.sectionBody}
        sectionIndex={sectionIndex}
      />
    </React.Fragment>
  );
}

type InfoPanelProps = { infoPanel: InfoPanelContent; pageTestIds: any };

function InfoPanel({ infoPanel, pageTestIds }: InfoPanelProps) {
  return (
    <>
      {infoPanel.contentSections.map((section: ContentSection, sectionIndex) =>
        section.isAccordion ? (
          <InfoPanelAccordionSection
            key={section.sectionID}
            pageTestIds={pageTestIds}
            section={section}
            sectionIndex={sectionIndex}
          />
        ) : (
          <InfoPanelSection
            key={section.sectionID}
            pageTestIds={pageTestIds}
            section={section}
            sectionIndex={sectionIndex}
          />
        ),
      )}
    </>
  );
}

export default InfoPanel;
