import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const freeConveyancingService: AppContent['freeConveyancingService'] = {
  metaContent: MetaContentValues.FreeConveyancingService,
  pageTitle: 'Free Conveyancing Service',
  backButtonLabel: 'Back',
  backButtonHref: routes.ContactUs,
  paragraph: 'Broker support when using our free conveyancing service firms.',
  sectionsList: [
    { text: 'England and Wales', id: 'england-and-wales' },
    { text: 'Scotland', id: 'scotland' },
    { text: 'Northern Ireland', id: 'northern-ireland' },
  ],
  tableData: [
    {
      sectionName: 'England and Wales',
      sectionId: 'england-and-wales',
      items: [
        {
          id: 'england-and-wales-1',
          name: 'Aberdein Considine',
          telText: '0333 009 6378',
          telLink: '03330096378',
          email: 'ewbmid.remortgages@acandco.com',
        },
        {
          id: 'england-and-wales-2',
          name: 'Enact Conveyancing',
          telText: '0344 244 3875',
          telLink: '03442443875',
          email: 'remobrokerupdates@enact.co.uk',
        },
        {
          id: 'england-and-wales-3',
          name: 'Fidler & Pepper',
          telText: '01623 703825',
          telLink: '01623703825',
          email: 'remortgage@fidler.co.uk',
        },
        {
          id: 'england-and-wales-4',
          name: 'Gordons Property Lawyers',
          telText: '01628 308380',
          telLink: '01628308380',
          email: 'LBGremortgage@gplawyers.co.uk',
        },
        {
          id: 'england-and-wales-5',
          name: 'Gorvins Residential',
          telText: '0161 527 2281',
          telLink: '01615272281',
          email: 'lbgfa@gorvinsresi.com',
        },
        {
          id: 'england-and-wales-6',
          name: `LMS O'Neill Patient`,
          telText: '0345 075 7306',
          telLink: '03450757306',
          email: 'Birminghammidshires@lms.com',
        },
        {
          id: 'england-and-wales-7',
          name: 'LMS Solex Legal Services',
          telText: '0345 075 7306',
          telLink: '03450757306',
          email: 'Birminghammidshires@lms.com',
        },
        {
          id: 'england-and-wales-8',
          name: `O'Neill Patient Solicitors LLP`,
          telText: '0161 676 5231',
          telLink: '01616765231',
          email: 'info@movera.co.uk',
        },
        {
          id: 'england-and-wales-9',
          name: 'Optima Legal Services',
          telText: '0344 571 3677',
          telLink: '03445713677',
          email: 'birminghammidshires@optimalegal.co.uk',
        },
        {
          id: 'england-and-wales-10',
          name: `Smoove Solex Legal Services`,
          telText: '0345 338 0127',
          telLink: '03453380127',
          email: 'info@solexlegalservices.com',
        },
        {
          id: 'england-and-wales-11',
          name: `Solex Legal Services`,
          telText: '0345 338 0127',
          telLink: '03453380127',
          email: 'info@solexlegalservices.com',
        },
        {
          id: 'england-and-wales-12',
          name: `Taylor Rose`,
          telText: '020 354 00087',
          telLink: '02035400087',
          email: 'michelle.phipps@taylor-rose.co.uk',
        },
      ],
    },

    {
      sectionName: 'Scotland',
      sectionId: 'scotland',
      items: [
        {
          id: 'scotland-1',
          name: 'Aberdein Considine',
          telText: '0333 006 6115',
          telLink: '03330066115',
          email: 'bmid.remortgages@acandco.com',
        },
        {
          id: 'scotland-2',
          name: 'Moray Legal',
          telText: '0344 571 5205',
          telLink: '03445715205',
          email: 'remgla@moray.legal',
        },
        {
          id: 'scotland-3',
          name: 'Simpson & Marwick',
          telText: '0344 571 5205',
          telLink: '03445715205',
          email: 'remgla@simpsonmarwick.com',
        },
      ],
    },

    {
      sectionName: 'Northern Ireland',
      sectionId: 'northern-ireland',
      items: [
        {
          id: 'northern-ireland-1',
          name: 'Wilson Nesbitt',
          telText: '0289 127 1035',
          telLink: '02891271035',
          email: 'remortgage@wilson-nesbitt.co.uk',
        },
      ],
    },
  ],
};

export default freeConveyancingService;
