import React from 'react';

import { StyledLink } from 'client/components/styledLink/StyledLink';
import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const home: AppContent['home'] = {
  metaContent: MetaContentValues.home,
  heroContent: {
    imageCaption: 'Our rental income calculator',
    imageSubtext: 'Designed by brokers for brokers.',
    btnLabel: 'Try our calculator',
    btnHref:
      'https://www2.bmsolutionsonline.co.uk/tools/rental-income-calculator',
  },
  productSearch: {
    heading: 'Product search',
    firstParagraph:
      "Search our mortgage products to find the right deals for your customers' needs.",
    btnHref:
      'https://www2.bmsolutionsonline.co.uk/tools/products/product-finder',
    btnLabel: 'View our products',
    external: true,
    target: '_blank',
  },
  mortgageCriteria: {
    heading: 'Our mortgage criteria',
    firstParagraph:
      'Find out what you need to know about our buy to let and let to buy mortgages eligibility criteria.',
    btnHref: routes.OurCriteria,
    btnLabel: 'View our criteria',
    external: false,
    target: '',
  },
  contactBDM: {
    heading: 'Contact your BDM',
    firstParagraph:
      'Use our handy postcode search to find the Business Development Manager (BDM) in your area.',
    btnHref: routes.BDMSearch,
    btnLabel: 'Search for your BDM',
    external: false,
    target: '',
  },
  newToBM: {
    heading: 'New to BM Solutions?',
    firstParagraph:
      "If you're not registered with us yet, you can do it online in just a few steps. You'll need a 10-character user ID to sign in.",
    secondParagraph: (
      <>
        To get your user ID and set things up,{' '}
        <StyledLink weight="bold" to={routes.Register}>
          register with us today.
        </StyledLink>
      </>
    ),
    signinHeader: 'Already registered with us?',
    signinButtonLabel: 'Sign in',
    signinButtonHref:
      'https://www.bmsolutionsonline.co.uk/IntermediariesOnline/Login/Public/Login.aspx',
  },

  sustainability: {
    heading: 'Sustainability Hub',
    firstParagraph:
      'Want to help your customers make their homes more energy-efficient? Our sustainability Hub can tell you how to do it.',
    secondParagraph:
      'Stay up-to-date with energy performance certificate (EPC) regulations, and understand what grants and schemes are on offer for your customers and their tenants.',
    findOutMoreBtnLabel: 'Learn more',
  },
};

export default home;
