const IS_W_POSTCODE = /^W\d{1,2}[A-Z]?/;
const DISTRICT_LETTER = /(\d)[A-Z]$/;
const NON_LETTERS = /[^A-Z]/g;

const trimLastLetter = (str) => /^[A-Z]$/.test(str.charAt(str.length - 1)) ? str.slice(0, -1) : str;

const getPostcodePrefixLettersWithNumberWhenWPostcode = (postcodePrefix: string): string => {
    const prefix = postcodePrefix?.trim()?.toUpperCase();
    if (!prefix) return;
    const isWPostcode = IS_W_POSTCODE.test(prefix);
    return isWPostcode
        ? trimLastLetter(prefix)
        : prefix.replace(DISTRICT_LETTER, '$1').replace(NON_LETTERS, '');
}

export default getPostcodePrefixLettersWithNumberWhenWPostcode;