import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const landlordRetrofitTool: AppContent['landlordRetrofitTool'] = {
  metaContent: MetaContentValues.LandlordRetrofitTool,
  pageTitle: 'Landlord Retrofit Tool',
  backButtonLabel: 'Back',
  backButtonHref: routes.SustainabilityHub,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'landlord-retrofit-tool',
        sectionBody: [
          {
            text: 'Help your customers understand how green their properties are and how a few changes could make them even more energy-efficient. You just need to have a few basic details about the property to hand to get your report.',
          },
          {
            linkButton: {
              displayText: 'Landlord Retrofit Tool',
              href: 'https://home-energy-saving-tool.bmsolutions.co.uk/WhereDoYouLive/',
              external: true,
            },
          },
        ],
      },
    ],
  },
};

export default landlordRetrofitTool;
