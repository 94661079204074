import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { InfoPanel } from 'client/components/infoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';

import { LowerRatesForEnergyEfficientHomesContent } from './LowerRatesForEnergyEfficientHomes.config';

function LowerRatesForEnergyEfficientHomesPage() {
  const { metaContent, pageTitle, backButtonHref, backButtonLabel, infoPanel } =
    useContent<LowerRatesForEnergyEfficientHomesContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          to={backButtonHref}
          marginRight="04"
        >
          {backButtonLabel}
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="05"
          data-testid={testIds.lowerRatesForEnergyEfficientHomes.heading}
        >
          {pageTitle}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={testIds.lowerRatesForEnergyEfficientHomes}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default LowerRatesForEnergyEfficientHomesPage;
