import React from 'react';

import { Table, TableRow, TableCol } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

type StyledCalulatorTableType = StyledComponent<
  typeof Table,
  any,
  React.ComponentProps<typeof Table>,
  never
>;

export const StyledCalulatorTable: StyledCalulatorTableType = styled(Table)`
  &&& {
    border: 0;
  }
`;
type StyledCalulatorTableColType = StyledComponent<
  typeof TableCol,
  any,
  React.ComponentProps<typeof TableCol>,
  never
>;

export const StyledCalulatorTableCol: StyledCalulatorTableColType = styled(
  TableCol,
)`
  &&&&& {
    border: 0;
    padding: 8px 40px 8px 0px;
    &:first-child {
      margin-top: 0rem;
    }
  }
`;

type StyledCalulatorTableRowType = StyledComponent<
  typeof TableRow,
  any,
  React.ComponentProps<typeof TableRow>,
  never
>;

export const StyledCalulatorTableRow: StyledCalulatorTableRowType = styled(
  TableRow,
)`
  &&&&& {
    &:not(:first-child)::before {
      border: 0;
    }
  }
`;
