import { useEffect } from 'react';

import { useLocation } from '@interstellar/react-app-routing';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const domId = hash.replace("#", "");
      document.getElementById(domId)?.scrollIntoView();
    }else {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [pathname, hash]);

  return null;
}

export default ScrollToTop;
