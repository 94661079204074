import React, { useEffect, useState } from 'react';

import { Accordion } from '@constellation/core';
import { useLocation } from '@interstellar/react-app-routing';

export type TaggedAccordionProps = React.ComponentProps<typeof Accordion>;

export function TaggedAccordion({
  children,
  open,
  id,
  ...rest
}: TaggedAccordionProps): React.JSX.Element {

  const [accordionOpen, setAccordionOpen] = useState(open);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && hash === `#${id}`) {
      setAccordionOpen(true);
    }
  }, [hash, id]);

  return (
    <Accordion
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      id={id}
      data-isaccordion
      open={accordionOpen}
      onChange={(isOpen: boolean) => {
        setAccordionOpen(isOpen);
      }}
    >
      {children}
    </Accordion>
  );
}
