import React from 'react';

import { Heading, Main, Strong } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import houseOutline from 'assets/common/House_white_CMYK.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import GradientBox from 'client/components/gradientBox/GradientBox';
import MetaTags from 'client/components/metaTags/MetaTags';
import { NavigationTiles } from 'client/components/navigationTiles';

import { PlacingBusinessContent } from './PlacingBusiness.config';

function PlacingBusinessPage(): React.JSX.Element {
  const { pageTitle, bannerSubheader, navigationTiles, metaContent } =
    useContent<PlacingBusinessContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <GradientBox>
        <ContentBox
          imgSrc={houseOutline}
          leftAlignImage={false}
          imgWidth="200px"
          marginBottom="none"
          marginTop="none"
          marginLeft="auto"
          marginRight="auto"
          imgAlt="Icon of a house"
        >
          <Heading as="h1" size="s7" inverse>
            {pageTitle}
          </Heading>
          <Strong inverse>{bannerSubheader}</Strong>
        </ContentBox>
      </GradientBox>
      <NavigationTiles
        navigationTiles={navigationTiles}
        pageTestId="placing-business-page-navigation-tiles"
      />
    </Main>
  );
}

export default PlacingBusinessPage;
