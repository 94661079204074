import { Grid, Accordion } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledGrid: StyledComponent<typeof Grid, any> = styled<
  typeof Grid
>(Grid)`
  &&& {
    margin: 0 auto;
    max-width: 75rem;
  }
`;

export const StyledAccordion: StyledComponent<typeof Accordion, any> = styled<
  typeof Accordion
>(Accordion)`
  &&& {
    border-radius: 0;
  }

  // hide last hr in mobile navigation menu
  span > div:last-child hr {
    display: none;
  }
`;

export const MobileMenuWidth = 786;
const SmallerTextWidth = 870;

export const StyledNavBox = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 75rem;

    padding: ${theme.spacing_05} ${theme.spacing_02};

    @media (min-width: ${theme.breakpoint_md}) {
      padding: ${theme.spacing_05} ${theme.spacing_05};
    }

    @media (min-width: ${SmallerTextWidth}px) {
      padding: ${theme.spacing_05} ${theme.spacing_03};
    }
`,
);

export const StyledNavBoxItem = styled.div(
  ({ theme }) => `
    display: flex;
    margin-right: ${theme.spacing_04};
    white-space: nowrap;

    &&& a > span {
      font-size: ${theme.typography_text_font_size_s1};
    }

    @media (min-width: ${SmallerTextWidth}px) {
      &&& a > span {
        font-size: ${theme.typography_text_font_size_s2};
      }
    }
`,
);
