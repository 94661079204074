import { NavigationMenuContent } from 'client/components/navigationMenu/NavigationMenu.config';

import * as routes from '../../routes/manifest';

const navigationMenu: NavigationMenuContent = {
  menuTitle: 'Menu',
  menuItems: [
    {
      label: 'Our products',
      Url: 'https://www2.bmsolutionsonline.co.uk/tools/products/product-finder',
      external: true,
    },
    {
      label: 'Our criteria',
      Url: routes.OurCriteria,
    },
    {
      label: 'Calculators',
      Url: routes.Calculators,
    },
    {
      label: 'Placing business',
      Url: routes.PlacingBusiness,
    },
    {
      label: 'Resources',
      Url: routes.Resources,
    },
    {
      label: 'Sustainability',
      Url: routes.SustainabilityHub,
    },
    {
      label: 'Articles',
      Url: routes.Articles,
    },
    {
      label: 'Contact us',
      Url: routes.ContactUs,
    },
  ],
};

export default navigationMenu;
