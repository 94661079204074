import React from 'react';

import { Box } from '@constellation/core';

import { StyledGradientBox } from './GradientBox.styled';

type BoxProps = React.ComponentProps<typeof Box>;

interface GradientBoxProps extends Pick<BoxProps, 'bgColor'> {
  children: any;
}

function GradientBox({
  children,
}: GradientBoxProps): React.JSX.Element {
  return <StyledGradientBox >{children}</StyledGradientBox>;
}

export default GradientBox;
