import { Container, ContentGroup } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledHeroImage = styled.img`
  width: 100%;
  height: 340px;
  clip-path: inset(0px 0 00px 0);
  object-position: center calc(100% - 25%);
  object-fit: cover;
`;

export const StyledGradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 340px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.68), rgba(0, 0, 0, 0));
`;

export const StyledHeroContainer: StyledComponent<typeof Container, any> =
  styled<typeof Container>(Container)`
    position: relative;
    height: auto;
  `;

export const StyledHeroOverlayText: StyledComponent<typeof Container, any> =
  styled<typeof Container>(Container)`
    position: absolute;
    top: 50%;
  `;

export const StyledContentGroup: StyledComponent<typeof ContentGroup, any> =
  styled<typeof ContentGroup>(ContentGroup)`
    position: fixed;
    top: 25vw;
  `;
