import React from 'react';

import BoxProps from '@constellation/core/dist/types/components/Box/box.types';

import { StyledHeroImageBox } from './HeroImageBox.styled';

interface HeroImageBoxProps {
  children: any;
  bgColor?: BoxProps['bgColor'];
}

function HeroImageBox({
  children,
  bgColor,
}: HeroImageBoxProps): React.JSX.Element {
  return <StyledHeroImageBox bgColor={bgColor}>{children}</StyledHeroImageBox>;
}

export default HeroImageBox;
