export const Home = '/';
export const Resources = '/resources';
export const OurCriteria = '/our-criteria';
export const Calculators = '/calculators';
export const PlacingBusiness = '/placing-business';
export const SustainabilityHub = '/sustainability';
export const TermsAndConditions = '/terms';
export const Accessibility = '/accessibility';
export const Privacy = '/privacy';
export const Cookies = '/cookies';
export const ContactUs = '/contact-us';
export const ExistingCustomers = '/contact-us/existing-customers';
export const NewBusinessEnquiries = '/contact-us/new-business-enquiries';
export const Complaints = '/contact-us/complaints';
export const IntermediaryServiceSupport =
  '/contact-us/intermediary-service-support';
export const BDMSearch = '/contact-us/bdm-search';
export const SubmittedMortgageApplications =
  '/contact-us/submitted-mortgage-applications';
export const EpcRegulations =
  '/sustainability/current-and-proposed-epc-regulations';
export const LowerRatesForEnergyEfficientHomes =
  '/sustainability/lower-rates-for-energy-efficient-homes';
export const GrantsAndSchemes = '/sustainability/grants-and-schemes';
export const LandlordRetrofitTool = '/sustainability/landlord-retrofit-tool';
export const EducationalHub = '/sustainability/educational-hub';

export const FreeConveyancingService = '/contact-us/free-conveyancing-service';
export const EpcStepByStepGuide = '/sustainability/epc-step-by-step-guide';
export const ProductTransfers = '/placing-business/product-transfers';
export const FurtherAdvances = '/placing-business/further-advances';
export const TransferOrPurchaseOfEquity =
  '/placing-business/transfer-or-purchase-of-equity';
export const SubmittingBtlOrLtbMortgageApplication =
  '/placing-business/submitting-btl-ltb-mortgage-application';
export const Porting = '/placing-business/porting';
export const Fees = '/placing-business/fees';
export const DocumentUpload = '/placing-business/document-upload';
export const RateChangeCalculator = '/calculators/rate-change-calculator';
export const Security = '/security';
export const Articles = '/articles';
export const Register = '/register';
export const Sitemap = '/sitemap';
export const ArticleImprovingQualityBenefitsLandlordsAndTenants =
  '/articles/article-improving-quality-benefits-landlords-and-tenants';

// #pragma insert-page-route-definition
