export const ourCriteria = {
  heading: 'our-criteria-heading',
  sectionHeading: 'our-criteria-section-heading',
  sectionBody: 'our-criteria-section-body',
  property : {
    heading: 'our-criteria-property-heading',
    sectionHeading: 'our-criteria-section-property-heading',
    sectionBody: 'our-criteria-section-property-body',
  },
  lending : {
    heading: 'our-criteria-lending-heading',
    sectionHeading: 'our-criteria-section-lending-heading',
    sectionBody: 'our-criteria-section-lending-body',
  },
  topSlicing : {
    heading: 'our-criteria-top-slicing-heading',
    sectionHeading: 'our-criteria-section-top-slicing-heading',
    sectionBody: 'our-criteria-section-top-slicing-body',
  },
  existingCustomer : {
    heading: 'our-criteria-existing-customer-heading',
    sectionHeading: 'our-criteria-section-existing-customer-heading',
    sectionBody: 'our-criteria-section-existing-customer-body',
  }
};