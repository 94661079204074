import React from 'react';

import { Paragraph } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const complaints: AppContent['complaints'] = {
  metaContent: MetaContentValues.Complaints,

  pageTitle: 'Complaints',
  backButtonLabel: 'Back',
  backButtonHref: routes.ContactUs,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'complaints',
        sectionBody: [
          {
            text: 'If you have a complaint, we can help.',
          },
          {
            boldText:
              'We aim to offer you the best possible service, however we understand there may be times when you feel you have cause to complain. As soon as you get in touch we will do our very best to put things right to resolve the problem. If you need to complain you can contact us as follows:',
          },
          {
            list: [
              <Paragraph>
                If your complaint relates to an application in progress, please
                telephone{' '}
                <StyledLink
                  data-testid="complaint-helpline"
                  to="tel:0345 850 5000"
                >
                  0345 850 5000
                </StyledLink>{' '}
                or contact your Business Development Manager.
              </Paragraph>,
              <Paragraph>
                Existing customers should refer to the{' '}
                <StyledLink
                  external
                  data-testid="complaint-url"
                  to="https://www.bmmortgages.co.uk/complaints"
                >
                  Birmingham Midshires mortgages website
                </StyledLink>{' '}
                for further information.
              </Paragraph>,
            ],
          },
        ],
      },
    ],
  },
};

export default complaints;
