import { accessibility } from './accessibility';
import { calculatorsPage } from './calculators';
import { complaintsPage } from './complaints';
import { contactUsPage } from './contactUs';
import { disclaimer } from './disclaimer';
import { existingCustomers } from './existingCustomers';
import { footer } from './footer';
import { freeConveyancingService } from './freeConveyancingService';
import { homePage } from './homePage';
import { intermediaryServiceSupportPage } from './intermediaryServiceSupport';
import { lowerRatesForEnergyEfficientHomes } from './lowerRatesForEnergyEfficientHomes';
import { navigationMenu } from './navigationMenu';
import { ourCriteria } from './ourCriteria';
import {rateChangeCalculator} from './rateChangeCalculator'
import { resourcesPage } from './resources';
import { sustainabilityHubPage } from './sustainabilityHub';

export default {
  content: {
    container: 'content-container',
  },
  homePage,
  navigationMenu,
  resourcesPage,
  disclaimer,
  footer,
  existingCustomers,
  contactUsPage,
  ourCriteria,
  sustainabilityHubPage,
  intermediaryServiceSupportPage,
  complaintsPage,
  lowerRatesForEnergyEfficientHomes,
  calculatorsPage,
  rateChangeCalculator,
  accessibility,
  freeConveyancingService,
};
