import { Brand } from 'components/brandProvider';

import baseContent from './_base';
import { AppContent } from './AppContent';
import birminghamMidshiresContent from './birmingham-midshires';

interface AppContentResolverContext {
  brand: Brand;
}

export default ({ brand }: AppContentResolverContext): AppContent => {
  if (brand === 'birmingham-midshires') {
    return birminghamMidshiresContent;
  }
  return baseContent;
};
