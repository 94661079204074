import React from 'react';

import { Paragraph } from '@constellation/core';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const epcStepByStepGuide: AppContent['epcStepByStepGuide'] = {
  metaContent: MetaContentValues.EpcStepByStepGuide,
  pageTitle:
    'Step-by-step guide to supporting your customers with energy performance certificate (EPC) changes',
  backButtonLabel: 'Back',
  backButtonHref: routes.SustainabilityHub,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        isAccordion: false,
        sectionBody: [
          {
            text: "Landlords might need to take action to improve their properties' energy efficiency.",
          },
          { subheading: '1. Find out the EPC rating of their property' },
          {
            text: 'An EPC rating shows how energy efficient a property is. The rating varies from A-G, with A being the best.',
          },
          {
            textWithLinks: [
              'You or your customers can ',
              {
                displayText:
                  'check the EPC rating of a property in England, Wales, and Northern Ireland',
                external: true,
                href: 'https://www.gov.uk/find-energy-certificate',
              },
              ' or the ',
              {
                displayText: 'Scottish register',
                external: true,
                href: 'https://www.scottishepcregister.org.uk',
              },
              '  in Scotland.',
            ],
          },
          { subheading: '2. Check if their property is exempt' },
          {
            textWithLinks: [
              "Some properties don't need to comply with current minimum energy-efficiency standards. Landlords can find out if their property is ",
              {
                displayText: 'exempt and register an exemption',
                external: true,
                href: 'https://www.gov.uk/government/publications/private-rented-sector-minimum-energy-efficiency-standard-exemptions/guidance-on-prs-exemptions-and-exemptions-register-evidence-requirements',
              },
              '.',
            ],
          },
          {
            text: 'Rules on exemptions can change if new proposals come into force, so advise your customers to check back regularly.',
          },
          {
            subheading:
              "3. Pay for an EPC assessment if they don't have an up-to-date certificate",
          },
          {
            text: 'An EPC assessment will cost around £40-70 and lasts for 10 years.',
          },
          {
            text: "However, if a customer's had work done to their property since their last assessment, they should consider getting a new one to see if they've improved its EPC rating:",
          },
          {
            list: [
              <Paragraph>
                <StyledLink
                  external
                  to="https://www.gov.uk/get-new-energy-certificate"
                >
                  Find accredited EPC assessors in England, Wales and Northern
                  Ireland
                </StyledLink>
                .
              </Paragraph>,
              <Paragraph>
                <StyledLink
                  external
                  to="https://www.scottishepcregister.org.uk/assessorsearch"
                >
                  Find accredited EPC assessors in Scotland
                </StyledLink>
                .
              </Paragraph>,
            ],
          },
          {
            text: "Tenants should be given at least 24 hours' notice before any property visits, including the EPC assessment.",
          },
          {
            subheading: "4. Improve their property's EPC rating",
          },
          {
            text: 'EPCs provide lots of useful information, including the property rating and its potential rating if any suggested changes are made.',
          },
          {
            text: 'This could include adding or improving insulation, fitting double-glazed windows, upgrading lightbulbs, or getting a more efficient boiler, for example.',
          },
          {
            text: "Upgrading their property to meet EPC standards could be costly, but it's worth explaining the potential benefits to your customers.",
          },
          {
            text: 'It could add value to their property, increase their rental income and make it more attractive to tenants.',
          },
          {
            textWithLinks: [
              'Useful energy efficiency tips from the ',
              {
                displayText: 'Energy Saving Trust can be found here',
                external: true,
                href: 'https://energysavingtrust.org.uk/energy-at-home/',
              },
              '.',
            ],
          },
          {
            subheading:
              '5. Find tradespeople to carry out property improvements',
          },
          {
            text: 'Before starting any work, your customers should:',
          },
          {
            list: [
              <Paragraph>
                Check if they need approvals, such as planning permission or{' '}
                <StyledLink
                  external
                  to="https://www.gov.uk/building-regulations-approval"
                >
                  building regulations approval
                </StyledLink>
                . For Scotland, check if they need a{' '}
                <StyledLink
                  external
                  to="https://www.mygov.scot/building-standards-in-scotland/"
                >
                  building warrant
                </StyledLink>
                .
              </Paragraph>,
              <Paragraph>
                If they have tenants in the property, make sure they give them
                enough notice of any works and minimise disruption.
              </Paragraph>,
              <Paragraph>
                Shop around for tradespeople and get a few quotes{' '}
                <StyledLink
                  external
                  to="https://www.trustmark.org.uk/homeowner/find-a-tradesperson"
                >
                  Trustmark
                </StyledLink>{' '}
                is the government-endorsed scheme for UK tradespeople.
              </Paragraph>,
              <Paragraph>
                Keep all quotes, contracts, and receipts. They could count
                towards the cost cap if they need to register an exemption.
              </Paragraph>,
            ],
          },
          {
            subheading: '6. How to pay for property improvements',
          },
          {
            textWithLinks: [
              'Your customer might already have funds to cover improvements, and there might be grants available from government, ',
              {
                displayText: 'energy companies',
                external: true,
                href: 'https://www.ofgem.gov.uk/environmental-and-social-schemes/energy-company-obligation-eco/homeowners-and-tenants',
              },
              ', and other sources.',
            ],
          },
          {
            text: 'These links might be useful for your customers, but the schemes could change or expire:',
          },
          {
            list: [
              <Paragraph>
                <StyledLink
                  external
                  to="https://www.gov.uk/improve-energy-efficiency"
                >
                  Find Ways to Save Energy in Your Home
                </StyledLink>{' '}
                for England and Wales.
              </Paragraph>,
              <Paragraph>
                <StyledLink external to="https://www.homeenergyscotland.org/">
                  Home Energy Scotland
                </StyledLink>
                ,{' '}
                <StyledLink
                  external
                  to="https://www.homeenergyscotland.org/warmer-homes-scotland"
                >
                  {' '}
                  Warmer Homes Scotland
                </StyledLink>{' '}
                and the
                <StyledLink
                  external
                  to="https://www.homeenergyscotland.org/landlord-loan"
                >
                  {' '}
                  Private rented sector landlord loan in Scotland
                </StyledLink>
                .
              </Paragraph>,

              <Paragraph>
                Northern Ireland Housing Executive&apos;s
                <StyledLink
                  external
                  to="https://www.nihe.gov.uk/housing-help/affordable-warmth/affordable-warmth-scheme"
                >
                  {' '}
                  Affordable warmth scheme
                </StyledLink>
                .
              </Paragraph>,

              <Paragraph>
                The Welsh Government&apos;s{' '}
                <StyledLink
                  external
                  to="https://www.gov.wales/get-help-energy-efficiency-your-home-nest"
                >
                  Nest Scheme
                </StyledLink>
                .
              </Paragraph>,

              <Paragraph>
                The{' '}
                <StyledLink
                  external
                  to="https://www.gov.uk/energy-company-obligation"
                >
                  Energy company obligation scheme
                </StyledLink>{' '}
                for tenants operates in England, Scotland and Wales.
              </Paragraph>,

              <Paragraph>
                The{' '}
                <StyledLink
                  external
                  to="https://www.gov.uk/apply-great-british-insulation-scheme"
                >
                  Great British Insulation Scheme
                </StyledLink>{' '}
                provides free or cheaper insulation for properties England,
                Scotland and Wales.
              </Paragraph>,
            ],
          },
          {
            subheading: '7. Borrowing to fund property improvements',
          },
          {
            text: 'Many landlords will need to borrow to fund energy-efficient home improvements, using a further advance or remortgage, for example.',
          },
          {
            text: 'You could be best-placed to help them find the right borrowing solution for their needs. Lenders are designing green mortgage products to support landlords who want to raise funds to boost the energy efficiency of their property.',
          },
          {
            text: "Speak to your landlord clients to check they're aware of the proposed changes and understand their borrowing options to raise funds to cover property improvements.",
          },
          {
            subheading: '8. Check their new EPC rating',
          },
          {
            text: "Remind your customer to get a new EPC assessment once they've made improvements to their rental property.",
          },
          {
            subheading: 'Help for you',
          },
          {
            textWithLinks: [
              {
                displayText: 'Speak to your BDM',
                external: false,
                href: routes.BDMSearch,
              },
              ' if you have any questions.',
            ],
          },
        ],
      },
    ],
  },
};

export default epcStepByStepGuide;
