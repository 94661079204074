import React from 'react';

import { Paragraph, IconCross, IconTick } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { StyledText } from 'client/components/styledText/StyledText';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const porting: AppContent['porting'] = {
  metaContent: MetaContentValues.Porting,
  pageTitle: 'Porting',
  backButtonLabel: 'Back',
  backButtonHref: routes.PlacingBusiness,

  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        isAccordion: false,
        sectionBody: [
          {
            component: (
              <Paragraph marginBottom="02">
                <IconTick iconSize="md" color="success" size="s4" />
                <StyledText marginLeft="02" verticalAlign="middle">
                  You can port a product to a new BTL purchase or remortgage an
                  unencumbered BTL property.
                </StyledText>
              </Paragraph>
            ),
          },
          {
            component: (
              <Paragraph marginBottom="02">
                <IconCross iconSize="md" color="warning" size="s4" />
                <StyledText marginLeft="02" verticalAlign="middle">
                  You can&apos;t port to a mortgaged property already owned by a
                  customer.
                </StyledText>
              </Paragraph>
            ),
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            component: (
              <Paragraph marginBottom="02">
                <IconCross iconSize="md" color="warning" size="s4" />
                <StyledText marginLeft="02" verticalAlign="middle">
                  Existing let to buy mortgages aren&apos;t portable.
                </StyledText>
              </Paragraph>
            ),
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: "Please choose the scenario that meets your customers' circumstances and follow the corresponding process.",
          },
          { subheading: 'Scenario 1' },
          {
            text: "The mortgage the customer is porting the product from hasn't been redeemed before the application, and there are more than three months left until the end of the current product term. Or there are less than three months left until the end of the product term and the customer has chosen to port the product.",
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            text: "Following full application submission, completion and redemption of the current mortgage don't need to be simultaneous.",
          },
          {
            subheading: { displayText: 'Process', headingLevel: 'h3' },
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'Key full application.',
              "You'll need to select a 2-year fixed rate from the current range. To make sure the correct stress rate is applied, the 5-year stress rate only applies if the remaining product term at the point of application is 5 years or more.",

              <>
                Once the application has been submitted, upload a completed{' '}
                <StyledLink external to="/public/porting-request-form.pdf">
                  Porting request form
                </StyledLink>
                .
              </>,
              'Our Broker Support Team will produce the correct illustration once the product has been applied to the account.',
            ],
          },
          { subheading: 'Scenario 2' },
          {
            text: "The mortgage the customer is porting the product from hasn't been redeemed before the application, there are less than 3 months remaining on the current product, and the customer has chosen a product from the current range.",
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),

            text: "Following full application submission, completion and redemption of the current mortgage don't need to be simultaneous.",
          },
          { subheading: { displayText: 'Process', headingLevel: 'h3' } },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'Key full application and select the required product from the current range.',
              <>
                Once the application has been submitted, upload a completed{' '}
                <StyledLink external to="/public/porting-request-form.pdf">
                  Porting request form
                </StyledLink>
                .
              </>,
            ],
          },
          { subheading: 'Scenario 3' },
          {
            text: "The mortgage the customer is porting the product from has been redeemed before the application, the mortgage application is being submitted within three months of redemption of the existing mortgage, and the customer has chosen a product from the current range. There is no option to keep the existing product in this scenario.",
          },
          {
            subheading: { displayText: 'Process', headingLevel: 'h3' },
          },
          {
            container: contentSectionBox({ marginBottom: '08' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'Key full application and select the required product from the current range.',
              <>
                Once the application has been submitted, upload a completed{' '}
                <StyledLink external to="/public/porting-request-form.pdf">
                  Porting request form
                </StyledLink>
                .
              </>,
            ],
          },
          { subheading: 'Guidance' },
          {
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              "Where the product is ported, the borrowing amount can't exceed the current balance or maximum product LTV.",
              'Any additional borrowing must be taken on a product from the range available at the time of application.',
              "For the rental income calculation, we'll use the highest interest rate where there's more than one product.",
              'Any 5-year preferential stress rate will only apply where the term on all products is 5 years or more at the time of application.',
              'ERCs will be payable on any amount of the original loan amount not ported.',
            ],
          },
        ],
      },
    ],
  },
};

export default porting;
