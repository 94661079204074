import React from 'react';

import {
  IconEmail,
  IconGlobe,
  IconHeadset,
  Link,
  List,
  ListItem,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { BdmSearchContent } from '../BdmSearch.config';
import { StyledBox, StyledLink } from '../BdmSearchPage.styled';

function BdmResultBox({ result, testID }): React.JSX.Element {
  const { bdmResultsLabels } = useContent<BdmSearchContent>();
  const sortedPostcodes = result.postcodes.sort().join(' / ');
  const formattedTelephone = result.telephone.replace(' ', '');
  return (
    <StyledBox data-testid={testID} padding="none" marginBottom="05">
      <Strong size="s4">
        {result.name} - {result.role}
      </Strong>
      <List>
        <ListItem icon={<IconEmail iconSize="md" color="brand" trim />}>
          <Paragraph size="s2" marginTop="05" marginBottom="none">
            {bdmResultsLabels.email}
          </Paragraph>
          <StyledLink color="brand" href={`mailto:${result.email}`}>
            {result.email}
          </StyledLink>
        </ListItem>
        <ListItem icon={<IconHeadset iconSize="md" color="brand" trim />}>
          <Paragraph size="s2" marginTop="05" marginBottom="none">
            {bdmResultsLabels.telephone}
          </Paragraph>
          <Link color="brand" href={`tel:${formattedTelephone}`}>
            {result.telephone}
          </Link>
        </ListItem>
        <ListItem icon={<IconGlobe iconSize="md" color="brand" trim />}>
          <Paragraph size="s2" marginTop="05" marginBottom="none">
            {bdmResultsLabels.postcodes}
          </Paragraph>
          <Strong>{sortedPostcodes}</Strong>
        </ListItem>
      </List>
    </StyledBox>
  );
}

export default BdmResultBox;
