import { pxToRem } from '@constellation/core';
import NumberFieldProps from '@constellation/core/dist/types/components/NumberField/numberfield.types';
import styled, { css } from 'styled-components';

const buttonWidth = '3rem';

const StyledNumberFieldButtonCss = css`
  ${({ $disabled, $down, $error, $up, $valid, theme }: any) => css`
    align-items: center;
    background-color: ${theme.color_background_light_default_1};
    border: ${theme.border_width_default} solid
      ${theme.color_input_border_default};
    box-sizing: border-box;
    color: ${theme.numberfield_color};
    cursor: pointer;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 3rem;
    justify-content: center;
    outline: ${pxToRem(2)}rem solid transparent;
    outline-offset: -${pxToRem(2)}rem;
    top: 0;
    transition-duration: ${theme.transition_duration_default};
    transition-property: border-color, box-shadow;
    transition-timing-function: ${theme.transition_easing_standard_css};
    width: ${buttonWidth};
    &:focus {
      ${!$valid && `border-color: ${theme.color_border_default_1}`};
      box-shadow: ${theme.input_box_shadow_focus_css};
      z-index: 3;
    }
    ${!$disabled &&
    css`
      &:hover {
        border-color: ${theme.color_input_border_hover};
      }
    `}
    ${$disabled &&
    css`
      background-color: ${theme.color_background_light_default_3};
      border-style: ${theme.input_border_style_disabled_css};
      color: ${theme.color_text_subdued};
      cursor: not-allowed;
    `}
    ${$down &&
    css`
      border-radius: ${theme.numberfield_border_radius_down};
      margin-right: -${theme.border_width_default};
      z-index: 1;
    `}
    ${$error &&
    css`
      border-color: ${theme.color_border_critical};
    `}
    ${$up &&
    css`
      border-radius: ${theme.numberfield_border_radius_up};
      margin-left: -${theme.border_width_default};
    `}
    
    ${$valid &&
    css`
      & {
        border-color: ${theme.color_border_success};
      }
    `}
  `}
`;

interface StyledNumberFieldButtonProps {
  $disabled: NumberFieldProps['disabled'];
  $down?: boolean;
  $error: NumberFieldProps['error'];
  $up?: boolean;
  $valid: NumberFieldProps['valid'];
}

export const StyledNumberFieldButton = styled.button<StyledNumberFieldButtonProps>`
  ${({ theme }: any) => theme.cssPrefix} {
    ${StyledNumberFieldButtonCss}
  }
`;

const StyledNumberFieldInputCss = css`
  ${({ $valid, theme }: any) => css`
    background-color: ${theme.color_background_light_default_1};
    border: ${theme.border_width_default} solid
      ${theme.color_input_border_default};
    box-sizing: border-box;
    color: ${theme.color_text_default_1};
    font-family: ${theme.typography_text_font_family_css};
    font-size: ${theme.typography_font_size_s2};
    line-height: ${theme.typography_line_height_s2_css};
    outline: ${pxToRem(2)}rem solid transparent;
    outline-offset: -${pxToRem(2)}rem;
    padding: calc(${theme.spacing_03} - ${theme.border_width_default})
      calc(${theme.spacing_04} - ${theme.border_width_default});
    text-align: center;
    transition-duration: ${theme.transition_duration_default};
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ${theme.transition_easing_standard_css};
    max-width: calc(
      ${theme.input_width_default} - (2 * ${buttonWidth}) +
        (2 * ${theme.border_width_default})
    );
    width: 100%;
    &::-ms-clear {
      display: none;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      background-color: ${theme.color_background_light_default_3};
      border-style: ${theme.input_border_style_disabled_css};
      color: ${theme.color_text_subdued};
      cursor: not-allowed;
    }
    &:focus {
      ${!$valid && `border-color: ${theme.color_border_default_1}`};
      box-shadow: ${theme.input_box_shadow_focus_css};
      z-index: 1;
    }
    ${$valid &&
    css`
      & {
        border-color: ${theme.color_border_success};
      }
    `}
    &[aria-invalid="true"] {
      border-color: ${theme.color_border_critical};
    }
    &:hover:not(:disabled) {
      border-color: ${theme.color_input_border_hover};
      z-index: 2;
    }
  `}
`;

interface StyledNumberFieldInputProps {
  $valid: NumberFieldProps['valid'];
}

export const StyledNumberFieldInput = styled.input<StyledNumberFieldInputProps>`
  ${({ theme }: any) => theme.cssPrefix} {
    ${StyledNumberFieldInputCss}
  }
`;

export default styled.div`
  ${({ theme }: any) => theme.cssPrefix} {
    display: flex;
    width: ${({ theme }: any) => theme.numberfield_width_css};
  }
`;
