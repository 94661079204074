import React from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import InfoPanel from 'client/components/infoPanel/InfoPanel';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import testIds from 'client/testIds';

import { IntermediaryServiceSupportContent } from './IntermediaryServiceSupport.config';
import * as routes from '../manifest';

function IntermediaryServiceSupportPage(): React.JSX.Element {
  const { metaContent, pageHeading, infoPanel } =
    useContent<IntermediaryServiceSupportContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink
          iconPosition="left"
          data-testid="back-button-link"
          to={routes.ContactUs}
          marginRight="04"
        >
          Back
        </StyledLink>
        <Heading
          as="h1"
          size="s7"
          marginTop="03"
          marginBottom="03"
          data-testid={testIds.intermediaryServiceSupportPage.heading}
        >
          {pageHeading}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <InfoPanel
              infoPanel={infoPanel}
              pageTestIds={testIds.intermediaryServiceSupportPage}
            />
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}

export default IntermediaryServiceSupportPage;
