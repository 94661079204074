import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const termsAndConditions: AppContent['termsAndConditions'] = {
  metaContent: MetaContentValues.TermsAndConditions,
  pageTitle: 'BM Solutions Intermediaries Online Website Conditions',

  infoPanel: {
    contentSections: [
      {
        sectionID: 'general-section',
        isAccordion: false,
        sectionBody: [
          {
            text: 'Please take the time to go through the important information that is relevant to the mortgage you are arranging for your clients.',
          },
          {
            text: 'By transacting business on this website you agree to abide by the terms and conditions available here:',
          },
          {
            textWithLinks: [
              {
                displayText: 'Website terms and conditions (PDF, 223KB)',
                external: true,
                href: '/public/terms-conditions.pdf',
              },
            ],
          },
        ],
      },
    ],
  },
};

export default termsAndConditions;
