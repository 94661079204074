import React from 'react';

import { MetaContent } from 'client/content/MetaContent';
import { MetaContentValues } from 'client/content/MetaContentValues';
import Helmet from 'react-helmet';

interface MetaTagsProps {
  metaContent: MetaContent;
}

function MetaTags({ metaContent }: MetaTagsProps) {
  const { title, description } = metaContent;
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description ?? MetaContentValues.home.description}
      />
    </Helmet>
  );
}

export default MetaTags;
