import React from 'react';

import { Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import testIds from 'client/testIds';

import { StyledBox } from './Disclaimer.styled';
import { AppLayoutContent } from '../appLayout/AppLayout.config';
import { StyledGrid } from '../navigationMenu/NavigationMenu.styled';

export default function Disclaimer() {
  const {
    disclaimer: { title },
  } = useContent<AppLayoutContent>();

  return (
    <StyledBox bgColor="none" marginBottom="none">
      <StyledGrid>
        <Paragraph
          marginBottom="none"
          size="s1"
          data-testid={testIds.disclaimer.title}
        >
          {title}
        </Paragraph>
      </StyledGrid>
    </StyledBox>
  );
}
