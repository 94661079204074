import React, { ReactNode, useMemo } from 'react';

import { BrandProvider as ConstellationBrandProvider } from '@constellation/core';
import BirminghamMidshiresTheme from '@constellation/core/themes/birmingham-midshires';
import BlackHorseTheme from '@constellation/core/themes/black-horse';
import BosTheme from '@constellation/core/themes/bos';
import HalifaxTheme from '@constellation/core/themes/halifax';
import LloydsTheme from '@constellation/core/themes/lloyds';
import MbnaTheme from '@constellation/core/themes/mbna';
import ScottishWidowsTheme from '@constellation/core/themes/scottish-widows';

import { Brand } from './Brand';
import BrandContext from './BrandContext';
import GlobalStyle from './GlobalStyle';

type Theme = Record<string, unknown>;

const getTheme = (brand: Brand): Theme => {
  switch (brand) {
    case Brand.BOS: {
      return BosTheme;
    }
    case Brand.HALIFAX: {
      return HalifaxTheme;
    }
    case Brand.MBNA: {
      return MbnaTheme;
    }
    case Brand.SCOTTISH_WIDOWS: {
      return ScottishWidowsTheme;
    }
    case Brand.BIRMINGHAM_MIDSHIRES: {
      return BirminghamMidshiresTheme;
    }
    case Brand.BLACK_HORSE: {
      return BlackHorseTheme;
    }
    case Brand.LLOYDS:
    default: {
      return LloydsTheme;
    }
  }
};

function BrandProvider({
  children,
  brand,
}: {
  brand: Brand;
  children: ReactNode;
}): React.JSX.Element {
  const memoizedBrand = useMemo(() => ({ brand }), [brand]);

  return (
    <>
      <GlobalStyle />
      <ConstellationBrandProvider theme={getTheme(brand)}>
        <BrandContext.Provider value={memoizedBrand}>
          {children}
        </BrandContext.Provider>
      </ConstellationBrandProvider>
    </>
  );
}

export default BrandProvider;
