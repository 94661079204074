import React from 'react';

import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const transferOrPurchaseOfEquity: AppContent['transferOrPurchaseOfEquity'] = {
  metaContent: MetaContentValues.TransferOrPurchaseOfEquity,
  pageTitle: 'Transfer / purchase of equity',
  backButtonLabel: 'Back',
  backButtonHref: routes.PlacingBusiness,
  infoPanel: {
    contentSections: [
      {
        sectionID: 'main-section',
        sectionBody: [
          { subheading: 'Changing names on an existing BM Solutions mortgage' },
          {
            container: contentSectionBox({ marginBottom: '06' }),
            text: 'This will be treated as a new remortgage application with revised terms and conditions, and potentially different monthly payments.',
          },
          { subheading: 'Step 1 – product request' },
          {
            container: contentSectionBox({ marginBottom: '06' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              <>
                Before submitting the application, please complete the{' '}
                <StyledLink
                  external
                  to="https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-transfer-of-equity-new-product-request-form.html"
                >
                  Transfer of equity – product request form
                </StyledLink>
                .
              </>,
              "Once you've done this, we can product match to the product the customer already has with us.",
              'If the customer is on a standard variable rate (SVR), they can choose to stay on an SVR product. Or they can choose a new product from the Remortgage own conveyancer range.',
              "You'll need to complete the product request form on all applications with an LTV above 80% regardless of the current product rate.",
              'Our Broker Support Team will send your product code request by email.',
            ],
          },
          {
            subheading:
              'Step 2 – keying the application on BM Solutions Online',
          },
          {
            container: contentSectionBox({ marginBottom: '05' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'Please follow Step 1 before starting an application.',
              'The applicants will be the borrowers to remain on the account, along with anyone else to be added to the account.',
              "Select 'Remortgage own conveyancer' as the purchase type on the application.",
              "If your customer is also taking additional borrowing, select a new product from the current remortgage range for the additional funds only. Products with free conveyancing aren't available for this transaction type.",
            ],
          },
          {
            text: " In the Remortgage section, please select the 'Reason for remortgage' as one of the following:",
          },
          {
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'TOMP Only',
              'TOMP plus home improvements',
              'TOMP plus debt consolidation',
              'TOMP plus home improvements plus debt consolidation',
              'TOMP other.',
            ],
            container: contentSectionBox({ marginBottom: '05' }),
          },
          {
            text: "Please make sure the current lender selected from the dropdown list is 'Birmingham Midshires'.",
          },
          {
            boldText:
              'TOMP and debt consolidation should only be used if the application includes additional borrowing to repay unsecured debt.',
            container: contentSectionBox({ marginBottom: '06' }),
          },
          { subheading: 'Guidance' },
          {
            container: contentSectionBox({ marginBottom: '06' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'All applications will need to meet current lending criteria.',
              'The application will need an underwriter assessment.',
              'A new valuation will be carried out.',
              'A solicitor will be needed - any legal costs will be the responsibility of the applicants.',
            ],
          },
          { subheading: "We won't" },
          {
            container: contentSectionBox({ marginBottom: '06' }),
            listItemContainer: contentSectionBox({ marginBottom: '02' }),
            list: [
              'agree to transfer a mortgage from one sole named borrower to another.',
              'agree to a transfer of equity for a buy to let account not rented within our current buy to let criteria.',
            ],
          },
          { subheading: 'Additional information' },
          {
            textWithLinks: [
              "These aren't our only requirements. All applications will be subject to individual assessment. For further enquiries, please contact us on ",
              {
                displayText: '0345 850 5000',
                href: 'tel:03458505000',
                external: true,
              },
              ' or contact your ',
              {
                displayText: 'Business Development Manager',
                href: routes.BDMSearch,
              },
              '.',
            ],
          },
        ],
      },
    ],
  },
};

export default transferOrPurchaseOfEquity;
