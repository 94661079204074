import isTealiumAvailable from './isTealiumAvailable';
import { recursiveErrorCatchingProxy } from './recursiveErrorCatchingProxy';

declare global {
  interface Window {
    LBGAnalytics;
  }
}

let safeAnalytics;

const getSafeLBGAnalytics = () => {
  if (isTealiumAvailable() && !safeAnalytics) {
    safeAnalytics = recursiveErrorCatchingProxy(window.LBGAnalytics);

    safeAnalytics.data
      .setJourney('BMSolutions', '1.0.0')
      .setBrand('Birmingham Midshires')
      .setDivision('Retail')
      .setPresentation('Responsive')
      .setProduct('Mortgages', '')
      .setChannel('Online', undefined, undefined, false);
  }

  return safeAnalytics;
};

export default getSafeLBGAnalytics;
