import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const contactUs: AppContent['contactUs'] = {
  metaContent: MetaContentValues.contactUs,

  pageTitle: 'Contact us',
  pageText:
    'Need to get in touch with us about anything? Find out how on this page.',
  navigationTiles: [
    {
      linkText: 'New business enquiries',
      boxText: 'All the support you need with new applications.',
      url: routes.NewBusinessEnquiries,
      linkTestId: 'New business enquiries',
    },
    {
      linkText: 'Submitted mortgage applications',
      boxText: 'Want an update, or need to change something?',
      url: routes.SubmittedMortgageApplications,
      linkTestId: 'Submitted mortgage applications',
    },
    {
      linkText: 'Contact a BDM',
      boxText:
        'BDM contact is for use of mortgage intermediaries and other professionals only.',
      url: routes.BDMSearch,
      linkTestId: 'Contact a BDM',
    },
    {
      linkText: 'Existing customers',
      boxText: 'Get support for existing customers.',
      url: routes.ExistingCustomers,
      linkTestId: 'Existing customers',
    },
    {
      linkText: 'Intermediary service support',
      boxText: 'For technical, panel, and procuration fee queries.',
      url: routes.IntermediaryServiceSupport,
      linkTestId: 'Intermediary service support',
    },
    {
      linkText: 'Free conveyancing service',
      boxText: 'Broker support when using our free conveyancing service firms.',
      url: routes.FreeConveyancingService,
      linkTestId: 'Free conveyancing service',
    },
    {
      linkText: 'Complaints',
      boxText: 'What to do if you have a complaint.',
      url: routes.Complaints,
      linkTestId: 'Complaints',
    },
  ],
};

export default contactUs;
