import React from 'react';

import { Hr, Link, Paragraph, Strong } from '@constellation/core';
import GradientBox from 'client/components/gradientBox/GradientBox';

import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';
import { MetaContentValues } from '../MetaContentValues';

const articleImprovingQualityBenefitsLandlordsAndTenants: AppContent['articleImprovingQualityBenefitsLandlordsAndTenants'] =
{
  backButtonLabel: 'Back',
  backButtonHref: routes.Articles,
  pageTitle:
    'Improving the quality of rental homes benefits landlords and tenants',
  metaContent:
    MetaContentValues.ArticleImprovingQualityBenefitsLandlordsAndTenantsPage,
  imgAltText: 'Head of BM Solutions',
  imgLine1: 'Leigh Church',
  imgLine2: 'Head of BM Solutions',
  infoPanel: {
    contentSections: [
      {
        sectionID: 'article-section-1',
        isAccordion: false,
        sectionHeading: '',
        headingLevel: 'h2',
        sectionBody: [
          {
            component: (
              <GradientBox>
                <Paragraph inverse data-testid="article-gradient-header">
                  <Strong size="s4" inverse>
                    One in five households (19%) live in the private rented
                    sector (PRS), a figure that has stayed constant for the
                    last four years.
                  </Strong>
                </Paragraph>
                <Paragraph inverse data-testid="article-gradient-text1">
                  And every one of them deserves a good quality, warm home.
                </Paragraph>
                <Paragraph
                  inverse
                  data-testid="article-gradient-text2"
                >{`The vast majority of landlords provide exactly that, and many are highly motivated to improve their rental properties. In return for this investment, they know they'll attract better, long-term tenants and maximise their return.`}</Paragraph>
                <Paragraph inverse data-testid="article-gradient-text3">
                  Boosting the overall quality of homes in the PRS is good for
                  everyone.
                </Paragraph>
                <Strong
                  size="s4"
                  inverse
                  data-testid="article-gradient-text4"
                >
                  So, how are landlords doing?
                </Strong>
              </GradientBox>
            ),
          },
          {
            subheading: 'Making strides',
          },
          {
            text: <>The government's latest <Link href="https://www.gov.uk/government/collections/english-housing-survey-2022-to-2023-headline-report" external>English Housing Survey</Link>, published in December, highlighted some of the areas where landlords are making strides.</>
          },
          {
            subheading: 'Support for those receiving housing benefit',
          },
          {
            text: 'Landlords are now providing much-needed homes to a quarter (24%) of renters in receipt of housing benefit, up from 20% in 2018.',
          },

          {
            text: 'Historically many of these households would have lived in social housing but, with stock remaining low, the PRS plays a vital role.',
          },

          {
            text: "At BM Solutions, we welcome landlords who are supporting these renters and most lenders are happy with landlords letting to tenants in receipt of benefits (despite a persistent myth that it isn't allowed).",
          },
          { subheading: 'Energy-efficient housing' },
          {
            text: 'Over the last 10 years, the proportion of overall homes in the highest energy efficiency bands (A to C) increased from 19% to 48%.',
          },

          {
            text: 'Homes in the social rented sector saw the largest rise (36% to 70%) but the PRS saw an increase too, more than doubling from 20.2% to 44.8%.',
          },

          {
            text: 'Average Standard Assessment Procedure (SAP) ratings in the PRS rose from 57.2 in 2012 to 65.2 in 2022 (from a low to a high D rating). And, despite the cost of living crisis and rising mortgage rates, SAP ratings even inched up between 2021 and 2022 (from 64.9 to 65.2).',
          },

          {
            text: 'Landlords are doing a good job of providing more energy-efficient properties in the PRS, and we expect and encourage this trend to continue.',
          },

          {
            subheading: 'Room for improvement',
          },
          {
            text: "It's not all good news. The cost of living crisis has inevitably impacted both landlords and tenants and the latest figures on damp and mould prevalence in the PRS reveal a small slide backwards.",
          },

          {
            text: 'Despite landlords having made improvements to energy efficiency, 9% of homes in the PRS now have damp compared to 7% in 2019.',
          },

          {
            text: 'This compares unfavourably to the 5.4% of social housing and 2.3% of owner-occupied homes with damp issues.',
          },

          {
            text: "However, it's not necessarily down to poor maintenance from landlords. Inflation has disproportionately affected tenants, with average rents and energy costs having soared. Some instances of damp are likely due to tenants being unable to afford to adequately heat their homes.",
          },

          {
            text: <>We know that damp and mould have a significant impact on wellbeing. According to the Parliamentary research briefing, <Link href='https://commonslibrary.parliament.uk/research-briefings/cbp-9696/' external>Health Inequalities: Cold and Damp Homes</Link>, they can lead to worsening asthma and increase the risk of heart disease.</>,
          },

          {
            text: 'They also impact mental health, with depression and anxiety more common among people living in damp homes.',
          },
          {
            text: 'The English Housing Survey reflected this, showing that private renters score lower than owner-occupiers across its range of wellbeing measures.',
          },
          { subheading: 'What can brokers do?' },
          {
            text: 'For many landlords, damp and mould is a recurrent bugbear, demanding their time and money, and potentially negatively affecting the value of their property.'
          },
          {
            text: 'The data shows that landlords have made progress on boosting energy efficiency in the PRS, but the improvement trend has stalled, or reversed in other areas.'
          },
          {
            text: "However, you're well placed to help them."
          },
          {
            text: 'We all have a part to play in improving the quality of rented homes because it indirectly benefits us all. Plus we know that many of our broker partners are landlords themselves.'
          },
          {
            boldText: 'So, where do you start when it comes to supporting landlord clients?'
          },
          {
            text: <>Bring energy efficiency into your client conversations. Explain the benefits of improving their property's EPC rating, preventing damp and mould and making the property cheaper for their tenants to heat, and signpost them to useful information, such as the Tenancy Deposit Scheme's <Link external href="https://www.tenancydepositscheme.com/wp-content/uploads/2023/11/Damp-and-Mould-Guide.pdf">guide to preventing damp and mould</Link>.</>
          },
          {
            textWithLinks: [
              'Complete the CPD-accredited training module about EPC regulation on our ',
              {
                displayText: 'BM Solutions Sustainability Educational Hub',
                external: false,
                href: routes.EducationalHub,
              },
              '.',
            ],
          },
          {
            text: 'Speak to landlords about how they can fund any improvements they plan to make to their property to boost energy efficiency or reduce damp problems.'
          },
          {
            text: 'Give your clients information on any relevant grants they or their tenants could access to fund energy efficient improvements to their property, particularly if they have tenants in receipt of benefits.'
          },
          {
            boldText: 'Check out the following options:'
          },
          {
            list: [
              <><Link href="https://www.ofgem.gov.uk/environmental-and-social-schemes/energy-company-obligation-eco/homeowners-and-tenants" external>The Energy Company Obligation</Link> (England, Wales and Scotland)</>,
              <><Link href="https://www.homeenergyscotland.org/warmer-homes-scotland" external>Warmer Homes Scotland</Link> (Scotland)</>,
              <><Link href="https://www.gov.wales/get-help-energy-efficiency-your-home-nest" external>Nest Scheme</Link> (Wales)</>,
              <><Link href="https://www.nihe.gov.uk/housing-help/affordable-warmth/affordable-warmth-scheme" external>Affordable Warmth Scheme</Link> (Northern Ireland)</>
            ]
          },
          { subheading: 'Progressing together' },
          {
            text: "It's encouraging to see the improvements landlords have made to the energy efficiency of privately rented homes.",
          },
          {
            text: 'More efficient homes are warmer and cheaper to run, so they attract better, long-term tenants who can heat them adequately to prevent damp issues.',
          },
          {
            text: 'They can also better hold their value and potentially give landlords access to more competitive mortgage rates.',
          },
          {
            text: "It's a virtuous circle and an opportunity for you to deepen your relationships with your clients during 2024 and beyond, to discuss the benefits of improving the quality of their rental properties.",
          },
          {
            component: <Hr />,
          },
          {
            text: (
              <>
                <Strong>Here to help. </Strong>
                {`Reach out to your local BDM to understand how BM Solutions can support you and your landlord clients to improve the quality of the UK's rental properties.`}
              </>
            ),
          },
        ]
      }
    ]
  }
}

export default articleImprovingQualityBenefitsLandlordsAndTenants;
