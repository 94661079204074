import React, { ReactElement } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import bmSolutionsLogo from 'assets/common/bm-solutions.png';
import { ScreenProvider } from 'client/context/ScreenContext';
import AppLayout from 'components/appLayout/AppLayout';

import content from '../../content';
import Routes from '../../routes';
import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import useAppConfig from '../appConfig/useAppConfig';
import { BrandProvider, Brand } from '../brandProvider';
import ScrollToTop from '../scrollToTop/ScrollToTop';

function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;

  return (
    <BrandProvider brand={brand}>
      <ScreenProvider>
        <ContentProvider value={content({ brand }).appLayout}>
          <AppLayout logo={bmSolutionsLogo}>
            <ScrollToTop />
            <Routes />
          </AppLayout>
        </ContentProvider>
      </ScreenProvider>
    </BrandProvider>
  );
}

export default App;
