import React from 'react';

import { Heading, ListItem, Main, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';
import { getMetaTagContent } from 'client/handlers/getMetaTagContent';

import { SitemapContent } from './Sitemap.config';
import { StyledList } from './SitemapPage.styled';
import * as routes from '../manifest';

type MarginLeft =
  | 'none'
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '10';

function SitemapPage(): React.JSX.Element {
  const { metaContent, pageTitle } = useContent<SitemapContent>();
  const manifestFilePaths = Object.values(routes);

  const formatRouteLabel = (route: string) => {
    const onlyPageTitle = route.split('|')[0];
    return onlyPageTitle.trim();
  };

  const getMarginLeftValue = (path: string): MarginLeft => {
    const slashCount = path.split('/').length - 2;
    if (slashCount === 0) {
      return 'none';
    }
    const marginLeftValue = (slashCount * 3).toString().padStart(2, '0');
    return marginLeftValue as MarginLeft;
  };

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <Heading
        data-testid={`${pageTitle}-heading`}
        as="h1"
        size="s7"
        marginTop="05"
      >
        {pageTitle}
      </Heading>

      <StyledList>
        {[...manifestFilePaths].sort().map((path) => {
          let originalTitles = getMetaTagContent(path).title;

          originalTitles = formatRouteLabel(originalTitles);

          return (
            <ListItem key={path} icon={false}>
              <Paragraph
                marginBottom="none"
                marginLeft={getMarginLeftValue(path)}
                marginTop="none"
              >
                <StyledLink data-testid={`sitemap-route-${path}`} to={path}>
                  {originalTitles}
                </StyledLink>
              </Paragraph>
            </ListItem>
          );
        })}
      </StyledList>
    </Main>
  );
}

export default SitemapPage;
