import React, { ReactElement } from 'react';

import {
  ContentGroup,
  Heading,
  Grid,
  GridItem,
  Main,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';

import { NotFoundContent } from './NotFound.config';
import { StyledParagraph, StyledLink } from './NotFoundPage.styled';

export default function NotFoundPage(): ReactElement {
  const { metaContent, heading, subHeading, footer, footerLink, subFooter } =
    useContent<NotFoundContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />
      <ContentGroup>
        <Grid>
          <GridItem xs={5} />
          <GridItem xs={4}>
            <Heading as="h4">{heading}</Heading>
          </GridItem>
          <GridItem xs={3} />
        </Grid>
        <Grid>
          <GridItem xs={12}>
            <StyledParagraph>{subHeading}</StyledParagraph>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem xs={12}>
            <StyledParagraph>
              {footer} <StyledLink href="/">{footerLink}</StyledLink>
              {subFooter}
            </StyledParagraph>
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}
