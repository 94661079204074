import React from 'react';

import {
  Accordion,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import MetaTags from 'client/components/metaTags/MetaTags';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { NewBusinessEnquiriesContent } from './NewBusinessEnquiries.config';
import * as routes from '../manifest';

export default function NewBusinessEnquiriesPage(): React.JSX.Element {
  const { section1, accordion1, accordion2, accordion3, metaContent } =
    useContent<NewBusinessEnquiriesContent>();

  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <ContentGroup marginTop="05">
        <StyledLink iconPosition="left" to={routes.ContactUs}>
          Back
        </StyledLink>
        <Heading as="h1" marginTop="05" size="s6">
          {section1.heading}
        </Heading>
        <Grid>
          <GridItem sm={12} md={8}>
            <Paragraph>{section1.text}</Paragraph>
            <Accordion variation="inpage1" label={accordion1.label}>
              <StyledLink to={accordion1.link.href}>
                {accordion1.link.text}
              </StyledLink>
            </Accordion>
            <Accordion variation="inpage1" label={accordion2.label}>
              <StyledLink to={accordion2.link.href} external>
                {accordion2.link.text}
              </StyledLink>
            </Accordion>
            <Accordion variation="inpage1" label={accordion3.label}>
              <StyledLink to={accordion3.link.href}>
                {accordion3.link.text}
              </StyledLink>
            </Accordion>
          </GridItem>
        </Grid>
      </ContentGroup>
    </Main>
  );
}
