import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const rateChangeCalculator: AppContent['rateChangeCalculator'] = {
  metaContent: MetaContentValues.RateChangeCalculator,
  pageHeading: 'Rate change calculator',
  repaymentText: {
    firstLine: 'If the interest rate changed from',
    secondLine: 'to',
    thirdLine: 'the approximate change to your monthly payment would be:',
    fourthLine: 'per month',
    illustrativePurposes: 'These figures are for illustrative purposes only.',
  },
  mortgageBalanceFieldLabel: 'Mortgage balance',
  termLengthYearsFieldLabel: 'Years',
  termLengthMonthsFieldLabel: 'Months',
  interestRateFieldLabel: 'Interest rate',
  repaymentType: {
    repayment: 'Repayment',
    interestOnly: 'Interest only',
  },
  interestRateChangeFieldLabel: 'Interest rate change',
  termLengthHeading: 'Term length',
  errors: {
    title: "Something's not quite right",
    mortgageBalance: 'Please enter a value. Min £1 and max £15,000,000.',
    termLengthYears: 'Please enter a value. Min 0 and max 50.',
    termLengthMonths: 'Please enter a value. Min 0 and max 11 months.',
    interestRate: 'Please enter a value. Min 0.1% and max 15%.',
    interestRateChange: 'The combined interest rate must be greater than 0.1%',
  },
};

export default rateChangeCalculator;
