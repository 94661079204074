import { Box, GridItem } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const GridItemStretchChildren: StyledComponent<typeof GridItem, any> =
  styled<typeof GridItem>(GridItem)`
    display: flex;
    align-items: stretch;
  `;

export const FullWidthBox: StyledComponent<typeof Box, any> = styled<
  typeof Box
>(Box)`
  width: 100%;
`;

export const FullWidthArticleBox: StyledComponent<typeof Box, any> = styled<
  typeof Box
>(Box)`
  &&& {
    width: 100%;
    background-color: ${({ theme }) => theme.color_background_page_1};
    border-bottom: 1px solid ${({ theme }) => theme.color_border_default_2};
    border-radius: 0rem;
  }
`;
