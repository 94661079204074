import React from 'react';

import { Link } from '@constellation/core';
import { Link as ReactRouterLink } from 'react-router-dom';

import { StyledSpan } from './StyledLink.styled';
import { StyledLinkProps } from './StyledLink.types';

export function StyledLink({
  children,
  marginRight,
  ...rest
}: StyledLinkProps): React.JSX.Element {
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <StyledSpan marginRight={marginRight}>
      <Link as={ReactRouterLink} {...rest}>
        {children}
      </Link>
    </StyledSpan>
  );
}
