import { Box, GridItem } from '@constellation/core';
import styled, { StyledComponent } from 'styled-components';

export const StyledSustainabilityBox: StyledComponent<typeof Box, any> = styled<
  typeof Box
>(Box)`
  &&& {
    background-color: ${({ theme }) => theme.color_source_green_100};
  }
`;

export const FullWidthImage = styled.img`
  height: 155px;
  object-fit: cover;
  display: block;
`;

export const StyledGridItem = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export const StyledHomeContainer = styled.div`
  margin: 1.5rem;
`;
