import * as routes from 'client/routes/manifest';
import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const existingCustomers: AppContent['existingCustomers'] = {
  backButtonLabel: 'Back',
  backButtonHref: routes.ContactUs,
  metaContent: MetaContentValues.ExistingCustomers,
  section1: {
    heading: 'Existing customers',
    subheading: 'Updating info on an existing mortgage account',
    text1: 'Please call ',
    text1Tel: '0345 300 2627',
    text1TelHref: 'tel:03453002627',
    text2:
      "Lines are open Monday to Friday 8am to 8pm and Saturday 9am to 1pm.  We're not open Sundays or bank holidays.",
  },
  accordion1: {
    label: 'Converting customer account numbers',
    text: "Sometimes, our system and online forms need account numbers to have 14 digits. Here's a guide on how to convert account numbers into that format.",
  },
  accordion2: {
    label: 'If the account number has 12 digits',
    text: "Remove the forward-slash (/) and the dash (-) and replace them with '00' at the end.",
    exampleLabel: 'Example',
    exampleText: '60/02013198-10 converts to 60020131981000.',
  },
  accordion3: {
    label:
      'If the account number has 11 digits and only 1 digit after the dash',
    text: "Remove the forward-slash (/), replace the dash (-) with '0' and add '00' at the end.",
    exampleLabel: 'Example',
    exampleText: '60/02013198-1 converts to 60020131980100.',
  },
  accordion4: {
    label: 'If the account number has 11 digits and 2 digits after the dash',
    text: "Remove the dash (-), replace the forward-slash (/) with '0' and add '00' at the end.",
    exampleLabel: 'Example',
    exampleText: '60/2013198-01 converts to 60020131980100.',
  },
  accordion5: {
    label: 'If the account number has 10 digits',
    text: "Replace both the forward-slash (/) and dash (-) with '0' and add '00' at the end.",
    exampleLabel: 'Example',
    exampleText: '60/2013198-1 converts to 60020131980100.',
  },
  accordion6: {
    label: 'Paying customer ERCs so a product transfer can be keyed',
    text: 'If a customer has ERCs to pay on an unregulated buy to let or let to buy mortgage, please ',
    linkText: 'complete this form',
    linkHref:
      'https://www.bankofscotland.co.uk/mortgages/bm-solutions/pay-erc-pt.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
    importantLabel: 'Important to know',
    importantText:
      'Before completing this form, the customer will need to speak to our servicing team on ',
    importantTel: '0345 300 2627',
    importantTelHref: 'tel:03453002627',
  },
  accordion7: {
    label: 'Updating product transfers and further advances',
    text1:
      "If you've been asked to update an account to submit a product transfer or further advance, please ",
    linkText: 'complete this form',
    linkHref:
      'https://www.bankofscotland.co.uk/mortgages/existing-customers/account-update-submit-pt-fad.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
    subheading: 'If your customer has a buy to let flexible mortgage',
    text2:
      "You'll need to complete a scheme amendment form if want to submit a product transfer or further advance.",
    button: {
      text: 'Go to scheme amendment form',
      href: 'https://www.bankofscotland.co.uk/mortgages/existing-customers/bm-scheme-amendment-form.html?optoutmulti=0:0|c1:1|c3:0|c5:0|c4:0|c2:0&optmessage=1',
    },
  },
};

export default existingCustomers;
