import React from 'react';

import { Heading, Paragraph } from '@constellation/core';
import heroImage from 'assets/common/BMSolutions_homepage_hero_banner.jpg';
import { LinkButton } from 'client/components/linkButton';

import {
  StyledHeroImage,
  StyledHeroContainer,
  StyledHeroOverlayText,
  StyledGradientOverlay,
} from './HeroComponent.styled';

function HeroComponent({
  caption,
  subtext,
  btnLabel,
  btnHref,
  testId,
}): React.JSX.Element {
  return (
    <StyledHeroContainer padding="none">
      <StyledHeroImage
        alt="Row of houses"
        src={heroImage}
        data-testid={`${testId}-image`}
      />
      <StyledGradientOverlay />
      <StyledHeroOverlayText>
        <Heading
          as="h1"
          size="s4"
          marginBottom="none"
          inverse
          data-testid={`${testId}-heading`}
        >
          {caption}
        </Heading>
        <Paragraph
          size="s1"
          marginTop="01"
          marginBottom="05"
          inverse
          data-testid={`${testId}-subtext`}
        >
          {subtext}
        </Paragraph>
        <div style={{ maxWidth: '200px' }}>
          <LinkButton to={btnHref} testId={`${testId}-button`} external>
            {btnLabel}
          </LinkButton>
        </div>
      </StyledHeroOverlayText>
    </StyledHeroContainer>
  );
}

export default HeroComponent;
