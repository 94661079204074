import { AppContent } from 'content/AppContent';

import * as routes from '../../routes/manifest';
import { MetaContentValues } from '../MetaContentValues';

const newBusinessEnquiries: AppContent['newBusinessEnquiries'] = {
  metaContent: MetaContentValues.NewBusinessEnquiries,
  section1: {
    heading: 'New business enquiries',
    text: 'All the support you need with new applications.',
  },
  accordion1: {
    label: 'Checking criteria',
    link: {
      text: 'Go to customer criteria',
      href: routes.OurCriteria,
    },
  },
  accordion2: {
    label: 'Help keying a new application',
    link: {
      text: 'Keying hints & tips guide (PDF, 1.8MB)',
      href: '/public/bm-online-hints-tips.pdf',
    },
  },
  accordion3: {
    label: 'Contact a Business Development Manager',
    link: {
      text: 'Search for a Business Development Manager (BDM)',
      href: routes.BDMSearch,
    },
  },
};

export default newBusinessEnquiries;
