import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';
import { MetaContentValues } from '../MetaContentValues';

const sustainabilityHub: AppContent['sustainabilityHub'] = {
  metaContent: MetaContentValues.sustainabilityHub,

  pageTitle: 'Sustainability hub',
  pageText:
    'Want to help your customers make their homes more energy-efficient? Our sustainability Hub can tell you how to do it.',
  navigationTiles: [
    {
      linkText: 'Lower rates for energy-efficient homes',
      boxText:
        'An A, B or C Energy Performance Certificate (EPC) rating could get a lower fixed-rate.',
      url: routes.LowerRatesForEnergyEfficientHomes,
      linkTestId: 'Lower rates for energy-efficient homes',
    },
    {
      linkText: 'Current and proposed EPC regulations',
      boxText: 'Everything you need to know about EPCs, now and in the future.',
      url: routes.EpcRegulations,
      linkTestId: 'Current and proposed EPC regulations',
    },
    {
      linkText: 'Educational Hub',
      boxText:
        'Read our step-by-step guide to helping your customers understand their EPC ratings and what to do next.',
      url: routes.EducationalHub,
      linkTestId: 'Educational Hub',
    },
    {
      linkText: 'Grants and schemes',
      boxText: `We've put together info about some of the grants and schemes your customers or their tenants might be eligible for.`,
      url: routes.GrantsAndSchemes,
      linkTestId: 'Grants and schemes',
    },
    {
      linkText: 'Landlord Retrofit Tool',
      boxText:
        "Our Landlord Retrofit Tool is great for working out just how green your customers' properties are.",
      url: routes.LandlordRetrofitTool,
      linkTestId: 'Landlord Retrofit Tool',
    },
    {
      linkText: 'Step-by-step guide to EPC changes',
      boxText:
        'Step-by-step guide to supporting your customers with energy performance certificate (EPC) changes.',
      url: routes.EpcStepByStepGuide,
      linkTestId: 'Step-by-step guide to EPC changes',
    },
  ],
};

export default sustainabilityHub;
