import { ApplicationState } from '../types';
import getSafeLBGAnalytics from '../utils/getSafeLBGAnalytics';
import isTealiumAvailable from '../utils/isTealiumAvailable';

interface TealiumPageViewEvent {
  stepNumber: string;
  stepName: string;
  action?: string;
  actionNarrative?: string;
  applicationState?: ApplicationState;
}

/**
 * Logs a page view event with given parameters, should generally be used within a useEffect on the first render of a page component.
 * @param tealiumPageViewEvent - the analytics payload of the page view event.
 * @param tealiumPageViewEvent.stepNumber - JourneyStepNumber (v84) - The step number to use for this page. e.g. "1", "2" Passing undefined will inherit the previous step number. Can also provide an offset from the previous step number using - and + prefixes.
 * @param tealiumPageViewEvent.stepName - JourneyStepNumber (v85) - The name of the journey step, usually the page name.
 * @param tealiumPageViewEvent.action - JourneyAction (v73) - The action that happened, this could be an outcome on a decision or if an error occurred
 * @param tealiumPageViewEvent.actionNarrative - JourneyActionNarrative (v73) - The narrative of the action e.g. this could be the error that the customer is seeing
 * @param tealiumPageViewEvent.applicationState  - ApplicationState (v71) - The current state of the application e.g. "Pre-Application", "Application", "Completed", "Fulfilled", "Declined", "Referred"
 */
export function logTealiumPageViewEvent({
  stepNumber: journeyStep,
  stepName: journeyStepName,
  action: journeyAction = undefined,
  actionNarrative: journeyActionNarrative = undefined,
  applicationState = 'Pre-Application',
}: TealiumPageViewEvent) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics()
      .data.setJourneyStep(journeyStep, journeyStepName)
      .setJourneyStatus(journeyAction, journeyActionNarrative, applicationState)
      .done()
      .events.pageView()
      .send();
  }
}
