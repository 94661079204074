import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const sitemap: AppContent['sitemap'] = {
  metaContent: MetaContentValues.Sitemap,
  pageTitle: 'Sitemap',
};

export default sitemap;
