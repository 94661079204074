import React from 'react';

import { Paragraph, Strong } from '@constellation/core';
import { InfoPanelContent } from 'client/components/infoPanel/InfoPanel.config';
import { StyledLink } from 'client/components/styledLink/StyledLink';

import { anchors } from './anchors';

const topSlicing: {
  title: string;
  id: string;
  infoPanel: InfoPanelContent;
} = {
  title: 'Top slicing',
  id: anchors.topSlicing.id,
  infoPanel: {
    contentSections: [
      {
        sectionHeading: 'Minimum top slicing criteria',
        sectionID: anchors.topSlicing.minimumTopSlicingCriteria,
        isAccordion: true,
        sectionBody: [
          {
            list: [
              'Portfolio landlord excluded.',
              'Let to buy excluded.',
              'Minimum income £100k (first two applicants).',
              'Minimum stressed 125% RCR - below this, use the standard RCR calculation.',
              'Maximum stressed 145% RCR - Over this, use the standard RCR calculation.',
            ],
          },
          {
            boldText:
              'If a customer is within six years of anticipated retirement age or aged 70, whichever is the earliest, at the time of application',
          },
          {
            text: 'We can only consider lending on a top slicing basis if they are either in receipt of an acceptable pension income or be a joint applicant with someone who has sufficient income and isn’t within six years of retirement.',
          },
          {
            text: <Strong>Income and expenditure assessment</Strong>,
          },
          {
            component: (
              <Paragraph marginTop="05">
                Income included in affordability:
              </Paragraph>
            ),
          },
          {
            list: [
              'PAYE - basic, commission, bonus, and overtime.',
              'Self-employment.',
              'Pension.',
            ],
          },
          {
            component: (
              <Paragraph marginTop="05">
                Income excluded from affordability assessment:
              </Paragraph>
            ),
          },
          {
            list: [
              'Other income.',
              'Profit from UK land and property.',
              'Benefits.',
            ],
          },
          {
            component: (
              <Paragraph marginTop="05">
                Commitments used to calculate affordability:
              </Paragraph>
            ),
          },
          {
            list: [
              'Residential mortgages.',
              'Loans, credit cards, HP, etc.',
              'Financial dependents.',
              'School fees.',
              'Childcare costs.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Process',
        sectionID: anchors.topSlicing.process,
        isAccordion: true,
        sectionBody: [
          {
            text: (
              <>What to expect when you submit a Top slicing application.</>
            ),
          },
          {
            list: [
              <>
                Complete our{' '}
                <StyledLink
                  external
                  to="https://www2.bmsolutionsonline.co.uk/tools/rental-income-calculator"
                >
                  online calculator
                </StyledLink>{' '}
                {`and work out standard RCR calculation. If the application qualifies, the 'Top slicing' option and extra sections will be available to complete.`}
              </>,
              'The calculator results page will update with the new borrowing amount once the top slicing sections are completed.',
              'The system will calculate the lending decision using existing income and expenditure fields.',
              'All top slicing applications will need proof of income submitting and verifying.',
              "Any discrepancies between credit information and what's been keyed, might mean we'll need more information to make a decision.",
              'Any changes to an application, including valuation changes, will mean a reassessment. A different amount could be offered, including up to the standard rental cover ratio amount.',
              'An offer will be produced once income and expenditure has been verified and valuation is complete.',
            ],
          },
        ],
      },
      {
        sectionHeading: 'Income and affordability',
        sectionID: anchors.topSlicing.incomeAndAffordability,
        isAccordion: true,
        sectionBody: [
          {
            text: 'All sustainable income must be entered online.',
          },
          {
            text: 'All income evidence must be submitted and verified before an offer can be issued.',
          },
          {
            text: 'The following income will be used for our top slicing assessment.',
          },
          {
            component: (
              <Paragraph marginTop="05">
                <Strong>Employed</Strong>
              </Paragraph>
            ),
          },
          {
            basicTable: {
              ariaLabel:
                'A table showing how to asses employed income for top slicing assessment',
              testIdPrefix: 'top-slicing-income-assessment-table-test-id',
              width: 'auto',
              responsive: true,
              columns: [
                { displayText: 'Income Type', key: 'col-income-type' },
                { displayText: 'Main or Other', key: 'col-main-or-other' },
                { displayText: 'Key as', key: 'col-key-as' },
                { displayText: 'Evidence', key: 'col-evidence' },
              ],
              rows: [
                {
                  'col-income-type':
                    "Bank Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type':
                    'Basic Salary (including the probationary period of a permanent contract)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type':
                    'Bonus (These must not be keyed unless they have actually been paid)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Guaranteed Bonus',
                  'col-evidence':
                    "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
                },
                {
                  'col-income-type': 'Cash In Hand',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslip(s) and Bank Statement',
                },
                {
                  'col-income-type': 'CEA (Doctors Excellence Award)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Commission',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Regular / Guaranteed Commission',
                  'col-evidence':
                    "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
                },
                {
                  'col-income-type': 'Competency Related Threshold Payment',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Guaranteed Bonus',
                  'col-evidence':
                    "Latest 3 months' payslips (or for incomes received less frequently than monthly all payslips showing this income for the latest 2 years)",
                },
                {
                  'col-income-type': 'Danger Allowance',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Regular Overtime',
                  'col-evidence': 'Latest 3 months payslips',
                },
                {
                  'col-income-type': 'Delivery Supplement',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Dividends - For Company Director',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Self Employed',
                  'col-evidence':
                    'See Limited Company Director/Shareholder under Self Employed/Contractors section',
                },
                {
                  'col-income-type':
                    'Employed in a Family Business: If less than 25% invested interest and does not receive dividends or net profit as part of their reward package (Treat as employed)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence':
                    'Latest months payslip(s), and corresponding bank statement(s)',
                },
                {
                  'col-income-type': 'Foster Care Income',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Self Employed',
                  'col-evidence': 'Latest 2 years tax assessments',
                },
                {
                  'col-income-type':
                    "Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Leave of Absence',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Living Wage Allowance',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Long Service Pay',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Market Supplement',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type':
                    'Maternity Pay - providing the applicant will return to work on the same terms.',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence':
                    'Latest months payslip(s) received prior to commencement of maternity',
                },
                {
                  'col-income-type': 'NHS Banding',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Nursing Bank (Main Income)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest 3 months payslips',
                },
                {
                  'col-income-type': 'Occupational Sick Pay',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Overtime',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Regular Overtime',
                  'col-evidence': 'Latest 3 months payslips',
                },
                {
                  'col-income-type':
                    "Public Holiday Pay (only if it forms part of basic pay and doesn't inflate income)",
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Stipend (Only acceptable for Clergy)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Letter of confirmation from Religious Order',
                },
                {
                  'col-income-type':
                    'Teaching and Learning Responsibility (TLR)',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence': 'Latest months payslips',
                },
                {
                  'col-income-type': 'Tips',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Regular Overtime',
                  'col-evidence': 'Latest 3 months payslips',
                },
                {
                  'col-income-type': 'Tronc',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Annual Regular Overtime',
                  'col-evidence': 'Latest 3 months payslips',
                },
                {
                  'col-income-type':
                    'Zero Hours Contract (The customer must have been employed on a zero hours contract for a minimum of 12 months (with the same employer or with different employers but in the same type of work))',
                  'col-main-or-other': 'Main',
                  'col-key-as': 'Basic Annual Income',
                  'col-evidence':
                    "12 months' worth of payslips (this applies whether the applicant is paid weekly or monthly and could consist of P60 plus subsequent payslips to cover the full 12 month period)",
                },
              ],
            },
          },
          {
            component: (
              <Paragraph marginTop="05" data-testid="pension-subheading-ts">
                <Strong>Pension</Strong>
              </Paragraph>
            ),
          },
          {
            basicTable: {
              ariaLabel:
                'A table showing how to asses pension income for top slicing assessment',
              testIdPrefix:
                'top-slicing-pension-income-assessment-table-test-id',
              width: 'auto',
              responsive: true,
              columns: [
                { displayText: 'Income Type', key: 'col-income-type' },
                { displayText: 'Main or Other', key: 'col-main-or-other' },
                { displayText: 'Key as', key: 'col-key-as' },
                { displayText: 'Evidence', key: 'col-evidence' },
              ],
              rows: [
                {
                  'col-income-type':
                    'Armed Forces Independence Payments (AFIP)',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type': 'Fixed Annuity Payments (Private)',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type':
                    'HM Forces - Guaranteed Income Payment (GIP)',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type': 'Pension Tax Credits',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension Credit',
                  'col-evidence': 'Latest Bank Statement or Pension Statement',
                },
                {
                  'col-income-type':
                    'Permanent Health Insurance (PHI) The term must not extend past the final payment date for PHI if affordability is based on this income only',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type':
                    'Private Pension / Private Widowers Pension',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type': 'State Pension',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - State',
                  'col-evidence': 'Latest Bank Statement or Pension Statement',
                },
                {
                  'col-income-type':
                    'State Widows Pension. (Please see above if Bereavement Allowance).',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - State',
                  'col-evidence': 'Latest Bank Statement or Pension Statement',
                },
                {
                  'col-income-type': 'The Thalidomide Trust Income',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - Private',
                  'col-evidence':
                    'Latest Bank Statement (where paid gross) or Latest Pension Statement / Latest months payslip(s) or Pension P60',
                },
                {
                  'col-income-type': 'War Disablement Pension',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - War Disablement',
                  'col-evidence': 'Latest Bank Statement or Pension Statement',
                },
                {
                  'col-income-type': 'War Widow(er) Pension',
                  'col-main-or-other': 'Other',
                  'col-key-as': 'Pension - War Widow',
                  'col-evidence': 'Latest Bank Statement or Pension Statement',
                },
              ],
            },
          },
          {
            component: (
              <Paragraph
                marginTop="05"
                data-testid="self-employed/contractors-subheading-ts"
              >
                <Strong>Self-employed/contractors</Strong>
              </Paragraph>
            ),
          },
          {
            text: "All self-employed income must be entered in 'Net profit' to calculate the correct affordability. It's important to enter turnover and gross profit if the customer has them. If they don't have or know these figures, please enter £'0'.",
          },
          {
            text: 'For contractors who earn more than £500 per day or £75,000 a year, the gross value of their contract is calculated as either:',
          },
          {
            list: [
              'daily rate on the contract x 5 days per week x 46 weeks per year',
              'hourly rate x 7 hours per day x 5 days per week x 46 weeks per year.',
            ],
          },
          {
            text: 'If their contract states their hours are lower than these, please use the figures provided.',
          },
        ],
      },
    ],
  },
};

export default topSlicing;
