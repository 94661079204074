import { AppContent } from 'content/AppContent';

import { MetaContentValues } from '../MetaContentValues';

const security: AppContent['security'] = {
  metaContent: MetaContentValues.Security,
  pageTitle: 'Security',
  infoPanel: {
    contentSections: [
      {
        sectionID: 'intro-section',
        isAccordion: false,
        headingLevel: 'h2',
        sectionBody: [
          {
            textWithLinks: [
              'Bank Safe Online is a one-stop advice website which has been set up by APACS, the UK payments association. To keep up to date on online security issues, please visit ',
              {
                displayText: 'www.banksafeonline.org.uk',
                external: true,
                href: 'http://www.banksafeonline.org.uk/',
              },
              '.',
            ],
          },
        ],
      },
      {
        sectionID: 'our-guarantee-section',
        isAccordion: false,
        sectionHeading: 'Our guarantee',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'If a BM Solutions customer is a victim of fraud involving the Internet, we will guarantee that they will not lose any money as a result of the fraud, and we will reimburse them in full.',
          },
        ],
      },
      {
        sectionID: 'our-infomation-section',
        isAccordion: false,
        sectionHeading: 'How is my information kept secure on the internet?',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'The best way to ensure online security is to use the most recent version of a recommended Internet browser which offers the latest encryption technology. Currently the latest versions of Firefox and Microsoft Internet Explorer offer this technology.',
          },
        ],
      },
      {
        sectionID: 'encryption-section',
        isAccordion: false,
        sectionHeading: 'What is encryption?',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: "Encryption is a way of coding information to make it virtually impossible for someone else to read, unless they have the 'key' to decipher it.",
          },
          {
            text: 'The effectiveness of encryption is measured in bits (binary digits). The latest web browsers available from Microsoft and Firefox offer 128-bit encryption technology. BM Solutions cannot be used without 128-bit encryption - for your security and peace of mind.',
          },
        ],
      },
      {
        sectionID: 'SSL-section',
        isAccordion: false,
        sectionHeading: 'What does Secure Sockets Layer (SSL) mean?',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'SSL provides a secure communications link between your browser and BM Solutions. All information passed back and forth is encrypted. SSL is now supported by all major browsers and is generally accepted as a way of doing business securely over the internet.',
          },
        ],
      },
      {
        sectionID: 'protect-section',
        isAccordion: false,
        sectionHeading: 'How should I protect my security details?',
        headingLevel: 'h2',
        sectionBody: [
          {
            list: [
              'Never write them down.',
              'Never give them to anyone else.',
              'Never include them in an email.',
            ],
          },
          {
            text: 'In addition,',
          },
          {
            list: [
              'Never leave your PC unattended when logged in placing business, especially without a screen lock. We recommend that you log out if you leave your PC unattended.',
              "When logging in, make sure that no-one can see what you're typing.",
              'Always log out and close your browser when you have finished placing business.',
              'Never respond to emails that request you to reply with your security details, including those that appear to be from BM Solutions.',
              'Remember that protecting your UserID, passwords and username is your responsibility.',
            ],
          },
        ],
      },
      {
        sectionID: 'security-certificate-section',
        isAccordion: false,
        sectionHeading: 'Security certificate',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'When entering secure websites you should always check the security certificate and look out for warning messages. Double-clicking the padlock symbol displayed on your browser will tell you the level of security being used.',
          },
          {
            text: "However, changes to the technology used by VeriSign, the issuer of our website security certificate, means that older versions of Internet Explorer (version 4 and older versions of IE5) are unable to read updated certificates. An alert may appear suggesting that our security certificate is not valid - this is not true. If you choose to 'OK' the alert you will still be able to proceed and use the online banking service as normal.",
          },
          {
            textWithLinks: [
              "If you are getting this security alert then we suggest you update your browser to version IE6 or later to correct this problem. If you'd like to update your browser you can visit the ",
              {
                displayText: 'Microsoft Internet Explorer homepage',
                external: true,
                href: 'https://www.microsoft.com/en-gb/download/internet-explorer.aspx',
              },
              '. (Please note Birmingham Midshires is not responsible for the content of external websites.)',
            ],
          },
        ],
      },
      {
        sectionID: 'prevent-section',
        isAccordion: false,
        sectionHeading:
          'How can I help prevent someone else finding out my details?',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'When visiting the BM Solutions website always complete your task before leaving the site. Never leave your computer unattended while you are on the internet, especially when you are logged in. Always log out before leaving the site.',
          },
        ],
      },
      {
        sectionID: 'computer-virus-section',
        isAccordion: false,
        sectionHeading: 'How can I protect myself from computer viruses?',
        headingLevel: 'h2',
        sectionBody: [
          {
            text: 'We recommend that you use virus detection software to keep your computer free from known viruses.',
          },
        ],
      },
    ],
  },
};

export default security;
