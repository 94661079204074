import React from 'react';

import { Strong } from '@constellation/core';
import { contentSectionBox } from 'client/components/infoPanel/InfoPanel.config';

import * as routes from '../../routes/manifest';
import { AppContent } from '../AppContent';
import { MetaContentValues } from '../MetaContentValues';

const calculators: AppContent['calculators'] = {
  metaContent: MetaContentValues.calculators,
  pageTitle: 'Calculators',
  infoPanel1: {
    contentSections: [
      {
        sectionID: 'calculators',
        sectionBody: [
          {
            subheading: {
              displayText: 'Rental income calculator',
              size: 's4',
              headingLevel: 'h2',
            },
          },
          {
            text: 'Calculate the maximum amount of capital your customers can borrow to purchase or remortgage their property.',
          },
          {
            linkButton: {
              displayText: 'Rental income calculator',
              href: 'https://www2.bmsolutionsonline.co.uk/tools/rental-income-calculator',
              external: true,
            },
          },
          {
            container: contentSectionBox({ marginTop: '07' }),
            subheading: {
              displayText: 'Portfolio Calculator',
              size: 's4',
              headingLevel: 'h2',
            },
          },
          {
            text: "Check your customers' existing portfolio and new application fits our portfolio landlord criteria.",
          },
          {
            linkButton: {
              displayText: 'Portfolio Calculator',
              href: 'https://www2.bmsolutionsonline.co.uk/tools/portfolio-assessment-calculator',
              external: true,
            },
          },
          {
            container: contentSectionBox({
              marginTop: '07',
            }),
            subheading: {
              displayText: 'Rate Change Calculator',
              size: 's4',
              headingLevel: 'h2',
            },
          },
          {
            text: "Find out how much a change in the Bank of England (BoE) base rate will affect your customers' mortgage repayments.",
          },
          {
            linkButton: {
              displayText: 'Rate Change Calculator',
              href: routes.RateChangeCalculator,
              external: false,
            },
          },
          {
            container: contentSectionBox({ marginTop: '07' }),
            subheading: {
              displayText: 'How we make our calculations',
              size: 's4',
              headingLevel: 'h2',
            },
          },
          {
            text: 'Use these tables to work out the correct stress rate and rental cover ratio (RCR) to calculate the maximum borrowing available.',
          },
          {
            subheading: {
              displayText:
                'Purchase, let to buy, remortgage with capital raising and further advances*',
              size: 's3',
              headingLevel: 'h3',
            },
          },
        ],
      },
    ],
  },
  table1Data: {
    heading1: 'Product type',
    heading2: 'Stress rates (higher of)',
    rowData: [
      {
        column1: '2 year fixed or tracker',
        column2: '5.5% or pay rate + 2%',
      },
      {
        column1: '5 year fixed',
        column2: '5% or pay rate + 0.5%',
      },
    ],
  },
  paragraph:
    '*For further advances to qualify for 5 year stress rates, the total loan - including all existing balances - must be fixed for 5 years or more at point of application.',
  headingTwo: 'Like for like remortgage (BTL/LTB)',
  table2Data: {
    heading1: 'Product type',
    heading2: 'Stress rates (higher of)',
    rowData: [
      {
        column1: '2 year fixed or tracker',
        column2: '5.5% or pay rate + 0.5%',
      },
      {
        column1: '5 year fixed',
        column2: '5% or pay rate + 0.5%',
      },
    ],
  },
  table3Data: {
    heading1: `Customers' tax band`,
    heading2: 'RCR',
    rowData: [
      {
        column1: 'All customers are basic rate tax payers**',
        column2: '125%',
      },
      {
        column1:
          'One or more customers are higher/additional rate tax payers**',
        column2: '145%',
      },
    ],
  },
  infoPanel2: {
    contentSections: [
      {
        sectionID: 'info-panel-2',
        sectionBody: [
          {
            container: contentSectionBox({
              marginTop: '03',
            }),
            text: '** Higher/additional rate tax payers are customers whose total earned yearly income, plus 80% share of the gross rental income for all properties the customer will own on completion exceeds £50,271 or £43,663 Scotland.',
          },
          {
            subheading: {
              displayText: 'Calculations',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            text: 'Annual rent ÷ stress rate % ÷ RCR % = max available loan, including any products fees added.',
          },
          {
            subheading: {
              displayText:
                'How to work out maximum lending on a Further Advance',
              size: 's4',
              headingLevel: 'h2',
            },
          },
          {
            subheading: {
              displayText: 'Gather the required information',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            list: [
              'log into BM Solutions Online',
              'select Mortgage Applications and then Mortgage enquiry',
              "input the customer's account number and confirm you have consent to proceed",
              'click on sub accounts and note down current balance, interest rates and product end dates*',
              'then use the back button to go back to the summary of accounts',
              'select product finder tab, review the Further Advance products and choose the interest rate, taking note of the rate and product end dates.',
            ],
          },
          {
            text: (
              <>
                *<Strong>Exception</Strong> - If the customer has recently
                completed on a 5 year fixed rate product transfer with more than
                5 years remaining on the product before completion of the new
                further advance and they select a new 5 year fixed FAD product -
                in this scenario the stress rate will be the highest of the
                interest rates plus 0.5% subject to a minimum stress rate of 5%.
              </>
            ),
          },
          {
            subheading: {
              displayText: 'Work out the applicable Stress Rate',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            text: 'Add 2% to the highest of the interest rates from the selected new product or existing products - this is the stress rate to be used subject to a minimum stress rate of 5.5%.',
          },
          {
            subheading: {
              displayText: 'Work out the applicable Rental Cover Ratio',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            list: [
              <>
                If <Strong>any</Strong> individual customers total income is
                over £50,256 (£43,663 if they live in Scotland).
              </>,
              <>
                If <Strong>all</Strong> customers income is below the
                thresholds, then the Rental Cover ratio is 125%.
              </>,
              <>
                See above for what we use to calculate income for tax bandings.
              </>,
            ],
          },
          {
            subheading: {
              displayText:
                'Use the below calculation to work out the maximum borrowing',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            text: 'Annual rent ÷ stress rate % ÷ rental cover ratio % = total available lending including existing mortgage balances.',
          },
          {
            text: (
              <>
                Example - £12,000 (£1,000 per month rent) ÷ 7.5% (highest rate
                is 5.5% and existing mortgage has less than 5 years left on a
                fixed rate) ÷ 145% (on customer earns over £50,256) = £110,344
                minus any existing mortgage balance ={' '}
                <Strong>maximum further advance amount</Strong>.
              </>
            ),
          },
          {
            subheading: {
              displayText: 'Portfolio calculations',
              size: 's3',
              headingLevel: 'h3',
            },
          },
          {
            text: "Portfolio landlords' total portfolio, including this new property must achieve a minimum rental cover of 145%, stressed at 5.5%. It must also be no more than 75% LTV. The minimum joint yearly income allowed is £30,000.",
          },
          {
            textWithLinks: [
              {
                displayText:
                  'View our Portfolio landlord criteria page for more information',
                href: routes.OurCriteria,
                external: false,
              },
            ],
          },
          {
            boldText:
              'Total portfolio annual rent ÷ total portfolio mortgage balance ÷ 5.5% x 100 = portfolio RCR.',
          },
          {
            boldText:
              'Total portfolio mortgage balance ÷ total portfolio property value = portfolio LTV.',
          },
        ],
      },
    ],
  },
};

export default calculators;
