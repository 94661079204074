import React from 'react';

import {
  Button,
  Container,
  Grid,
  Heading,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import bmFiveStarImg from 'assets/common/BM-5-start-winner-updated.jpg';
import illustrationClipboard from 'assets/common/BM_illustration_clipboard_white.png';
import illustrationWhite from 'assets/common/BM_illustration_scales_white.png';
import illustrationSignpost from 'assets/common/BM_illustration_signpost_white.png';
import sustainabilityImg from 'assets/common/BMSolutions_ground_source_heat_pump.png';
import ContentBox from 'client/components/contentBox/ContentBox';
import HeroImageBox from 'client/components/heroImageBox/HeroImageBox';
import { LinkButton } from 'client/components/linkButton';
import MetaTags from 'client/components/metaTags/MetaTags';
import testIds from 'client/testIds';

import HeroComponent from './components/hero/HeroComponent';
import { HomePageContent } from './Home.config';
import {
  FullWidthImage,
  StyledGridItem,
  StyledHomeContainer,
  StyledSustainabilityBox,
} from './HomePage.styled';
import * as routes from '../manifest';

function HomePage(): React.JSX.Element {
  const {
    heroContent,
    productSearch,
    mortgageCriteria,
    contactBDM,
    newToBM,
    sustainability,
    metaContent,
  } = useContent<HomePageContent>();
  const gridItems = [
    { ...productSearch, img: illustrationWhite },
    { ...mortgageCriteria, img: illustrationClipboard },
    { ...contactBDM, img: illustrationSignpost },
  ];
  const navigate = useNavigate();
  return (
    <Main>
      <MetaTags metaContent={metaContent} />

      <HeroComponent
        caption={heroContent.imageCaption}
        subtext={heroContent.imageSubtext}
        btnLabel={heroContent.btnLabel}
        btnHref={heroContent.btnHref}
        testId={testIds.homePage.heroComponent}
      />

      <StyledHomeContainer>
        <Grid>
          {gridItems.map((val, index) => (
            <StyledGridItem md={4} sm={12} key={val.heading}>
              <HeroImageBox bgColor="brand1">
                <FullWidthImage
                  src={val.img}
                  data-testid={`${testIds.homePage.homeGridImg}_${index}`}
                  alt=""
                />
              </HeroImageBox>
              <Heading
                as="h2"
                marginTop="02"
                marginBottom="02"
                data-testid={`${testIds.homePage.homeGridHeading}_${index}`}
              >
                {val.heading}
              </Heading>
              <Paragraph
                size="s2"
                data-testid={`${testIds.homePage.homeGridSubheading}_${index}`}
              >
                {val.firstParagraph}
              </Paragraph>
              <div style={{ bottom: 0 }}>
                <Button
                  variation="secondary"
                  data-testid={`${testIds.homePage.homeGridButton}_${index}`}
                  onClick={() => {
                    if (val.external) {
                      window.open(val.btnHref, val.target);
                    } else {
                      navigate(val.btnHref);
                    }
                  }}
                >
                  {val.btnLabel}
                </Button>
              </div>
            </StyledGridItem>
          ))}
        </Grid>
        <ContentBox
          imgSrc={bmFiveStarImg}
          leftAlignImage={false}
          imgWidth="180px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.homePage.newToBMImg}
          marginLeft="auto"
          marginRight="auto"
          imgAlt="Five star winner award for mortgages - BM Solutions"
        >
          <Heading
            as="h2"
            data-testid={testIds.homePage.newToBMHeading}
            marginTop="05"
            marginBottom="03"
          >
            {newToBM.heading}
          </Heading>
          <Paragraph
            size="s2"
            data-testid={testIds.homePage.newToBMFirstParagraph}
          >
            {newToBM.firstParagraph}
          </Paragraph>
          <Paragraph
            size="s2"
            data-testid={testIds.homePage.newToBMSecondParagraph}
          >
            {newToBM.secondParagraph}
          </Paragraph>
          <Heading
            as="h3"
            size="s3"
            marginBottom="02"
            data-testid={testIds.homePage.newToBMHeading}
          >
            {newToBM.signinHeader}
          </Heading>
          <Container marginBottom="03" padding="none">
            <LinkButton
              testId={testIds.homePage.newToBMSignin}
              to={newToBM.signinButtonHref}
              external
            >
              {' '}
              {newToBM.signinButtonLabel}
            </LinkButton>
          </Container>
        </ContentBox>
      </StyledHomeContainer>
      <StyledSustainabilityBox>
        <ContentBox
          imgSrc={sustainabilityImg}
          leftAlignImage
          imgWidth="300px"
          marginBottom="none"
          marginTop="none"
          imgTestId={testIds.homePage.sustainabilityImage}
          marginLeft="auto"
          marginRight="auto"
          imgAlt="Man setting up air conditioning device"
        >
          <Heading as="h2" data-testid={testIds.homePage.sustainabilityHeading}>
            {sustainability.heading}
          </Heading>
          <Paragraph
            data-testid={testIds.homePage.sustainabilityFirstParagraph}
          >
            {sustainability.firstParagraph}
          </Paragraph>
          <Paragraph
            data-testid={testIds.homePage.sustainabilitySecondParagraph}
          >
            {sustainability.secondParagraph}
          </Paragraph>
          <Button
            variation="secondary"
            data-testid={testIds.homePage.sustainabilityButton}
            onClick={() => {
              navigate(routes.SustainabilityHub);
            }}
          >
            {sustainability.findOutMoreBtnLabel}
          </Button>
        </ContentBox>
      </StyledSustainabilityBox>
    </Main>
  );
}

export default HomePage;
